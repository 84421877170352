import React, { useState, useContext } from "react"; // Assurez-vous d'importer tous les hooks et composants nécessaires
import { Modal, Button } from "antd"; // Ou d'où que vous importez vos composants UI

import { AuthContext } from "../../context/AuthContext.js";
import "./steps.css";
import mixpanel from "mixpanel-browser";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const Step2 = ({ isVisible, onClose, onNext, onBack }) => {
  const { t, i18n } = useTranslation();

  const [teamName, setTeamName] = useState("");
  const [languagekeyword, setLanguagekeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState({});

  const context = useContext(AuthContext);
  const { setTeamId } = useContext(AuthContext);

  const handleNextClick = async () => {
    // 1. Validation
    if (!teamName) {
      setError({
        ...error,
        teamName: !teamName ? t("Team_name_is_required") : "",
      });
      return;
    }

    // Nouvelle vérification pour la longueur du nom de l'équipe
    if (teamName.length > 30) {
      toast.error(t("Team_name_cannot_exceed_30car"));
      return;
    }

    // 2. Envoi des données
    const headers = {
      "Content-Type": "application/json",
    };
    if (context.token) {
      headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem("Token")) {
      headers.Authorization = `Bearer ${localStorage.getItem("Token")}`;
    }

    const requestBody = {
      teamName,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + "/api/teams/update-name",
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              context.token || localStorage.getItem("Token")
            }`,
          }),
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();
      // console.log("responseData", responseData);

      // 3. Gérer la réponse
      if (response.ok) {
        // Votre code pour gérer un succès, comme mettre à jour l'UI ou naviguer vers une autre page
        localStorage.setItem("teamid", responseData.team._id);
        setTeamId(responseData.team._id); // Mettez à jour teamId dans le contexte
        // console.log("Data sent successfully!", responseData);
        mixpanel.track("Onboarding", {
          Step: "2",
        });
        onNext(); // Appellez la fonction callback
      } else {
        // Votre code pour gérer une erreur, comme afficher un message d'erreur
        console.error("Failed to send data:", responseData);
      }
    } catch (error) {
      console.error("There was an error sending the data:", error);
    }
  };

  return (
    <Modal
      className="customModal" // Ajoutez cette classe pour cibler ce modal spécifiquement dans votre CSS
      open={isVisible}
      maskStyle={{
        background:
          "linear-gradient(130deg, #FFD788 28.79%, #FFBFBF 64.77%, #DDA5FF 100%)",
      }}
      footer={false}
      maskClosable={false}
      closable={false}
    >
      <div className="leftPart">
        <h2>{t("Create_your_team")}</h2>
        <p>{t("Choose_a_great_name_for_your_buska_team")}</p>
        <br />

        <label className="labelFormOnboarding" htmlFor="teamName">
          {t("Name_of_your_team")}
        </label>
        <div className="form-group">
          <input
            placeholder="Ex: buska team"
            type="text"
            id="teamName"
            name="teamName"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
          />
        </div>

        <br />
        {/* <label className="labelFormOnboarding" htmlFor="inviteTeamMembers">
          {t("invite_teammates_and_share_access_to_the_mentions")}
        </label> */}
        {/* <span className="activeLinkOnboarding">Add a new team member</span> */}

        <div className="alignVert">
          <div style={{ textAlign: "left" }}>
            <Button
              key="submit"
              className="CtaOnboardingv2Back"
              onClick={onBack}
            >
              <i className="fa-solid fa-angle-left"></i>
            </Button>
          </div>

          <div style={{ textAlign: "right" }}>
            <Button
              key="submit"
              className="CtaOnboardingv2"
              onClick={handleNextClick}
            >
              {t("Continue")} <i className="fa-solid fa-arrow-right"></i>
            </Button>
          </div>
        </div>
        <div className="footerButtonSteps3"></div>
      </div>

      <div className="rightPart">
        <p>
          {t("90_off")}{" "}
          <span className="highlightedOnboard">
            {" "}
            {t("social_media_conversations")}{" "}
          </span>
          {t("about_brands_happn_on_platforms")}
        </p>
        <img style={{ width: "30%" }} src="/Rating.svg" />
        <h3>{t("use_buska_to_always_keep_an_ear_in_the_room")}</h3>
      </div>
    </Modal>
  );
};

export default Step2;
