// REACT STUFF
import React, { Suspense } from "react";
import { useEffect, useState, useContext, Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import axios from "axios";

// AUTH CONTEXT
import { AuthContext } from "../context/AuthContext.js";

import GoogleConnect from "../components/GoogleConnect.js";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n";
import Modal from "react-modal";

//Components
const Footer = React.lazy(() => import("../components/footer"));

import Loader from "../components/Loader.js";
const Accordion = React.lazy(() => import("../components/Accordion"));
import TypingText from "../components/typingText.js";

// LIB
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// CSS STYLES
import "./HomePage.css";
const Testimonials = React.lazy(() => import("../components/Testimonials"));

function HomePage() {
  const context = useContext(AuthContext);

  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState(null);
  const [resultsLinkedin, setResultsLinkedin] = useState(null);
  const [resultsReddit, setResultsReddit] = useState(null);
  const [resultsPinterest, setResultsPinterest] = useState(null);
  const [resultsYoutube, setResultsYoutube] = useState(null);
  const [resultsTiktok, setResultsTiktok] = useState(null);
  const [resultsInstagram, setResultsInstagram] = useState(null);
  const [resultsGoogle, setResultsGoogle] = useState(null);
  const [resultsGoogleNews, setResultsGoogleNews] = useState(null);
  const [resultsMedium, setResultsMedium] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const { t, i18n } = useTranslation();

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const redirectToRegister = () => {
    window.location.href = "/register";
  };

  const openModalWithContent = (content) => {
    setModalContent(content);
    openModal();
  };

  const toggleModalLogin = () => {
    setIsOpenLogin(!isOpenLogin);
  };

  const handleSearch = async () => {
    if (!loading) {
      try {
        setLoading(true); // Set loading state to true when search starts
        const response = await axios.post(
          process.env.REACT_APP_BASEURL + "/api/search/search",
          { keyword }
        );
        // console.log("response", response.data);
        setResults(response.data);
        setResultsReddit(response.data.reddit);
        setResultsLinkedin(response.data.linkedin);
        setResultsPinterest(response.data.pinterest);
        setResultsYoutube(response.data.youtube);
        setResultsTiktok(response.data.tiktok);
        setResultsInstagram(response.data.instagram);
        setResultsMedium(response.data.medium);
        setResultsGoogle(response.data.google);
        setResultsGoogleNews(response.data.googleNews);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading state back to false when search completes
      }
    }
  };

  const convertAbbreviationToFullForm = (abbreviation) => {
    const unitMapping = {
      h: t("hour"),
      d: t("jour"),
      w: t("semaine"),
      mo: t("mois"),
      y: t("annee"),
    };

    const unit = abbreviation.slice(-1); // Modifier pour obtenir le dernier caractère de l'abréviation
    const number = abbreviation.slice(0, -1); // Modifier pour obtenir le nombre sans le dernier caractère de l'abréviation

    return unitMapping[unit] ? `${number} ${unitMapping[unit]}` : abbreviation;
  };

  function truncateText(text, maxLength) {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  }

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  const formatCount = (count) => {
    if (count <= 100) {
      return count.toString();
    } else if ((count) => 1000) {
      return 100;
    }
  };

  function handlePlayPause() {
    const video = document.getElementById("videoElement");
    const playButton = document.querySelector(".playButton");

    if (video.paused) {
      video.play();
      video.classList.add("playing");
    } else {
      video.pause();
      video.classList.remove("playing");
    }
  }

  // useEffect(() => {
  //   // Scroll to the section with the id "infoWhatIsIt" when results are loaded
  //   if (results) {
  //     const infoWhatIsItSection = document.getElementById("infoWhatIsIt");
  //     if (infoWhatIsItSection) {
  //       infoWhatIsItSection.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }
  // }, [results]);

  return (
    <>
      {/* <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin} /> */}

      <div className="globalContainer">
        <main id="homeMain">
          <div className="mainBg">
            <div></div>
          </div>
          <div className="wrap vac">
            <h1 className="mb50">
              {context.userInfos
                ? `${t("welcome_back")}, ${context.userInfos.firstname}!`
                : t("Every_mentions_at_a_glance")}
            </h1>
            <h4>
              <span className="bold">
                {t(
                  "Monitor_online_mentions_of_your_brand_in_seconds_not_hours"
                )}
              </span>
              <br />
              {t(
                "Get_real_time_alerts_insights_from_every_platform_and_grow_your_reputation"
              )}
            </h4>
            <br />
            <div className="wrapS">
              {context.userInfos ? (
                <a href="/dashboard">
                  <div className="ctaPrimaryBlack">{t("See_all_mentions")}</div>
                </a>
              ) : (
                <>
                  <GoogleConnect
                    className="connectGoogleButton"
                    buttonText={t("Continue_with_Google")}
                    label="Continue with Google"
                    methodeType="login"
                  />
                  <div className="arrowhomeFree">
                    <span className="freedisplay">{t("7_day_free_trial")}</span>
                    <img
                      className="freeArrow"
                      src="Arrow-right.png"
                      alt="Arrow Right Buska"
                    />
                    <span className="no_credit_card_required">
                      {t("no_credit_card_required")}
                    </span>
                  </div>
                </>
              )}
            </div>
            {/* <h1 className="mb50">
              {t("Dont_miss_any_mention")}
              <br />
              {t("of_your_brand_on")} <TypingText />
            </h1> */}

            <>
              {/* <h5 className="mb50 bold">
                {t(
                  "fill_in_your_company_name_below_and_find_out_whos_talking_about_you_on_the_internet_and_social_networks"
                )}
              </h5> */}

              {/* <div className="inputWrapper">
                <input
                  className="inputSearch"
                  placeholder={t("enter_your_company_name_here")}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !results) {
                      handleSearch(); // Exécutez la fonction de recherche
                    }
                  }}
                />
                <button
                  className={`ctaSearch ${results ? "resultsLoaded" : ""}`}
                  onClick={results ? null : handleSearch}
                >
                  {loading ? (
                    <span className="spannerLoader"></span>
                  ) : results ? (
                    <i className="fa-solid fa-circle-check"></i>
                  ) : (
                    t("search")
                  )}
                </button>
                <div className="shadown"></div>
              </div> */}
              {/* {loading && (
                <>
                  <br />
                  <br />
                  <h5 className="bold" style={{ color: "#000" }}>
                    {t("can_be_long")}
                  </h5>
                </>
              )} */}
            </>
          </div>
        </main>

        {/* <section id="tabBetween">
          <div className="containerTab">
              <h4 style={{fontWeight:"bold"}}>{t("protect_your_online_reputation_and_engage_with_your_audience_like_never_before")}</h4>
              <p>{t("stay_informed_of_your_brand_on_social_networks_and_the_internet")}</p>
          </div>
      </section> */}

        {/* <section id="infoWhatIsIt">
          <div className="wrap">
            {results ? (
              <>
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  contentLabel="modalPost"
                  className="modalPost"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 1000, 
                    },
                  }}
                >
                  <div>
                    <span>
                      {t("want_to_get_more_details_about_this_mention")}
                    </span>
                    <div className="tac mt30">
                      <button
                        className="smallCta"
                        onClick={redirectToRegister}
                      >
                        {t("Register_now_here")}
                      </button>
                    </div>
                  </div>
                </Modal>
                <div className="webkitBox">
                  <div className="alignVignHome" onClick={openModal}>
                    <div className="SocialsVign">
                      <div className="webkitBox">
                        <i className="youtube fa-brands fa-youtube fa-3x"></i>
                        <div className="ml10 mt10 lineHeight">
                          <span className="NameChaine"> Youtube</span>
                          <br />
                          <span className="timeAgo">
                            {resultsYoutube &&
                            resultsYoutube.posts &&
                            resultsYoutube.posts.length > 0
                              ? resultsYoutube.posts[0].date
                              : t("not_available")}
                          </span>
                        </div>
                      </div>
                      <p
                        style={{ color: "black", marginTop: "10px" }}
                        className="text-truncate"
                      >
                        {resultsYoutube &&
                        resultsYoutube.posts &&
                        resultsYoutube.posts.length > 0
                          ? resultsYoutube.posts[0].content
                            ? resultsYoutube.posts[0].content
                            : resultsYoutube.posts[0].name
                          : t("impossible_de_charger_le_contenu_du_post")}
                        ...
                      </p>
                      <hr />
                      <br />
                      <br />
                      <div className="bottomMention linkHeight">
                        <a target="_blank">
                          <i className="fa-solid fa-up-right-from-square"></i>{" "}
                          {t("join_to_see_more")}
                        </a>
                        <span>
                          <i className="fa-solid fa-share-nodes"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="alignVignHome" onClick={openModal}>
                    <div className="SocialsVign">
                      <div className="webkitBox">
                        <i className="linkedin fa-brands fa-linkedin fa-2x"></i>
                        <div className="ml10 lineHeight">
                          <span className="NameChaine"> Linkedin</span>
                          <br />
                          <span className="timeAgo">
                            {convertAbbreviationToFullForm(
                              resultsLinkedin.posts[0]?.date ??
                                t("not_available")
                            )}
                          </span>
                        </div>
                      </div>
                      <p
                        style={{ color: "black", marginTop: "10px" }}
                        className="text-truncate"
                      >
                        {resultsLinkedin.posts[0]?.content ??
                          t("impossible_de_charger_le_contenu_du_post")}
                        ...
                      </p>
                      <hr />
                      <br />
                      <br />
                      <div className="bottomMention linkHeight">
                        <a target="_blank">
                          <i className="fa-solid fa-up-right-from-square"></i>{" "}
                          {t("join_to_see_more")}
                        </a>
                        <span>
                          <i className="fa-solid fa-share-nodes"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="alignVignHome" onClick={openModal}>
                    <div className="SocialsVign">
                      <div className="webkitBox">
                        <i className="instagram fa-brands fa-instagram fa-2x"></i>
                        <div className="ml10 lineHeight">
                          <span className="NameChaine"> Instagram</span>
                          <br />
                          <span className="timeAgo">
                            {resultsInstagram &&
                            resultsInstagram.posts &&
                            resultsInstagram.posts.length > 0
                              ? resultsInstagram.posts[0].date
                              : t("not_available")}
                          </span>
                        </div>
                      </div>
                      <p
                        style={{ color: "black", marginTop: "10px" }}
                        className="text-truncate"
                      >
                        {resultsInstagram &&
                        resultsInstagram.posts &&
                        resultsInstagram.posts.length > 0 &&
                        resultsInstagram.posts[0].content &&
                        resultsInstagram.posts[0].content !== ""
                          ? resultsInstagram.posts[0].content
                          : t("impossible_de_charger_le_contenu_du_post")}
                        ...
                      </p>
                      <hr />
                      <br />
                      <br />
                      <div className="bottomMention linkHeight">
                        <a target="_blank">
                          <i className="fa-solid fa-up-right-from-square"></i>{" "}
                          {t("join_to_see_more")}
                        </a>
                        <span>
                          <i className="fa-solid fa-share-nodes"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="alignVignHome" onClick={openModal}>
                    <div className="SocialsVign">
                      <div className="webkitBox">
                        <i className="reddit fa-brands fa-reddit fa-2x"></i>
                        <div className="ml10 lineHeight">
                          <span className="NameChaine"> Reddit</span>
                          <br />
                          <span className="timeAgo">
                            {resultsReddit.posts[0]?.date ?? t("not_available")}
                          </span>
                        </div>
                      </div>
                      <p
                        style={{ color: "black", marginTop: "10px" }}
                        className="text-truncate"
                      >
                        {resultsReddit.posts[0]?.content ??
                          t("impossible_de_charger_le_contenu_du_post")}
                        ...
                      </p>
                      <hr />
                      <br />
                      <br />
                      <div className="bottomMention linkHeight">
                        <a target="_blank">
                          <i className="fa-solid fa-up-right-from-square"></i>{" "}
                          {t("join_to_see_more")}
                        </a>
                        <span>
                          <i className="fa-solid fa-share-nodes"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="webkitBox secondRow">
                  <div className="alignVignHome" onClick={openModal}>
                    <div className="SocialsVignSecond">
                      <div className="webkitBox">
                        <i className="medium fa-brands fa-medium fa-3x"></i>
                        <div className="ml10 mt15 lineHeight">
                          <span className="NameChaine"> Medium</span>
                          <br />
                          <span className="timeAgo">
                            {resultsMedium &&
                            resultsMedium.posts &&
                            resultsMedium.posts.length > 0
                              ? convertAbbreviationToFullForm(
                                  resultsMedium.posts[0].date
                                )
                              : t("not_available")}
                          </span>
                        </div>
                      </div>
                      <p
                        style={{ color: "black", marginTop: "10px" }}
                        className="text-truncate"
                      >
                        {resultsMedium &&
                        resultsMedium.posts &&
                        resultsMedium.posts.length > 0
                          ? resultsMedium.posts[0].content
                          : t("impossible_de_charger_le_contenu_du_post")}
                        ...
                      </p>
                      <hr />
                      <br />
                      <br />
                      <div className="bottomMention linkHeight">
                        <a target="_blank">
                          <i className="fa-solid fa-up-right-from-square"></i>{" "}
                          {t("join_to_see_more")}
                        </a>
                        <span>
                          <i className="fa-solid fa-share-nodes"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="alignVignHome" onClick={openModal}>
                    <div className="SocialsVignSecond">
                      <div className="webkitBox">
                        <img className="google" src="background/google.webp" />
                        <div className="ml10 mt15 lineHeight">
                          <span className="NameChaine"> Google</span>
                          <br />
                          <span className="timeAgo">{t("not_available")}</span>
                        </div>
                      </div>
                      <p
                        style={{ color: "black", marginTop: "10px" }}
                        className="text-truncate"
                      >
                        {resultsGoogle &&
                        resultsGoogle.posts &&
                        resultsGoogle.posts[0]
                          ? resultsGoogle.posts[0].content
                          : t("impossible_de_charger_le_contenu_du_post")}
                        ...
                      </p>
                      <hr />
                      <br />
                      <br />
                      <div className="bottomMention linkHeight">
                        <a target="_blank">
                          <i className="fa-solid fa-up-right-from-square"></i>{" "}
                          {t("join_to_see_more")}
                        </a>
                        <span>
                          <i className="fa-solid fa-share-nodes"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="alignVignHome" onClick={openModal}>
                    <div className="SocialsVignSecond">
                      <div className="webkitBox">
                        <i className="pinterest fa-brands fa-pinterest fa-3x"></i>
                        <div className="ml10 mt15 lineHeight">
                          <span className="NameChaine"> Pinterest</span>
                          <br />
                          <span className="timeAgo">{t("not_available")}</span>
                        </div>
                      </div>
                      <p
                        style={{ color: "black", marginTop: "10px" }}
                        className="text-truncate"
                      >
                        {resultsPinterest &&
                        resultsPinterest.posts &&
                        resultsPinterest.posts.length > 1
                          ? resultsPinterest.posts[0].content
                          : t("impossible_de_charger_le_contenu_du_post")}
                        ...
                      </p>
                      <hr />
                      <br />
                      <br />
                      <div className="bottomMention linkHeight">
                        <a target="_blank">
                          <i className="fa-solid fa-up-right-from-square"></i>{" "}
                          {t("join_to_see_more")}
                        </a>
                        <span>
                          <i className="fa-solid fa-share-nodes"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="alignVignHome" onClick={openModal}>
                    <div className="SocialsVignSecond">
                      <div className="webkitBox">
                        <img
                          className="google"
                          src="background/newsGoogle.png"
                        />
                        <div className="ml10 mt15 lineHeight">
                          <span className="NameChaine">News</span>
                          <br />
                          <span className="timeAgo">
                            {resultsGoogleNews &&
                            resultsGoogleNews.posts &&
                            resultsGoogleNews.posts[0]?.date
                              ? truncateText(
                                  resultsGoogleNews.posts[0].date,
                                  15
                                )
                              : t("not_available")}
                          </span>
                        </div>
                      </div>
                      <p
                        style={{ color: "black", marginTop: "10px" }}
                        className="text-truncate"
                      >
                        {resultsGoogleNews &&
                        resultsGoogleNews.posts &&
                        resultsGoogleNews.posts[0]?.name
                          ? resultsGoogleNews.posts[0].name
                          : t("impossible_de_charger_le_contenu_du_post")}
                        ...
                      </p>
                      <hr />
                      <br />
                      <br />
                      <div className="bottomMention linkHeight">
                        <a target="_blank">
                          <i className="fa-solid fa-up-right-from-square"></i>{" "}
                          {t("join_to_see_more")}
                        </a>
                        <span>
                          <i className="fa-solid fa-share-nodes"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="tac joinBuskaForFree">
                  <h4 style={{ fontWeight: "bold" }}>
                    {t("want_see_more_results_want_to_receive_alerts")} <br />
                    <br />
                    <button
                      className="ctaSignup"
                      onClick={redirectToRegister}
                    >
                      {t("join_buska_for_free")}
                    </button>
                  </h4>
                </div>
                <div>
                  <img src="/hello.svg" className="helloVignette" />
                  <div className="tac">
                    <div className="containerVideo">
                      <div className="videoWrapper">
                        <video
                          className="videohomePage"
                          poster="/thumbnail.png"
                          id="videoElement"
                          onClick={handlePlayPause}
                        >
                          <source
                            loading="lazy"
                            src="buska_video_intro.mp4"
                            type="video/mp4"
                          />
                        </video>
                        <div
                          className="playButton"
                          onClick={handlePlayPause}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <img src="/hello.svg" className="helloVideo" />
                  <div className="tac">
                    <div className="containerVideo">
                      <div className="videoWrapper">
                        <video
                          className="videohomePage"
                          poster="/thumbnail.png"
                          id="videoElement"
                          onClick={handlePlayPause}
                        >
                          <source
                            loading="lazy"
                            src="buska_video_intro.mp4"
                            type="video/mp4"
                          />
                        </video>
                        <div
                          className="playButton"
                          onClick={handlePlayPause}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section> */}

        <section id="infoWhatIsIt">
          <div className="">
            <div>
              <img src="/hello.svg" className="helloVideo" alt="Hello Buska" />
              <div className="tac">
                <div className="containerVignetteBlur">
                  <img
                    src="/card_blur.webp"
                    alt="Card blur Buska"
                    // className="vignetteCardBlur"
                  />
                </div>
                <div className="ContainCard">
                  <div className="containerVignette">
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-1.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-2.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-3.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-4.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-5.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-6.webp"
                      className="vignetteCard"
                      width={"100%"}
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-7.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-8.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-9.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                    <img
                      loading="lazy"
                      src="/cards/Mention_cards-10.webp"
                      className="vignetteCard"
                      alt="Vignette Card Buska"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrap">
            <div className="containerVideo">
              <div className="videoWrapper">
                <video
                  className="videohomePage"
                  poster="/thumbnail.png"
                  id="videoElement"
                  onClick={handlePlayPause}
                >
                  <source
                    loading="lazy"
                    src="buska_video_intro.mp4"
                    type="video/mp4"
                  />
                </video>
                <div className="playButton" onClick={handlePlayPause}></div>
              </div>
            </div>
          </div>
        </section>

        <section id="dashboardScreen">
          <div className="wrap">
            <div className="tac mb30">
              <h3>{t("all_you_need_beautifully_displayed")}</h3>
              <p>{t("access_every_mention_from_every_platform")}</p>
              <video
                autoPlay
                muted
                loop
                preload="auto"
                className="videodashboardHome"
              >
                <source loading="lazy" src="dashboard.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </section>

        <Suspense fallback={<div>{t("loading")}...</div>}>
          <section id="feedbacksCustomers">
            <div className="wrap">
              <div className="tac mb30">
                <h3>{t("Our_Customer_Feedback")}</h3>
                <p>{t("join_buska_for_free_and_try")}</p>
                <Testimonials />
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div>{t("loading")}...</div>}>
          <section id="Banner">
            <div className="wrap">
              <div className="tac mb30">
                <a href="/register">
                  <img loading="lazy" src="banner.png" alt="banner Buska.io" />
                </a>
              </div>
            </div>
          </section>
        </Suspense>

        <section id="features">
          <div className="wrap">
            <div className="tac mt30 mb30">
              <h3>
                {t("discover_what")} <span>Buska</span> {t("does_for_you")}
              </h3>
              <p>{t("everything_you_need_to_monitor_your_brand")}</p>
            </div>
            <div className="parentContainer">
              <div className="containerFeatures">
                <img loading="lazy" src="/alerts.png" alt="alert Buska" />
                <h4>{t("get_alerts_in_real_time")}</h4>
                <p>{t("receive_alerts_in_real_time")}</p>
              </div>
              <div className="containerFeatures">
                <img
                  loading="lazy"
                  src="/Slack_Discord.png"
                  alt="alert Buska"
                />
                <h4>{t("slack_discord_integration")}</h4>
                <p>{t("receive_a_notification_on_your_discord")}</p>
              </div>
              <div className="containerFeaturesSoon">
                <span className="newSoonPastille">{t("soon")}</span>
                <img loading="lazy" src="/answer.png" alt="Answer Buska" />
                <h4>{t("Answer_automatically")} </h4>
                <p>{t("configure_your_bot")}</p>
              </div>
              <div className="containerFeatures">
                <img
                  loading="lazy"
                  src="/analyze_data.png"
                  alt="Analyze Data Buska"
                />
                <h4>{t("analyze_data_with_your_team")}</h4>
                <p>{t("create_your_own_team_and_invite_people")}</p>
              </div>
              <div className="containerFeaturesSoon">
                <img
                  loading="lazy"
                  src="/Feedbacks.png"
                  alt="Feedbacks Buska"
                />
                <h4>{t("Identifying_feedbacks")} </h4>
                <p>{t("find_out_what_is_good_and")}</p>
              </div>
              <div className="containerFeatures">
                <img loading="lazy" src="/reports.png" alt="Reports Buska" />
                <h4>{t("Automatic_reporting")}</h4>
                <p>{t("Whether_youre_an_agency")}</p>
              </div>
              <div className="containerFeatures">
                <img
                  loading="lazy"
                  src="/competitive.png"
                  alt="Competitve Buska"
                />
                <h4>{t("Competitive_Intelligence")}</h4>
                <p>{t("offer_in_depth_analysis")}</p>
              </div>
              <div className="containerFeaturesSoon">
                <span className="newSoonPastille">{t("soon")}</span>
                <img
                  loading="lazy"
                  src="/Facedetector.png"
                  alt="Face Detector Buska"
                />
                <h4>{t("logo_and_face_detector")} </h4>
                <p>{t("with_our_new_ia_track_location_logo")}</p>
              </div>
              <div className="containerFeaturesSoon">
                <span className="newSoonPastille">{t("soon")}</span>
                <img
                  loading="lazy"
                  src="/crm-integration.png"
                  alt="Crm Integration Buska"
                />
                <h4>{t("CRM_Integrations")} </h4>
                <p>{t("integrate_the_platform_with_popular_CRM")}</p>
              </div>
            </div>
          </div>
        </section>

        <section id={"monthlyPricing"}>
          <div className="wrap">
            <div className="tac mt30 mb30">
              <h3>{t("accessible_plans_for_priceless")}</h3>
              <br />
              <p style={{ color: "#000" }}>
                {t("everything_you_need_to_monitor_your_brand")}
              </p>
              <br />
              <div className="monthlyorYearly">
                <span
                  className={`monthlyPrice ${isMonthly ? "" : "activehere"}`}
                  onClick={handleToggle}
                >
                  {t("Monthly")}
                </span>
                <span
                  className={`yearlyPrice ${isMonthly ? "activehere" : ""}`}
                  onClick={handleToggle}
                >
                  {t("Yearly")} ({t("save_pourcentage")})
                </span>
              </div>
              <br />
            </div>
            <div className="priceContainer">
              <div className="containerPricing">
                <h4>
                  {isMonthly ? (
                    <img
                      className="align"
                      src="/crown_yellow.svg"
                      alt="Crown Yellow Buska"
                    />
                  ) : (
                    <img className="align" src="/crown.svg" alt="Crown Buska" />
                  )}
                  {t("Pro")}{" "}
                </h4>
                <p className="descriptifPrice">
                  {t("perfect_plan_for_professionals")}
                </p>
                <hr className="hrborder" />
                <div className="mb10 mt10">
                  <span className="price">
                    {t("dollar")}
                    {isMonthly ? "9" : "15"}{" "}
                  </span>
                  <span>/ {t("month")}</span>
                </div>
                {/* <div className="mb10 mt10"><span className="price">${isMonthly ? '15' : '9'} </span><span>/ month</span></div> */}
                <p>{t("Optimize_your_online_presence_with_multiple")}</p>
                <div className="tac">
                  <button
                    onClick={redirectToRegister}
                    // data-tally-open="mZEJEo"
                    // data-tally-overlay="1"
                    // data-tally-emoji-text="🚀"
                    // data-tally-emoji-animation="wave"
                    // data-tally-open="mZEJEo"
                    // data-tally-layout="modal"
                    // data-tally-width="800"
                    // data-tally-emoji-text="👋"
                    // data-tally-emoji-animation="wave"
                  >
                    {t("start_your_free_trial_now")}
                  </button>
                </div>
                <ul>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i> 3{" "}
                    {t("keywords")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i> 3{" "}
                    {t("users")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("daily_updates")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("email_notification")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("browser_notification")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("social_sharing")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("Sentiment_Analysis")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("IA_Features")}
                    <span className="soonPastilleHome">{t("soon")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    <span>{t("facebook_tiktok_mentions")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    {t("multiple_team")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    {t("discord_slack_notifs")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    {t("Software_Integrations")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    {t("Export_data")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    <span>{t("Google_Search_Mentions")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i> {t("api")}
                  </li>
                </ul>
              </div>
              <div className="containerPricingUltimate">
                <h4>
                  {isMonthly ? (
                    <img
                      className="align"
                      src="/share_yellow.svg"
                      alt="Share Yellow Buska"
                    />
                  ) : (
                    <img className="align" src="/share.svg" alt="Share Buska" />
                  )}
                  {t("Ultimate")}
                </h4>
                <p className="descriptifPrice">
                  {t("Best_suits_for_great_company")}
                </p>
                <hr className="hrborder" />
                <div className="mb10 mt10">
                  <span className="price">
                    {t("dollar")}
                    {isMonthly ? "69" : "115"}{" "}
                  </span>
                  <span>/ {t("month")}</span>
                </div>
                <p>{t("Ideal_for_a_complete_management")}</p>
                <div className="tac">
                  <button
                    onClick={redirectToRegister}
                    // data-tally-open="mZEJEo"
                    // data-tally-overlay="1"
                    // data-tally-emoji-text="🚀"
                    // data-tally-emoji-animation="wave"
                    // data-tally-open="mZEJEo"
                    // data-tally-layout="modal"
                    // data-tally-width="800"
                    // data-tally-emoji-text="👋"
                    // data-tally-emoji-animation="wave"
                  >
                    {t("start_your_free_trial_now")}
                  </button>
                </div>
                <ul>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i> 10{" "}
                    {t("keywords")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("Up_to_5_members_per_team")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("real_time_updates")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("email_notification")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("browser_notification")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("social_sharing")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("Sentiment_Analysis")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("IA_Features")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    <span>{t("facebook_tiktok_mentions")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("multiple_team")}
                    <span className="soonPastilleHome">{t("soon")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("discord_slack_notifs")}
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("Software_Integrations")}
                    <span className="soonPastilleHome">{t("soon")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("Export_data")}
                    <span className="soonPastilleHome">{t("soon")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    <span>{t("Google_Search_Mentions")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check green"></i>{" "}
                    {t("api")}
                    <span className="soonPastilleHome">{t("soon")}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <Suspense fallback={<div>{t("loading")}...</div>}>
          <section id="FAQ">
            <div className="wrap FAQ">
              <div className="tac mb30">
                <h3>{t("Questions_les_plus_frequemment_posees")}</h3>
                <br />
                <p>
                  {t(
                    "Decouvrez_toutes_les_reponses_a_vos_questions_les_plus_frequentes_sur"
                  )}{" "}
                  <br /> {t("lutilisation_de_notre_plateforme")}
                </p>
              </div>
              <div className="accordionDiv">
                <Accordion
                  question={t("What_is_Social_Share")}
                  answer={t(
                    "Social_Share_is_an_online_platform_that_allows_businesses_to_monitor_mentions_about_them_on_the_Internet_and_social_media"
                  )}
                />
                <Accordion
                  question={t("How_does_Social_Share_work")}
                  answer={t(
                    "Social_Share_uses_advanced_search_algorithms_to_track_mentions_of_your_company_across_various_websites_and_social_media_platforms"
                  )}
                />
                <Accordion
                  question={t(
                    "Which_social_media_platforms_does_Social_Share_support"
                  )}
                  answer={t(
                    "Social_Share_supports_a_variety_of_social_media_platforms_such_as_Facebook"
                  )}
                />
                <Accordion
                  question={t("How_can_I_start_using_Social_Share")}
                  answer={t(
                    "To_get_started_you_need_to_sign_up_for_the_waitlist"
                  )}
                />
                <Accordion
                  question={t(
                    "How_can_I_access_the_mentions_found_by_Social_Share"
                  )}
                  answer={t(
                    "On_your_dashboard_you_will_find_all_the_collected_mentions_intuitively_organized_with_links_and_all_the_necessary_information"
                  )}
                />
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div>{t("loading")}...</div>}>
          <Footer />
        </Suspense>
      </div>
    </>
  );
} // END HOMEPAGE

export default HomePage;
