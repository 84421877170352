import {useEffect, useState, useContext, useCallback} from 'react';
import {Link, useNavigate} from "react-router-dom";

import axios from "axios";

import {AuthContext} from "../context/AuthContext.js";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";

// STRIPE
import { loadStripe } from '@stripe/stripe-js';




// STYLES
import "./Credits.css";


function Credits() {
  const { t, i18n } = useTranslation();
  
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const context = useContext(AuthContext);

  const noMoreCredit = () => {
    if (context.userInfos.credits > 1) {
        return (<span>{t("Il_vous_reste_actuellement")} {context.userInfos.credits} {t("credits")}.</span>)
    } else {
        return (<span>{t("No_more_credits")}</span>)
    }
  }

  async function handlePurchaseClick(credits) {
    // Vérifiez si l'utilisateur a un stripeCustomerId
    if (!context.userInfos.stripeCustomerId) {
      // Si l'utilisateur n'a pas de stripeCustomerId, créez un client Stripe pour lui
      const response = await fetch(process.env.REACT_APP_BASEURL + `/api/user/createStripeCustomer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${context.token || localStorage.getItem('Token')}`
        },
        body: JSON.stringify({ email: context.userInfos.email })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      const stripeCustomerId = data.stripeCustomerId;
      context.userInfos.stripeCustomerId = stripeCustomerId;
    }
  
    // Ouvrez la page de paiement Stripe
    let stripeUrl;
    switch (credits) {
      case 100:
        stripeUrl = `https://buy.stripe.com/28o7uEbND133aoU001`;
        break;
      case 500:
        stripeUrl = `https://buy.stripe.com/7sI16g2d3aDDfJe28a`;
        break;
      case 1000:
        stripeUrl = `https://buy.stripe.com/14k4isdVLaDDaoU003`;
        break;
      default:
        throw new Error('Invalid credit amount');
    }
    window.open(`${stripeUrl}?prefilled_email=${context.userInfos.email}&client_reference_id=${context.userInfos.id}&metadata=${credits}`);
  }
  

  // async function createSubscription() {
  //   // Lorsque l'utilisateur clique sur le bouton "S'abonner"
  //   const stripe = await stripePromise;
  //   const {error} = await stripe.redirectToCheckout({
  //     lineItems: [{price: 'price_1NPkCOLboiaoM38lFkHEs4dS', quantity: 1}],
  //     mode: 'subscription',
  //     successUrl: 'https://presspilot.io/success',
  //     cancelUrl: 'https://presspilot.io/canceled',
  //   });
  
  //   if (error) {
  //     console.warn('Error:', error);
  //   }
  // }

  if(context.isAuthenticate) {
    return (
      <>
        <div className="userDashboard">
          <div className="wrapConnect">
            <NavDashboard />
            <main id="UserDashboard">
              <h1>{t("Achat_de_Credits")}</h1>
              <div className="">
                {noMoreCredit()}
                <div className="creditPacksContainer">
                  <div className="creditPack">
                    <h3>100 {t("credits")}</h3>
                    <p>{t("pack1_description")}</p>
                    <p>{t("Prix")} : 30€</p>
                    <button className="smallCta red" onClick={() => handlePurchaseClick(100)}>{t("Acheter")}</button>
                  </div>
                  <div className="creditPack">
                    <h3>500 {t("credits")}</h3>
                    <p>{t("pack2_description")}</p>
                    <p>{t("Prix")} : 135€ (10% {t("de_reduction")})</p>
                    <button className="smallCta red" onClick={() => handlePurchaseClick(500)}>{t("Acheter")}</button>
                  </div>
                  <div className="creditPack">
                    <h3>1 000 {t("credits")}</h3>
                    <p>{t("pack3_description")}</p>
                    <p>{t("Prix")} : 240€ (20% {t("de_reduction")})</p>
                    <button className="smallCta red" onClick={() => handlePurchaseClick(1000)}>{t("Acheter")}</button>
                  </div>
                </div>
              </div>

              
              <br />
              {/* <h1>Abonnements</h1>
              <div>
                <div className="creditPacksContainer">
                  <div className="creditPack">
                    <h3>Gratuit</h3>
                    <p>Bénéficiez d'un pack de 100 crédits pour envoyer vos communiqués de presse à de nombreux journalistes</p>
                    <p>Prix : 30€</p>
                    <button className="smallCta red" onClick={()=> window.open(`https://buy.stripe.com/test_aEU5nTgWydoFeFqdQR?prefilled_email=${context.userInfos.email}&client_reference_id=${context.userInfos.id}`)}>Acheter</button>
                  </div>
                  <div className="creditPack">
                    <h3>39,90€ <span className="month">par mois</span></h3>
                    <p>Bénéficiez d'un pack de 500 crédits pour envoyer vos communiqués de presse à de nombreux journalistes</p>
                    <p>Prix : 135€ (10% de réduction)</p>
                    <button className="smallCta red disabled">Acheter</button>
                  </div>
                  <div className="creditPack">
                    <h3>99,90€ <span className="month">par mois</span></h3>
                    <p>Bénéficiez d'un pack de 500 crédits pour envoyer vos communiqués de presse à de nombreux journalistes</p>
                    <p>Prix : 135€ (10% de réduction)</p>
                    <button className="smallCta red disabled">Acheter</button>
                  </div>
                </div>
              </div> */}
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Credits;
