import { useEffect, useState, useContext } from "react";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n";

import { Modal, Switch } from "antd";
import mixpanel from "mixpanel-browser";

import { AuthContext } from "../context/AuthContext.js";

// STYLES
import "./PlansSettings.css";

function PlansSettings({ isVisible, onClose }) {
  const context = useContext(AuthContext);

  const { t, i18n } = useTranslation();
  const [monthlyPricing, setMonthlyPricing] = useState(true);

  const handleToggle = () => {
    setMonthlyPricing(!monthlyPricing);
  };

  const productName = context.teamDetails?.plan?.[0]?.productName;
  const currency = context.teamDetails?.currency;

  function ManageSubscription() {
    window.open(
      "https://billing.stripe.com/p/login/fZebLhb2o30W6K45kk",
      "_blank"
    );
  }

  async function handlePurchaseClick(plan, price) {
    // Vérifiez si l'utilisateur a un stripeCustomerId
    if (!context.userInfos.stripeCustomerId) {
      // Si l'utilisateur n'a pas de stripeCustomerId, créez un client Stripe pour lui
      const response = await fetch(
        process.env.REACT_APP_BASEURL + `/api/user/createStripeCustomer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              context.token || localStorage.getItem("Token")
            }`,
          },
          body: JSON.stringify({ email: context.userInfos.email }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      mixpanel.track("Payment_Settings", {
        Price: price,
        Plan: plan,
      });

      const data = await response.json();
      const stripeCustomerId = data.stripeCustomerId;
      context.userInfos.stripeCustomerId = stripeCustomerId;
    }

    let paymentURL;
    if (currency === "EUR") {
      switch (plan) {
        case "Pro":
          paymentURL =
            price === 9
              ? `https://buy.stripe.com/9AQ3eo7xn9zz1SobIQ`
              : `https://buy.stripe.com/fZe8yI18ZeTTdB6dR0`;
          break;
        case "Ultimate":
          paymentURL =
            price === 69
              ? `https://buy.stripe.com/cN2eX64lb9zz54A5kp`
              : `https://buy.stripe.com/14kcOYdVL277fJe3cl`;
          break;
      }
    } else if (currency === "USD") {
      switch (plan) {
        case "Pro":
          paymentURL =
            price === 9
              ? `https://buy.stripe.com/7sI16gg3TaDD7cIcN0`
              : `https://buy.stripe.com/4gwaGQ6tj6nn68E14h`;
          break;
        case "Ultimate":
          paymentURL =
            price === 69
              ? `https://buy.stripe.com/9AQdT25pffXXfJe9AM`
              : `https://buy.stripe.com/dR6bKU9Fv13368EdR1`;
          break;
      }
    }

    window.open(
      `${paymentURL}?prefilled_email=${context.userInfos.email}&client_reference_id=${context.teamId}`
    );
  }

  const renderPlanButton = (planName, price) => {
    const currentPlan = context.teamDetails?.plan?.[0]?.productName;

    if (currentPlan === "Pro" && planName === "Ultimate") {
      return (
        <button
          className="buyButton"
          onClick={() => handlePurchaseClick(planName, price)}
        >
          <span>{t("Upgrade_to_ultimate")}</span>
        </button>
      );
    } else if (currentPlan === planName) {
      return (
        <button className="buyButton" onClick={() => ManageSubscription()}>
          <span>{t("Manage_my_Subscription")}</span>
        </button>
      );
    } else {
      return (
        <button
          className="buyButton"
          onClick={() => handlePurchaseClick(planName, price)}
        >
          <span>
            {planName === "Pro" ? t("Level_Up_with_Pro!") : t("Reach_the_Peak")}
          </span>
        </button>
      );
    }
  };

  return (
    <>
      <div className="modalPlanList">
        <div className="">
          <div className="containerPlan">
            <div className="paddingContain">
              <h4>{t("Pro")} </h4>
              <p className="descriptifPriceTitle">
                {t("perfect_plan_for_professionals")}
              </p>
              <hr className="ligne" />
              <div className="mb10 mt10">
                <span className="price">
                  {currency === "EUR" ? t("euro") : t("dollar")}
                  {monthlyPricing ? "9" : "15"}{" "}
                </span>
                <span className="monthsPrices">/ {t("month")}</span>
              </div>
              <p>{t("Optimize_your_online_presence_tracking_with_pro_plan")}</p>
              {monthlyPricing
                ? renderPlanButton("Pro", 9)
                : renderPlanButton("Pro", 15)}
              <br />
              <ul>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>
                  <span> 3 {t("keywords")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>
                  <span> 3 {t("users")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("daily_updates")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("email_notification")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("browser_notification")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("social_sharing")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("Sentiment_Analysis")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-xmark"></i>{" "}
                  <span>{t("IA_Features")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-xmark"></i>{" "}
                  <span>{t("facebook_tiktok_mentions")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-xmark"></i>{" "}
                  <span>{t("multiple_team")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-xmark"></i>{" "}
                  <span>{t("discord_slack_notifs")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-xmark"></i>{" "}
                  <span>{t("Software_Integrations")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-xmark"></i>{" "}
                  <span>{t("Export_data")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-xmark"></i>{" "}
                  <span>{t("Google_Search_Mentions")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-xmark"></i>
                  <span> {t("api")}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="containerPlansUltimate">
            <div className="paddingContain">
              <h4>{t("Ultimate")}</h4>
              <p className="descriptifPriceTitle">
                {t("Get_the_ultimate_experience")}
              </p>
              <hr className="ligne" />
              <div className="mb10 mt10">
                <span className="price">
                  {currency === "EUR" ? t("euro") : t("dollar")}
                  {monthlyPricing ? "69" : "115"}{" "}
                </span>
                <span className="monthsPrices">/ {t("month")}</span>
              </div>
              <p>{t("if_you_a_finance_manager_at_big_company")}</p>
              {monthlyPricing
                ? renderPlanButton("Ultimate", 69)
                : renderPlanButton("Ultimate", 115)}
              <br />
              <ul>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>
                  <span>10 {t("keywords")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("Up_to_5_members_per_team")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("real_time_updates")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("email_notification")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("browser_notification")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("social_sharing")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("Sentiment_Analysis")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("IA_Features")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("facebook_tiktok_mentions")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("multiple_team")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("discord_slack_notifs")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("Software_Integrations")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("Export_data")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("Google_Search_Mentions")}</span>
                </li>
                <li>
                  <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                  <span>{t("api")}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="actionButtons">
            {/* <button
              className="cancelButton"
              onClick={() => closeModalSettings()}
            >
              Cancel
            </button>
            <button className="ctaBase" onClick={() => closeModalSettings()}>
              <i className="fa-solid fa-floppy-disk"></i> Save
            </button> */}
            <div className="monthlyorYearly">
              <span
                className={`monthlyPrice ${monthlyPricing ? "" : "activehere"}`}
                onClick={handleToggle}
              >
                <span>{t("Monthly")}</span>
              </span>
              <span
                className={`yearlyPrice ${monthlyPricing ? "activehere" : ""}`}
                onClick={handleToggle}
              >
                {t("Yearly")} ({t("save_pourcentage")})
              </span>
            </div>{" "}
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default PlansSettings;
