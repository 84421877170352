import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "../context/AuthContext.js";
import { useGoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import Checkbox from "../components/Checkbox.js";
import NavDashboard from "../components/NavDashboard.js";

import "./UserProfile.css";

import { useTranslation } from "react-i18next";
import "../i18n";

function Profile() {
  const clientId =
    "6736777951-nm9cqal6qcgvit0envkga530ijfp1ssf.apps.googleusercontent.com";

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [nickname, setNickName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [surname, setSurname] = useState("");
  const [affiliateCode, setAffiliateCode] = useState("");
  const [newsLetter, setNewsLetter] = useState(false);
  const [selectedOption, setSelectedOption] = useState("1");
  const [referral, setReferral] = useState([]);
  const [error, setError] = useState({});
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const data = {
    email,
    setEmail,
    nickname,
    setNickName,
    companyName,
    setCompanyName,
    firstname,
    setFirstname,
    billingAddress,
    setBillingAddress,
    vatNumber,
    setVatNumber,
    surname,
    setSurname,
    newsLetter,
    setNewsLetter,
    selectedOption,
    setSelectedOption,
    affiliateCode,
    setAffiliateCode,
    referral,
    setReferral,
    error,
  };

  const disconnectGoogle = () => {};

  const disconnectUser = () => {
    context.setIsAuthenticate(false);
    context.setIsAdmin(false);
    disconnectGoogle();
    localStorage.removeItem("Token");
    navigate("/");
  };
  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleEditProfile = (e) => {
    e.preventDefault();
    const headers = { "Content-Type": "application/json" };
    if (context.token) {
      headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem("Token")) {
      headers.Authorization = `Bearer ${localStorage.getItem("Token")}`;
    }
    // const gamesSelected = games.filter(games => games.isChecked === true);
    fetch(process.env.REACT_APP_BASEURL + "/api/user/update-profile", {
      headers: new Headers(headers),
      method: "POST",
      body: JSON.stringify({
        email,
        nickname,
        firstname,
        surname,
        newsLetter,
        selectedOption,
        agency: {
          companyName,
          billingAddress,
          vatNumber,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setIsChanged(true);
          setTimeout(() => setIsChanged(false), 2000);
          context.setUserInfos({
            email: email,
            nickname: nickname,
            firstname: firstname,
            surname: surname,
            agency: {
              companyName,
              billingAddress,
              vatNumber,
            },
          });
        }
      });
  };

  useEffect(() => {
    //start to scrolltop 0
    window.scrollTo(0, 0);
    const fetchData = async () => {
      // const resGames = await fetch(process.env.REACT_APP_BASEURL + '/api/games');
      // const jsonGames = await resGames.json();
      const headers = { "Content-Type": "application/json" };
      if (context.token) {
        headers.Authorization = `Bearer ${context.token}`;
      } else if (localStorage.getItem("Token")) {
        headers.Authorization = `Bearer ${localStorage.getItem("Token")}`;
      }
      const resProfile = await fetch(
        process.env.REACT_APP_BASEURL + "/api/user/profile",
        { headers: new Headers(headers) }
      );
      const jsonProfile = await resProfile.json();

      // for(let i = 0; i < jsonGames.games.length; i++){
      //   if(jsonProfile.user.games.find(game => game.name === jsonGames.games[i].name)){
      //     jsonGames.games[i].isChecked = true;
      //   }else{
      //     jsonGames.games[i].isChecked = false;
      //   }
      // }
      setUser(jsonProfile.user);
      setEmail(jsonProfile.user.email);
      setNickName(jsonProfile.user.nickname);
      setFirstname(jsonProfile.user.firstname);
      setSurname(jsonProfile.user.surname);
      setAffiliateCode(
        process.env.REACT_APP_BASEURL +
          `/register?referral=${jsonProfile.user.affiliateCode}`
      );
      setCompanyName(jsonProfile.user.agency.companyName);
      setBillingAddress(jsonProfile.user.agency.billingAddress);
      setVatNumber(jsonProfile.user.agency.vatNumber);
      setNewsLetter(jsonProfile.user.newsLetter);
      setSelectedOption(jsonProfile.user.selectedOption);
      setReferral(jsonProfile.userAffiliated);
    };
    fetchData().catch(console.error);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (context.isAuthenticate) {
    return (
      <>
        <div className="userDashboard">
          <div className="">
            <NavDashboard />
            <main id="UserProfile">
              <h1>{t("Profile")}</h1>
              <p>Information about your profile</p>
              <br />
              <form>
                <fieldset>
                  <div>
                    <label className="labelUppercase">{t("email")}</label>
                    <input
                      id="email"
                      type="text"
                      className="disabled"
                      required
                      value={data.email}
                      // onChange={(e) => data.setNickName(e.target.value)}
                    />
                  </div>

                  <div>
                    <label className="labelUppercase">{t("First_Name")}</label>
                    <input
                      id="firstname"
                      type="text"
                      required
                      value={data.firstname}
                      onChange={(e) => data.setFirstname(e.target.value)}
                    />
                  </div>

                  <div>
                    <label className="labelUppercase">{t("Surname")}</label>
                    <input
                      id="surname"
                      type="text"
                      required
                      value={data.surname}
                      onChange={(e) => data.setSurname(e.target.value)}
                    />
                  </div>
                </fieldset>

                {/* <br />
                <h1>{t("Votre_entreprise")}</h1>
                <p>
                  {t(
                    "Merci_de_remplir_les_informations_concernant_votre_entreprise"
                  )}
                </p>
                <br />

                <fieldset>
                  <div>
                    <label className="labelUppercase">
                      {t("Company_name")}
                    </label>
                    <input
                      id="companyName"
                      type="text"
                      required
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>

                  <div>
                    <label className="labelUppercase">
                      {t("Company_address")}
                    </label>
                    <input
                      id="billingAddress"
                      type="text"
                      required
                      value={billingAddress}
                      onChange={(e) => setBillingAddress(e.target.value)}
                    />
                  </div>

                  <div>
                    <label className="labelUppercase">
                      {t("VAT_company_number")}
                    </label>
                    <input
                      id="vatNumber"
                      type="text"
                      value={vatNumber}
                      onChange={(e) => setVatNumber(e.target.value)}
                    />
                  </div>
                </fieldset> */}
              </form>
              <div className="mt30 tar">
                <button
                  onClick={handleEditProfile}
                  type="submit"
                  className="smallCta red"
                >
                  <span>{t("Confirm_changes")}</span>
                </button>
              </div>
              {isChanged && (
                <div className="modalContainer msgModal">
                  <div className="overlay"></div>
                  <div className="modal tac">
                    <i className="fas fa-check"></i>
                    <p className="bold">{t("Account_Updated")}</p>
                  </div>
                </div>
              )}
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default Profile;
