import { useEffect, useState, useContext } from "react";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n";

import { Modal, Switch } from "antd";
import mixpanel from "mixpanel-browser";

import { AuthContext } from "../context/AuthContext.js";

// STYLES
import "./PlansApi.css";

function PlansApi({ isVisible, onClose }) {
  const context = useContext(AuthContext);

  const { t, i18n } = useTranslation();
  const [monthlyPricing, setMonthlyPricing] = useState(true);

  const handleToggle = () => {
    setMonthlyPricing(!monthlyPricing);
  };

  function ManageSubscription() {
    window.open(
      "https://billing.stripe.com/p/login/fZebLhb2o30W6K45kk",
      "_blank"
    );
  }

  const productName = context.teamDetails?.plan?.[0]?.productName;
  const currency = context.teamDetails?.currency;

  const handlePurchaseClick = async (credits) => {
    let paymentURL;

    switch (credits) {
      case 1000:
        paymentURL = "https://buy.stripe.com/00gdT2eZPeTTbsYaEW";
        break;
      case 50000:
        paymentURL = "https://buy.stripe.com/eVa8yI18Z2772Ws5kB";
        break;
      case 500000:
        paymentURL = "https://buy.stripe.com/cN28yIaJzcLL54AdR6";
        break;
      default:
        return;
    }

    // Vérifiez si l'utilisateur a un stripeCustomerId
    if (!context.userInfos.stripeCustomerId) {
      try {
        // Si l'utilisateur n'a pas de stripeCustomerId, créez un client Stripe pour lui
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/api/user/createStripeCustomer`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                context.token || localStorage.getItem("Token")
              }`,
            },
            body: JSON.stringify({ email: context.userInfos.email }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        context.userInfos.stripeCustomerId = data.stripeCustomerId;
      } catch (error) {
        console.error("Error creating Stripe customer:", error);
        return;
      }
    }

    mixpanel.track("Payment_API_Plan", {
      Credits: credits,
    });

    window.open(
      `${paymentURL}?prefilled_email=${context.userInfos.email}&client_reference_id=${context.teamId}`,
      "_blank"
    );
  };

  const renderPlanButton = (credits) => {
    return (
      <button
        className="buyButton"
        onClick={() => handlePurchaseClick(credits)}
      >
        <span>{t("Buy_now")}</span>
      </button>
    );
  };

  return (
    <>
      <Modal
        open={isVisible}
        onCancel={onClose}
        footer={null}
        centered
        width={"100%"}
        style={{ borderRadius: "10px", maxWidth: "100%" }}
        className="plans-modal"
      >
        <div className="modalPlanList">
          <div className="plans-headerAPI">
            <div className="wrap">
              <div
                className="tac mb30"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  className="logoPricingAPI"
                  src="/logo.svg"
                  alt="Buska Logo"
                />
                <h6>{t("never_miss_any_mention_of_your_brand_again")}</h6>
              </div>
            </div>
            <div className="plans-content">
              <div className="containerPricingPlans">
                <h4> {t("Developper")} </h4>
                <p className="descriptifPriceTitle">
                  {t("for_research_or_lead_generation")}
                </p>
                <div className="mb10 mt10 center">
                  <span className="price">
                    {currency === "EUR" ? t("euro") : t("dollar")}
                    <span>50</span>
                  </span>
                </div>
                <ul>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> 1K {t("API_Credits")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("keyword_search")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span>
                      {" "}
                      {t("posts_likes")}{" "}
                      <span className="soonPastilleHome">{t("soon")}</span>
                    </span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span>
                      {" "}
                      {t("posts_comments")}{" "}
                      <span className="soonPastilleHome">{t("soon")}</span>
                    </span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("1_credit_per_sucessful_call")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("real_time_data_extraction")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("linkedin_reddit_twitter")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("100_GDPR_and_CCPA_compliant")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("Lifetime_available")}</span>
                  </li>
                </ul>
                {renderPlanButton(1000)}
              </div>
              <div className="containerPricingPlans">
                <h4> {t("Production")} </h4>
                <p className="descriptifPriceTitle">
                  {t("for_lead_gen_or_analysis")}
                </p>
                <div className="mb10 mt10 center">
                  <span className="price">
                    {currency === "EUR" ? t("euro") : t("dollar")}
                    <span>500</span>
                  </span>
                </div>
                <ul>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> 50K {t("API_Credits")}</span>
                  </li>

                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("keyword_search")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span>
                      {" "}
                      {t("posts_likes")}{" "}
                      <span className="soonPastilleHome">{t("soon")}</span>
                    </span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span>
                      {" "}
                      {t("posts_comments")}{" "}
                      <span className="soonPastilleHome">{t("soon")}</span>
                    </span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("1_credit_per_sucessful_call")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("real_time_data_extraction")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("linkedin_reddit_twitter")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("100_GDPR_and_CCPA_compliant")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("Lifetime_available")}</span>
                  </li>
                </ul>
                {renderPlanButton(50000)}
              </div>
              <div className="containerPricingPlans">
                <h4> {t("Business")} </h4>
                <p className="descriptifPriceTitle">
                  {t("for_extraction_or_analysis")}
                </p>
                <div className="mb10 mt10 center">
                  <span className="price">
                    {currency === "EUR" ? t("euro") : t("dollar")}
                    <span>3000</span>
                  </span>
                </div>
                <ul>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> 500K {t("API_Credits")}</span>
                  </li>

                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("keyword_search")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span>
                      {" "}
                      {t("posts_likes")}{" "}
                      <span className="soonPastilleHome">{t("soon")}</span>
                    </span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span>
                      {" "}
                      {t("posts_comments")}{" "}
                      <span className="soonPastilleHome">{t("soon")}</span>
                    </span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("1_credit_per_sucessful_call")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("real_time_data_extraction")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("linkedin_reddit_twitter")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("100_GDPR_and_CCPA_compliant")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> {t("Lifetime_available")}</span>
                  </li>
                </ul>
                {renderPlanButton(500000)}
              </div>
            </div>
            <br />
            <h7 className="center white">
              {t("want_a_customized_plan")}
              <br />
              <a
                target="_blank"
                href="https://app.lemcal.com/@buska/intro-call"
              >
                {t("Cliquez_ici")}
              </a>
            </h7>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PlansApi;
