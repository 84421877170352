import { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../context/AuthContext.js";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";

// STYLES
import "./Rapport.css";

function Rapport() {
  const { t, i18n } = useTranslation();

  const context = useContext(AuthContext);
  const { idPressRelease } = useParams();

  const [countMedia, setCountMedia] = useState([]);
  const [object, setObject] = useState([]);
  const [sendAt, setSendAt] = useState([]);
  const [sentEmails, setSentEmails] = useState([]);

  const [opens, setOpens] = useState([]);
  const [sender, setSender] = useState([]);
  const [interestedCount, setInterestedCount] = useState([]);
  const [notInterestedCount, setNotInterestedCount] = useState([]);
  const [status, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  if (context.isAuthenticate) {
    return (
      <>
        <div className="userDashboard">
          <div className="">
            <NavDashboard />
            <main id="UserDashboard">
              <h1>{"Rapport"}</h1>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default Rapport;
