import React, { useState } from "react";
import "./Basic.css";
import "../../i18n";

function InstagramCaptionTool() {
  const [description, setDescription] = useState("");
  const [tone, setTone] = useState("Friendly");
  const [language, setLanguage] = useState("English");
  const [caption, setCaption] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleToneSelect = (selectedTone) => {
    setTone(selectedTone);
  };

  const handleLanguageSelect = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("Submitting:", { description, tone, language });
    try {
      const response = await fetch(
        process.env.REACT_APP_BASEURL +
          `/api/freetools/generate-caption-instagram`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ description, tone, language }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCaption(data.caption);
      } else {
        alert("Erreur lors de la génération de la légende.");
      }
    } catch (error) {
      console.error("Failed to generate caption:", error);
      alert("Erreur lors de la génération de la légende.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="globalContainer">
        <main className="BasicMain">
          <div className="wrap smallWrap">
            <form
              onSubmit={handleSubmit}
              className="content"
              style={{ textAlign: "left" }}
            >
              <div className="textPart mb30">
                <h1>
                  <label htmlFor="description">What is your post about?</label>
                </h1>
                <input
                  id="description"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Describe your Instagram post"
                  style={{ width: "100%", marginBottom: "10px" }}
                />
                <div className="toneSelection">
                  <h2>
                    <legend>Select the tone:</legend>
                  </h2>
                  <div>
                    {[
                      "Friendly",
                      "Professional",
                      "Empathic",
                      "Relaxed",
                      "Sad",
                      "Motivating",
                      "Adventurous",
                      "Humorous",
                    ].map((option) => (
                      <button
                        key={option}
                        type="button"
                        className={`buttonOption ${
                          tone === option ? "selected" : ""
                        }`}
                        onClick={() => handleToneSelect(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="languageSelection">
                  <h2>
                    <legend>Select the language:</legend>
                  </h2>
                  <div>
                    {["English", "French", "Spanish"].map((lang) => (
                      <button
                        key={lang}
                        type="button"
                        className={`buttonOption ${
                          language === lang ? "selected" : ""
                        }`}
                        onClick={() => handleLanguageSelect(lang)}
                      >
                        {lang}
                      </button>
                    ))}
                  </div>
                </div>
                <br />
                <button
                  className="buttonLanding"
                  type="submit"
                  style={{ marginTop: "20px" }}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Generate the Instagram Caption"}
                </button>
                {caption && (
                  <div className="captionResult" style={{ marginTop: "20px" }}>
                    <strong>Generated Caption:</strong> {caption}
                  </div>
                )}
              </div>
            </form>
          </div>
        </main>
      </div>
    </>
  );
}

export default InstagramCaptionTool;
