import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Tooltip } from "antd";
import { AuthContext } from "../../context/AuthContext.js";
import "./steps.css";
import mixpanel from "mixpanel-browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";

const platforms = [
  { name: "LinkedIn", icon: "fab fa-linkedin", isPremium: false },
  { name: "YouTube", icon: "fab fa-youtube", isPremium: false },
  { name: "Twitter", icon: "fab fa-twitter", isPremium: false },
  { name: "Instagram", icon: "fab fa-instagram", isPremium: false },
  {
    name: "News",
    customIcon: "/social/news.png",
    isPremium: false,
  },
  { name: "Internet", icon: "fab fa-google", isPremium: false },
  { name: "Reddit", icon: "fab fa-reddit", isPremium: false },
  { name: "Pinterest", icon: "fab fa-pinterest", isPremium: false },
  { name: "Medium", icon: "fab fa-medium", isPremium: false },
  { name: "Quora", icon: "fab fa-quora", isPremium: false },
  {
    name: "IndieHacker",
    customIcon: "/social/indiehacker.png",
    isPremium: false,
  },
  {
    name: "Everywhere",
    icon: "fab fa-google",
    isPremium: true,
  },
  { name: "Tiktok", icon: "fab fa-tiktok", isPremium: true },
  { name: "Facebook", icon: "fab fa-facebook", isPremium: true },
];

const Step1choose = ({ isVisible, onClose, onNext, onBack }) => {
  const { t, i18n } = useTranslation();
  const [teamName, setTeamName] = useState("");
  const [languagekeyword, setLanguagekeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState({});
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [showLinkedInMessage, setShowLinkedInMessage] = useState(false);
  const [isLinkedInCookieSet, setIsLinkedInCookieSet] = useState(false);

  const context = useContext(AuthContext);
  const { setTeamId } = useContext(AuthContext);

  useEffect(() => {
    if (selectedPlatforms.includes("LinkedIn")) {
      checkLinkedInCookie();
    }
  }, [selectedPlatforms]);

  const checkLinkedInCookie = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/user/linkedin-cookie`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              context.token || localStorage.getItem("Token")
            }`,
          },
        }
      );

      const responseData = await response.json();
      if (response.ok && responseData.linkedinCookiePresent) {
        setIsLinkedInCookieSet(true);
      } else {
        setIsLinkedInCookieSet(false);
      }
    } catch (error) {
      console.error("Error checking LinkedIn cookie:", error);
      setIsLinkedInCookieSet(false);
    }
  };

  const handleNextClick = async () => {
    if (selectedPlatforms.length === 0) {
      toast.error(t("Please_select_at_least_one_platform"));
      return;
    }

    // Si LinkedIn est sélectionné, vérifier si le cookie est présent
    if (selectedPlatforms.includes("LinkedIn")) {
      await checkLinkedInCookie(); // Vérifier le cookie LinkedIn

      if (!isLinkedInCookieSet) {
        toast.error(t("Please_download_the_extension_and_connect_LinkedIn"));
        return;
      }
    }

    // if (selectedPlatforms.includes("LinkedIn") && !isLinkedInCookieSet) {
    //   toast.error(t("Please_download_the_extension_and_connect_LinkedIn"));
    //   return;
    // }

    const headers = {
      "Content-Type": "application/json",
    };
    if (context.token) {
      headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem("Token")) {
      headers.Authorization = `Bearer ${localStorage.getItem("Token")}`;
    }

    const requestBody = {
      teamId: context.teamId,
      platforms: selectedPlatforms,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + "/api/teams/update-selected-platforms",
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              context.token || localStorage.getItem("Token")
            }`,
          }),
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        mixpanel.track("Onboarding", {
          Step: "2",
        });
        onNext();
      } else {
        console.error("Failed to send data:", responseData);
      }
    } catch (error) {
      console.error("There was an error sending the data:", error);
    }
  };

  const togglePlatform = (platform) => {
    // if (platform.name === "LinkedIn") {
    //   return;
    // }

    if (platform.isPremium) {
      return;
    }

    setSelectedPlatforms((prev) =>
      prev.includes(platform.name)
        ? prev.filter((item) => item !== platform.name)
        : [...prev, platform.name]
    );

    if (platform.name === "LinkedIn") {
      setShowLinkedInMessage((prev) => !prev);
    }
  };

  return (
    <Modal
      className="customModal"
      open={isVisible}
      maskStyle={{
        background:
          "linear-gradient(130deg, #FFD788 28.79%, #FFBFBF 64.77%, #DDA5FF 100%)",
      }}
      footer={false}
      maskClosable={false}
      closable={false}
    >
      <div className="leftPartOnboarding">
        <h2>Plateforms to track</h2>
        <p>Choose which platform you want to track for your keyword.</p>
        <br />

        <div className="platforms-container">
          {platforms.map((platform) => (
            // <Tooltip
            //   key={platform.name}
            //   title={
            //     platform.name === "LinkedIn"
            //       ? t("linkedin_has_been_disabled_for_the_moment")
            //       : ""
            //   }
            // >
            <div
              className={`platform-card ${
                selectedPlatforms.includes(platform.name) ? "selected" : ""
              } ${platform.isPremium ? "premium" : ""}`}
              onClick={() => togglePlatform(platform)}
            >
              {platform.customIcon ? (
                <img
                  src={platform.customIcon}
                  alt={platform.name}
                  className="platform-icon"
                />
              ) : (
                <i className={platform.icon}></i>
              )}
              <span>{platform.name}</span>
              {platform.isPremium && (
                <span className="premium-tag">{t("PREMIUM")}</span>
              )}
            </div>
            // </Tooltip>
          ))}
        </div>

        {showLinkedInMessage && (
          <div className="linkedin-message">
            <p>
              {t("to_monitor_linkedin_you_must_download_the_chrome_ext")}
              <a
                href="https://chromewebstore.google.com/detail/buska-linkedin/fbmgcejhoneccecnplfllgkfgheoengm"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="downloadExtension">{t("download_here")}</span>
              </a>
            </p>
            <br />
            <i className="fa-solid fa-circle-info"></i>{" "}
            <a
              className="nolink"
              target="_blank"
              href="https://www.buska.io/ressources/how-to-monitor-linkedin-with-buska-a-step-by-step-guide"
            >
              {t("click_here_if_you_need_assistance_to_connect_the_extension")}
            </a>{" "}
          </div>
        )}

        {/* {showLinkedInMessage && (
          <div className="linkedin-message">
            <p>{t("linkedin_has_been_disabled_for_the_moment")}</p>
          </div>
        )} */}

        <div className="alignVert">
          <div style={{ textAlign: "left" }}>
            <Button
              key="submit"
              className="CtaOnboardingv2Back"
              onClick={onBack}
            >
              <i className="fa-solid fa-angle-left"></i>
            </Button>
          </div>

          <div style={{ textAlign: "right" }}>
            <Button
              key="submit"
              className="CtaOnboardingv2"
              onClick={handleNextClick}
            >
              {t("Continue")} <i className="fa-solid fa-arrow-right"></i>
            </Button>
          </div>
        </div>
        <div className="footerButtonSteps3"></div>
      </div>
    </Modal>
  );
};

export default Step1choose;
