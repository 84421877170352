import { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { AuthContext } from "../context/AuthContext.js";
import { useRef, useState, useEffect } from "react";

import Plans from "./Plans.js";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n.js";

import mixpanel from "mixpanel-browser";

// import moment from "moment";
import moment from "moment-timezone";

import "moment/locale/fr";

import Loader from "./Loader.js";

// STYLES
import "./MenuFeed.css";

const MenuFeed = (props) => {
  const context = useContext(AuthContext);

  const {
    allPosts,
    // selectedSources,
    // setSelectedSources,
    selectedLanguages,
    setSelectedLanguages,
    setIsClockView,
    isClockView,
    onMentionFilterChange,
  } = props;

  const { teamDetails } = context;
  const { isFreeTrialOver } = useContext(AuthContext);

  const { selectedSources, setSelectedSources } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [nbrResults, setNbrResults] = useState(0);
  const [dataFromSearch, setDataFromSearch] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [isDropdownOpenFilter, setIsDropdownOpenFilter] = useState(false);
  const [isDropdownOpenNonRead, setIsDropdownOpenNonRead] = useState(false);
  const [allSources, setAllSources] = useState([]);
  const [nombreResults, setNombreResults] = useState(0);
  const [nombreResultsNew, setNombreResultsNew] = useState(0);
  // const [allPosts, setAllPosts] = useState([]);
  const premiumOnlySources = ["tiktok", "facebook"];

  const allAvailableSources = [
    "linkedin",
    "twitter",
    "reddit",
    "googleNews",
    "facebook",
    "internet",
    "youtube",
    "pinterest",
    "medium",
    "tiktok",
    "indiehacker",
    "everywhere",
  ];

  const [timeRemaining, setTimeRemaining] = useState("");
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [mentionFilter, setMentionFilter] = useState("all");
  const [isDropdownOpenMention, setIsDropdownOpenMention] = useState(false);
  const [unreadMentionsCount, setUnreadMentionsCount] = useState(0);
  const [detectedLanguages, setDetectedLanguages] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const languages = [
      ...new Set(props.allPosts.map((post) => post.language).filter(Boolean)),
    ];
    setDetectedLanguages(languages);
  }, [props.allPosts]);

  const dropdownRef = useRef(null);
  const filterDivRef = useRef(null);
  const noRead = useRef(null);
  const mentionDropdownRef = useRef(null);

  const keyword = context.keyword;

  const toggleClockView = () => {
    mixpanel.track("Change View", { Select: "Time" });
    setIsClockView(true);
  };

  const handleGripClick = () => {
    mixpanel.track("Change View", { Select: "Grid" });
    setIsClockView(false);
  };

  const handleDropdownClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !filterDivRef.current.contains(event.target)
    ) {
      setIsDropdownOpenFilter(false);
    }
  };

  const handleDropdownClickOutsideNoRead = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !noRead.current.contains(event.target)
    ) {
      setIsDropdownOpenNonRead(false);
    }
  };

  useEffect(() => {
    // Fonction pour mettre à jour la locale de moment
    const updateMomentLocale = () => {
      let currentLang = localStorage.getItem("i18nextLng") || "en";
      if (currentLang === "en-EN") {
        currentLang = "en"; // Convert 'en-EN' to 'en'
      }
      moment.locale(currentLang);
    };

    // Mettre à jour la locale lors du premier chargement du composant
    updateMomentLocale();

    // Mettre à jour la locale chaque fois que la langue change
    i18n.on("languageChanged", updateMomentLocale);

    // Nettoyer en supprimant le gestionnaire d'événements lorsque le composant est démonté
    return () => {
      i18n.off("languageChanged", updateMomentLocale);
    };
  }, [i18n]);

  const calculateTimeRemaining = (plan, isFreeTrialOver) => {
    let currentLang = localStorage.getItem("i18nextLng") || "en";
    if (currentLang === "en-EN") {
      currentLang = "en"; // Convert 'en-EN' to 'en'
    }
    moment.locale(currentLang);

    const now = moment();
    let nextUpdate;

    // Si l'utilisateur est en période d'essai, traitez comme "Pro"
    if (!isFreeTrialOver && plan === "Free") {
      plan = "Pro";
    }

    switch (plan) {
      case "Pro":
        // Plan Pro s'exécute une fois par jour à 15h30
        nextUpdate = now.clone().hour(15).minute(30).second(0);
        if (now.isAfter(nextUpdate)) {
          nextUpdate.add(1, "day"); // Ajoutez 1 jour si c'est déjà passé
        }
        break;

      case "Ultimate":
        // Plan Ultimate s'exécute toutes les 3 heures entre 6h et 20h
        nextUpdate = now.clone().minute(0).second(0);

        if (now.hour() >= 6 && now.hour() <= 20) {
          // Trouver la prochaine heure d'actualisation basée sur les intervalles de 3h
          const nextInterval = Math.ceil(now.hour() / 3) * 3; // Ex: 6, 9, 12, 15, 18
          nextUpdate.hour(nextInterval);
          if (now.hour() === nextInterval && now.minute() >= 0) {
            nextUpdate.add(3, "hours"); // Si déjà atteint, passe au suivant
          }
        } else if (now.hour() < 6) {
          nextUpdate.hour(6); // Si avant 6h, l'actualisation est à 6h
        } else {
          nextUpdate.add(1, "day").hour(6); // Si après 20h, prochaine à 6h le lendemain
        }
        break;

      case "Free":
        // Plan Free, s'exécute une fois par semaine
        nextUpdate = now.clone().startOf("isoWeek").add(1, "week");
        if (
          now.isoWeekday() === 1 &&
          now.isSame(now.clone().startOf("day"), "day")
        ) {
          nextUpdate = nextUpdate.add(0, "week");
        }
        nextUpdate.startOf("day");
        break;

      default:
        nextUpdate = now; // Ou valeur par défaut
    }

    const duration = moment.duration(nextUpdate.diff(now));
    let timeString = "";

    // Vérifier si le temps total restant est inférieur à une heure
    if (duration.asHours() < 1) {
      // Afficher uniquement les minutes pour les temps inférieurs à une heure
      timeString = `${duration.minutes()} ${t("min")}`;
    } else {
      // Affichage standard pour les durées de plus d'une heure
      if (duration.days() > 0) {
        timeString += `${duration.days()} ${t("jour")} `;
      }
      if (duration.hours() > 0 || timeString.length > 0) {
        timeString += `${duration.hours()} ${t("hour")} `;
      }
      if (plan === "Ultimate") {
        timeString += `${duration.minutes()} ${t("min")} `;
      }
    }

    return timeString.trim(); // Retirer les espaces excédentaires
  };

  const userPlan = context.teamDetails?.plan?.[0]?.productName ?? "Free";

  useEffect(() => {
    // Supposons que `userPlan` et `isFreeTrialOver` soient fournis par votre contexte ou état
    const timeRemaining = calculateTimeRemaining(userPlan, isFreeTrialOver);
    setTimeRemaining(timeRemaining);
  }, [userPlan, isFreeTrialOver]);

  const handleMentionFilterChange = (filter) => {
    const isPremium = teamDetails?.premium ?? false;

    if (filter === "unread" && !isPremium) {
      // Si l'utilisateur n'est pas premium et essaie d'accéder aux nouvelles mentions
      setShowPlansModal(true); // Afficher le modal pour passer à un plan premium
    } else {
      // Si l'utilisateur est premium ou sélectionne "Toutes les mentions"
      setMentionFilter(filter); // Mettre à jour le filtre
      onMentionFilterChange(filter); // Appeler la fonction de changement de filtre
      setIsDropdownOpenNonRead(false); // Fermer le menu déroulant
    }
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const timeRemaining = calculateTimeRemaining(userPlan);
  //     setTimeRemaining(timeRemaining);
  //   }, 1000 * 60); // Mettre à jour toutes les minutes

  //   // Clear the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [userPlan]);

  useEffect(() => {
    // Calculer l'intervalle en minutes en fonction du plan
    const updateIntervalInMinutes = userPlan === "Ultimate" ? 180 : 1440;

    // Créer l'intervalle d'actualisation des mentions
    const intervalId = setInterval(() => {
      const timeRemaining = calculateTimeRemaining(userPlan);
      setTimeRemaining(timeRemaining);
    }, updateIntervalInMinutes * 1000 * 60); // Conversion des minutes en millisecondes

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, [userPlan]);

  useEffect(() => {
    // Ajouter l'écouteur lors du montage
    document.addEventListener("mousedown", handleDropdownClickOutside);

    // Supprimer l'écouteur lors du démontage
    return () => {
      document.removeEventListener("mousedown", handleDropdownClickOutside);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const teamId = context.teamId;
      const keyword = context.keyword;

      if (!teamId) return;

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      };

      const url = `${
        process.env.REACT_APP_BASEURL
      }/api/search/getSearchesByTeamId?teamId=${teamId}&keyword=${encodeURIComponent(
        keyword
      )}`;
      const response = await fetch(url, { headers });
      const result = await response.json();

      // Utiliser toutes les sources disponibles par défaut
      // setSelectedSources(allAvailableSources);

      // Compter le nombre total de résultats
      let totalPosts = 0;

      if (result.searches) {
        const filteredSearches = result.searches.filter(
          (search) => search.keyword === context.keyword
        );

        totalPosts = filteredSearches.reduce((acc, search) => {
          const searchTotalPosts = Object.values(search.results || {}).reduce(
            (innerAcc, source) => {
              return (
                innerAcc +
                (Array.isArray(source.data?.posts)
                  ? source.data.posts.length
                  : 0)
              );
            },
            0
          );
          return acc + searchTotalPosts;
        }, 0);
      }

      setNombreResults(totalPosts);
      setLoading(false);
    };
    fetchData();
  }, [context.teamId]);

  useEffect(() => {
    if (!dataFromSearch) return;

    let allPosts = [];

    dataFromSearch.searches.forEach((search) => {
      if (Array.isArray(selectedSources)) {
        selectedSources.forEach((source) => {
          if (
            search.results[source] &&
            Array.isArray(search.results[source].data?.posts)
          ) {
            search.results[source].data.posts.forEach((post) => {
              allPosts.push({
                ...post,
                sourceKey: source,
                searchDate: search.createdAt,
              });
            });
          }
        });
      }
    });

    allPosts.sort((a, b) => {
      // Convertir les dates en objets Date, et gérer les dates non valides
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      // Vérifier si les dates sont valides
      const isValidDateA = dateA instanceof Date && !isNaN(dateA);
      const isValidDateB = dateB instanceof Date && !isNaN(dateB);

      // Si les deux dates sont valides, comparez-les normalement
      if (isValidDateA && isValidDateB) {
        return dateB - dateA;
      }

      // Si la date A est invalide, placez-la à la fin
      if (!isValidDateA) {
        return 1;
      }

      // Si la date B est invalide, placez-la à la fin
      if (!isValidDateB) {
        return -1;
      }
    });

    // console.log("allPosts baby menu", allPosts);
    // setNombreResults(allPosts.length);
  }, [dataFromSearch, selectedSources, keyword]);

  useEffect(() => {
    if (!dataFromSearch) return;

    let nonReadCount = 0;

    dataFromSearch.searches.forEach((search) => {
      Object.values(search.results || {}).forEach((source) => {
        nonReadCount += (source.data?.posts || []).filter(
          (post) => !post.read
        ).length;
      });
    });

    setUnreadMentionsCount(nonReadCount);
  }, [dataFromSearch]);

  // const toggleSource = (source) => {
  //   setSelectedSources((prevSources) => {
  //     const allSourcesSelected =
  //       prevSources.length === allAvailableSources.length;
  //     const isSourceSelected = prevSources.includes(source);

  //     if (allSourcesSelected || (prevSources.length > 1 && isSourceSelected)) {
  //       return isSourceSelected
  //         ? [source]
  //         : [...prevSources.filter((s) => s !== source), source];
  //     } else if (isSourceSelected && prevSources.length === 1) {
  //       return allAvailableSources;
  //     } else if (!isSourceSelected) {
  //       return [...prevSources, source];
  //     } else {
  //       return prevSources.filter((s) => s !== source);
  //     }
  //   });
  // };

  const toggleSource = (source) => {
    if (selectedSources.includes(source)) {
      // Si le réseau est déjà sélectionné, on le retire
      setSelectedSources((prevSources) =>
        prevSources.filter((selectedSource) => selectedSource !== source)
      );
    } else {
      // Si le réseau n'est pas sélectionné, on l'ajoute
      setSelectedSources((prevSources) => [...prevSources, source]);
    }
  };

  const toggleSelectAllSources = () => {
    if (selectedSources.length === allAvailableSources.length) {
      // Toutes les sources sont sélectionnées, on les désélectionne
      setSelectedSources([]);
    } else {
      // Toutes les sources ne sont pas sélectionnées, on les sélectionne toutes
      setSelectedSources(allAvailableSources);
    }
  };

  // const toggleLanguageFilter = (language) => {
  //   setSelectedLanguages((currentLanguages) => {
  //     if (currentLanguages.includes(language)) {
  //       return currentLanguages.filter((lang) => lang !== language);
  //     } else {
  //       return [...currentLanguages, language];
  //     }
  //   });
  // };

  const toggleLanguageFilter = (language) => {
    setSelectedLanguages((prevLanguages) => {
      const allLanguagesSelected =
        prevLanguages.length === detectedLanguages.length;
      const isLanguageSelected = prevLanguages.includes(language);

      if (
        allLanguagesSelected ||
        (prevLanguages.length > 1 && isLanguageSelected)
      ) {
        return isLanguageSelected
          ? [language]
          : [...prevLanguages.filter((lang) => lang !== language), language];
      } else if (isLanguageSelected && prevLanguages.length === 1) {
        return detectedLanguages;
      } else if (!isLanguageSelected) {
        return [...prevLanguages, language];
      } else {
        return prevLanguages.filter((lang) => lang !== language);
      }
    });
  };

  // Fonction mise à jour pour envoyer une requête de mise à jour au backend
  // Fonction mise à jour pour envoyer une requête de mise à jour au backend
  const markAllAsReadOrUnread = async (read, postsData) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/search/searches/mark-read`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${context.token}`,
        },
        body: JSON.stringify({ postsData, read }), // Envoyez les données sous cette forme
      });

      if (!response.ok) {
        // Gérer l'erreur de la requête
        const error = await response.text();
        throw new Error(error);
      }

      // Traiter la réponse ici, si nécessaire
      console.log("Les posts ont été mis à jour avec succès");
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des posts: ", error.message);
    }
  };

  const handleMarkAllAsRead = () => {
    const postsData = allPosts.map((post) => ({
      postId: post.id,
      sourceKey: post.sourceKey,
    }));
    markAllAsReadOrUnread(true, postsData);
  };

  const handleMarkAllAsUnRead = () => {
    const postsData = allPosts.map((post) => ({
      postId: post.id,
      sourceKey: post.sourceKey,
    }));
    markAllAsReadOrUnread(false, postsData);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderIconForSource = (source) => {
    if (source === "googleNews") {
      return (
        <img
          src="/background/newsGoogle.png"
          className="googleFeed"
          alt="Google News"
        />
      );
    } else if (source === "indiehacker") {
      return (
        <img
          src="/social/indiehacker.png"
          className="googleFeed"
          alt="Indie Hacker"
        />
      );
    } else if (source === "internet") {
      return <i className="fa-brands fa-internet-explorer fa-1x"></i>;
    } else if (source === "twitter") {
      return <i className="fa-brands fa-x-twitter fa-1x"></i>;
    } else if (source === "everywhere") {
      return <i className="fa-solid fa-file fa-1x"></i>;
    } else {
      return <i className={`${source} fa-brands fa-${source} fa-1x`}></i>;
    }
  };

  useEffect(() => {
    // Supposons que `allPosts` contient tous les posts et que chaque post a un attribut `language`
    const filteredBySourceAndLanguage = allPosts.filter(
      (post) =>
        selectedSources.includes(post.source) &&
        (selectedLanguages.length === 0 ||
          selectedLanguages.includes(post.language))
    );

    setFilteredResults(filteredBySourceAndLanguage);
  }, [allPosts, selectedSources, selectedLanguages]);

  useEffect(() => {
    if (isDropdownOpenFilter && dropdownRef.current && filterDivRef.current) {
      const dropdownMenu = dropdownRef.current;
      const filterButton = filterDivRef.current.getBoundingClientRect();
      const dropdownRect = dropdownMenu.getBoundingClientRect();

      // Récupérer la position du bouton filtre et ajouter ou soustraire des pixels pour ajuster le positionnement
      let leftPosition = filterButton.left + 75; // Soustraire 5px par exemple pour décaler vers la gauche
      let topPosition = filterButton.bottom + window.scrollY - 50; // Soustraire 10px pour réduire l'espace avec le bouton

      // Vérifiez si le menu déroulant déborde sur le côté droit de l'écran
      const isOverflowingRight =
        leftPosition + dropdownRect.width > window.innerWidth;

      // Si c'est le cas, ajustez-le pour qu'il s'aligne à droite du bouton filtre et décalez-le un peu si nécessaire
      if (isOverflowingRight) {
        leftPosition = filterButton.right - dropdownRect.width - 5; // Ajustez ici aussi si nécessaire
      }

      // Appliquez la position calculée
      dropdownMenu.style.top = `${topPosition}px`;
      dropdownMenu.style.left = `${leftPosition}px`;
    }
  }, [isDropdownOpenFilter, allSources]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {isDropdownOpenFilter && (
        <div
          className="dropdownMenu"
          ref={dropdownRef}
          style={{ display: "flex", flexDirection: "row" }}
        >
          {/* Colonne pour les filtres existants */}
          <div className="filtersColumn" style={{ width: "60%" }}>
            <span style={{ paddingLeft: "10px" }} className="titleMenuColumn">
              {t("canals")}
            </span>

            <div className="select-deselect-all-container">
              <button
                className="titleMenuColumn"
                onClick={toggleSelectAllSources}
              >
                {selectedSources.length === allAvailableSources.length
                  ? t("Deselect_All")
                  : t("Select_All")}
              </button>
            </div>

            {allAvailableSources.map((source) => {
              const isPremiumSource = premiumOnlySources.includes(source);
              const isUserPremium = userPlan === "Ultimate";
              const isSourceSelected = selectedSources.includes(source);
              const isLinkedIn = source === "linkedin";

              const isLinkedInActivated =
                context.userInfos.linkedinCookieActivate;
              const canSelectLinkedIn = isLinkedIn ? isLinkedInActivated : true; // Vérifiez si LinkedIn peut être sélectionné

              return (
                <div
                  key={source}
                  className={`sourceToggle ${
                    isPremiumSource && !isUserPremium ? "premiumOnly" : ""
                  }`}
                  onClick={() => {
                    if (
                      (!isPremiumSource || isUserPremium) &&
                      canSelectLinkedIn
                    ) {
                      toggleSource(source);
                    }
                  }}
                >
                  <span className="ml10">
                    {renderIconForSource(source)}
                    <span className="ml10"></span>
                    {capitalizeFirstLetter(source)}
                  </span>

                  <input
                    type="checkbox"
                    className="ml10"
                    checked={isSourceSelected}
                    onChange={() => {
                      if (canSelectLinkedIn) toggleSource(source);
                    }}
                    onClick={(e) => e.stopPropagation()}
                    disabled={!canSelectLinkedIn} // Désactiver la checkbox si LinkedIn ne peut pas être sélectionné
                  />

                  {isPremiumSource && !isUserPremium ? (
                    <a onClick={() => setShowPlansModal(true)}>
                      <span className="premiumFeature">{t("PREMIUM")}</span>
                    </a>
                  ) : isSourceSelected ? (
                    <i
                      className="ml10 fa-solid fa-circle-xmark"
                      style={{
                        color: canSelectLinkedIn ? "#9CD323" : "#FF0000",
                      }} // Coche verte ou croix rouge
                    ></i>
                  ) : (
                    isLinkedIn &&
                    !isLinkedInActivated && (
                      <i
                        className="ml10 fa-solid fa-times-circle"
                        style={{ color: "#FF0000" }}
                      ></i>
                    )
                  )}
                </div>
              );
            })}
          </div>

          {/* Nouvelle colonne pour les filtres de langue */}
          <div
            className="languagesColumn"
            style={{ flex: 1, marginLeft: "20px" }}
          >
            <span className="titleMenuColumn">{t("languages")}</span>
            {detectedLanguages.map((language) => (
              <div
                key={language}
                className={`langueToggle ${
                  selectedLanguages.includes(language) ? "selected" : ""
                }`}
                onClick={() => toggleLanguageFilter(language)}
              >
                <img
                  src={`https://flagcdn.com/16x12/${
                    language === "en" ? "us" : language
                  }.png`}
                  onError={(e) => (e.target.style.display = "none")} // Masque l'image en cas d'erreur de chargement
                  alt={`flag ${language === "en" ? "us" : language}`}
                />
                <span className="languageName">{language.toUpperCase()}</span>
                {selectedLanguages.includes(language) && (
                  <i
                    className="fa-solid fa-check-circle checkIcon"
                    style={{ color: "#9CD323" }}
                  ></i>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {isDropdownOpenNonRead && (
        <div className="dropdownMenuNoRead" ref={dropdownRef}>
          <div
            className="sourceToggle"
            onClick={() => handleMentionFilterChange("all")}
          >
            <span>{t("Show_all_mentions")}</span>
            {mentionFilter === "all" && (
              <i
                className="fa-solid fa-check-circle"
                style={{ color: "#9CD323" }}
              ></i>
            )}
          </div>
          <div
            className="sourceToggle"
            onClick={() => handleMentionFilterChange("unread")}
          >
            <span>{t("Show_unread_mentions")}</span>
            {mentionFilter === "unread" && (
              <i
                className="fa-solid fa-check-circle"
                style={{ color: "#9CD323" }}
              ></i>
            )}
            {!teamDetails?.premium && (
              <span className="premiumFeature">{t("PREMIUM")}</span>
            )}
          </div>
        </div>
      )}

      <div className="alignMid">
        <div className="menu-container">
          <div className="filtersContainer" style={{ display: "flex" }}>
            <div className="filterButtonContainer" ref={filterDivRef}>
              <div
                // ref={filterDivRef}
                className="filter filtresButton"
                onClick={() => setIsDropdownOpenFilter(!isDropdownOpenFilter)}
              >
                <i className="fa-solid fa-filter"></i> {t("Filters")}{" "}
                <i className="small fa-solid fa-chevron-down"></i>
              </div>
            </div>
          </div>
          <div className="middle-elements linkMenu">
            {/* <Link
              to="/new-mentions"
              style={{ display: "flex" }}
              className={
                location.pathname === "/new-mentions"
                  ? "buttonActiveMenu"
                  : "buttonNoActiveMenu"
              }
            >
              <i className="mr10 fa-solid fa-wand-sparkles"></i>{" "}
              <span className="menu-text">{t("new_mentions")}</span>{" "}
              <span
                className={
                  location.pathname === "/new-mentions"
                    ? "nbrResultsActif"
                    : "nbrResults"
                }
              >
                {unreadMentionsCount > 99 ? "+99" : unreadMentionsCount}
              </span>
            </Link> */}

            <Link
              to="/leads"
              style={{ display: "flex" }}
              className={
                location.pathname === "/leads"
                  ? "buttonActiveMenu"
                  : "buttonNoActiveMenu"
              }
            >
              <i className="mr10 fa-solid fa-users"></i>
              <span className="menu-text">{t("Social_inbox")}</span>
            </Link>
            <Link
              to="/feed"
              style={{ display: "flex" }}
              className={
                location.pathname === "/feed"
                  ? "buttonActiveMenu"
                  : "buttonNoActiveMenu"
              }
            >
              <i className="mr10 fa-solid fa-table-cells"></i>{" "}
              <span className="menu-text">{t("All_Mentions")}</span>{" "}
              {nombreResults && nombreResults > 0 ? (
                <span className="ml10">
                  {" "}
                  {nombreResults > 99 ? "+99" : nombreResults}{" "}
                </span>
              ) : (
                <span className="ml10">0</span>
              )}
            </Link>

            {/* {teamDetails && teamDetails.beta && ( */}

            {/* <div className="NewTag">
              <div className="arrow-container">
                <img src="/Arrow-left-pink.png" alt="Arrow" />
                <span className="arrow-text">{t("new")}</span>
              </div>
            </div> */}

            {/* )} */}

            {/* <Link
            to="/analytics"
            className={
              location.pathname === "/analytics" ? "active-linkMenu" : ""
            }
          >
            Analytics
          </Link> */}
          </div>
          {/* <div className="mentionFilterDropdown" ref={mentionDropdownRef}>
            <div
              className="mentionsNonLuButton filtresButton"
              onClick={() => setIsDropdownOpenNonRead(!isDropdownOpenNonRead)}
            >
              {mentionFilter === "all"
                ? t("show") + t("Show_all_mentions")
                : t("show") + t("Show_unread_mentions")}
              <i className="small fa-solid fa-chevron-down"></i>
            </div>
          </div> */}
          <div className="middle-elements linkMenu">
            {/* <i
              className={`fa-solid fa-clock ${isClockView ? "" : "notActive"}`}
              onClick={toggleClockView}
            ></i>
            <i
              className={`fa-solid fa-grip ${isClockView ? "notActive" : ""}`}
              onClick={handleGripClick}
            ></i> */}
            <Link
              to="/dashboard"
              className={
                location.pathname === "/dashboard"
                  ? "active-linkMenu"
                  : "buttonNoActiveMenu"
              }
            >
              <i className="mr10 fa fa-chart-line"></i> {t("Dashboard")}
            </Link>
          </div>
        </div>
        <div className="menuUpdateMentions">
          <div className="updateMention">
            {/* Conteneur pour centrer le texte */}
            <div className="updateMentionsContainer">
              <span className="updateMentions">
                {isFreeTrialOver ? (
                  <span
                    className="sousligne"
                    onClick={() => setShowPlansModal(true)}
                  >
                    {t("your_free_trial_is_over_click_here_to_update")}
                  </span>
                ) : (
                  <>
                    {t("we_will_update_your_new_mentions_in")} {timeRemaining}.{" "}
                    {userPlan !== "Ultimate" && (
                      <span
                        className="sousligne"
                        onClick={() => setShowPlansModal(true)}
                      >
                        {t("click_here_to_update_sooner")}
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
            <div className="menuKebab" onClick={toggleDropdown}>
              ...
            </div>
            {isDropdownOpen && (
              <div className="dropdownMenuReadMark">
                <div
                  className="dropdownItemReadMark"
                  onClick={handleMarkAllAsRead}
                >
                  {t("mark_all_as_read")}
                </div>
                <div
                  className="dropdownItemReadMark"
                  onClick={handleMarkAllAsUnRead}
                >
                  {t("mark_all_as_unread")}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="menuUpdateProblems">
          <div className="updateProblem">
            <div className="updateProblemsContainer">
              <span className="updateProblems">
                {t("problem_with_linkedin")}.
                <br />
                {t("during_this_time")}
              </span>
            </div>
          </div>
        </div> */}
      </div>
      <Plans
        isVisible={showPlansModal}
        onClose={() => setShowPlansModal(false)}
      />
    </>
  );
};
export default MenuFeed;
