import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../i18n";

import Loader from "../components/Loader.js";
import LoginModal from "../components/loginModal.js";

import "./AccountValidation.css";

function AccountValidation() {
  const { t, i18n } = useTranslation();

  const { activationToken } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const toggleModalLogin = () => {
    setIsOpenLogin(!isOpenLogin);
  };

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASEURL +
        `/api/user/account-validation/${activationToken}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setSuccess(true);
        }
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <main className="AccountValidationMain">
        <div className="wrap tac vec">
          <h1>{success ? t("account_validated") : t("error_try_again")}</h1>
          <p>
            {t("Your_account_has_been_activated")}
            <br />
            {t("you_can_now_login_to_your_dashboard")}
          </p>
          <a className="ctaPrimaryBlack" href="/login">
            {t("login")}
          </a>
        </div>
      </main>
    </>
  );
}

export default AccountValidation;
