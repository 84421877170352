import React, { useState, useContext } from "react";

import { AuthContext } from "../../context/AuthContext.js";

import { ToastContainer, toast } from "react-toastify";
import { InfoCircleOutlined, TagOutlined } from "@ant-design/icons";
import { Modal, Button, Tooltip, Input } from "antd";
import mixpanel from "mixpanel-browser";

import "react-toastify/dist/ReactToastify.css";
import "./steps.css";

import { useTranslation } from "react-i18next";

const Step1 = ({ isVisible, onClose, onNext }) => {
  const { t } = useTranslation();
  const [keywords, setKeywords] = useState([
    { keywordName: "", description: "", intent: "", website: "" },
  ]);
  const context = useContext(AuthContext);

  const handleKeywordChange = (index, value) => {
    const updatedKeywords = keywords.map((item, idx) =>
      idx === index ? { ...item, keywordName: value } : item
    );
    setKeywords(updatedKeywords);
  };

  const handleDescriptionChange = (index, value) => {
    const updatedKeywords = keywords.map((item, idx) =>
      idx === index ? { ...item, description: value } : item
    );
    setKeywords(updatedKeywords);
  };

  const handleIntentChange = (index, value) => {
    const updatedKeywords = keywords.map((item, idx) =>
      idx === index ? { ...item, intent: value } : item
    );
    setKeywords(updatedKeywords);
  };

  const handleURLChange = (index, value) => {
    const updatedKeywords = keywords.map((item, idx) =>
      idx === index ? { ...item, website: value } : item
    );
    setKeywords(updatedKeywords);
  };

  const handleAddKeyword = () => {
    if (keywords.length < 3) {
      setKeywords([
        ...keywords,
        { keywordName: "", description: "", intent: "", website: "" },
      ]);
    } else {
      toast.error(t("You_can_only_add_up_to_three_keywords"));
    }
  };

  const handleDeleteKeyword = (index) => {
    if (keywords.length > 1) {
      setKeywords(keywords.filter((_, idx) => idx !== index));
    } else {
      toast.error(t("At_least_one_keyword_is_required"));
    }
  };

  const handleNextClick = async () => {
    if (keywords.some((kw) => kw.keywordName.trim() && !kw.intent.trim())) {
      toast.error(t("Keyword_name_is_required"));
      return;
    }

    const filteredKeywords = keywords.filter((kw) => kw.keywordName);
    const requestBody = {
      keywords: filteredKeywords,
      language: "All",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/user/onboarding`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              context.token || localStorage.getItem("Token")
            }`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();
      if (response.ok) {
        localStorage.setItem("keywords", JSON.stringify(filteredKeywords));
        mixpanel.track("Onboarding", { Step: "1", Keyword: filteredKeywords });

        // Appel de la fonction pour envoyer les mots-clés et l'intention à Loops
        await sendKeywordsAndIntentToLoops(context.email, filteredKeywords);

        onNext();
      } else {
        toast.error(responseData.message || t("An_error_occurred"));
      }
    } catch (error) {
      toast.error(t("An_error_occurred"));
    }
  };

  const sendKeywordsAndIntentToLoops = async (email, keywords) => {
    const data = {
      email: email,
      keywords: keywords.map((kw) => ({
        keywordName: kw.keywordName,
        intent: kw.intent,
        description: kw.description,
        website: kw.website,
      })),
    };

    try {
      const response = await fetch(
        "https://app.loops.so/api/v1/contacts/update",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              context.token || localStorage.getItem("Token")
            }`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send keywords and intent");
      }

      const responseData = await response.json();
      console.log("Response from Loops:", responseData);
    } catch (error) {
      console.error("Error sending keywords and intent:", error);
    }
  };

  return (
    <>
      <Modal
        className="customModal"
        open={isVisible}
        footer={false}
        maskClosable={false}
        closable={false}
      >
        <div className="leftPartOnboarding">
          <h2>{t("Your_keyword")}</h2>
          <p>{t("What_do_you_want_to_monitor?")}</p>
          <p>{t("enter_the_keyword_and_buska_will_scan_the_web")}</p>
          <div className="keywords-container">
            {keywords.map((k, index) => (
              <div key={index} className="keyword-block">
                <h5>{t("Keyword")}* :</h5>
                <input
                  placeholder={t("Keyword")}
                  className="inputKeyword"
                  value={k.keywordName}
                  onChange={(e) => handleKeywordChange(index, e.target.value)}
                />
                <h5>{t("Keyword_intent")}* :</h5>
                <textarea
                  placeholder={t("Description_intent")}
                  className="textArea"
                  rows="3"
                  value={k.intent}
                  onChange={(e) => handleIntentChange(index, e.target.value)}
                />
                <h5>{t("Website_URL")} :</h5>
                <input
                  placeholder={t("Website_URL")}
                  className="inputKeyword"
                  value={k.website}
                  onChange={(e) => handleURLChange(index, e.target.value)}
                />
                <button
                  className="deleteKeyword"
                  onClick={() => handleDeleteKeyword(index)}
                >
                  Remove
                </button>
              </div>
            ))}
            {keywords.length < 3 && (
              <button className="addKeyword" onClick={handleAddKeyword}>
                + {t("add_a_new_keyword")}
              </button>
            )}
          </div>
          <Button className="ctaBase" onClick={handleNextClick}>
            {t("Continue")}
          </Button>
        </div>
      </Modal>

      <Modal
        className="customModal"
        open={isVisible}
        maskStyle={{
          background:
            "linear-gradient(130deg, #FFD788 28.79%, #FFBFBF 64.77%, #DDA5FF 100%)",
        }}
        footer={false}
        maskClosable={false}
        closable={false}
      >
        <div className="leftPartOnboarding">
          <h2>{t("Lets_define_what_well_monitor_for_you")}</h2>
          <p>{t("Choose_keyword_you_want_us_to_track_for_you")}</p>
          <p>{t("we_will_find_every_mention_that_include_your_keywords")}</p>
          <br />

          <div className="keywords-container">
            {keywords.map((k, index) => (
              <div key={index} className="keyword-block">
                <h5>{t("Keyword")}* :</h5>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    placeholder={t("placeholder_keyword_description")}
                    prefix={
                      <TagOutlined
                        style={{
                          color: "rgba(0,0,0,.25)",
                        }}
                      />
                    }
                    className="textArea"
                    value={k.keywordName}
                    onChange={(e) => handleKeywordChange(index, e.target.value)}
                    style={{ flex: 1 }}
                    suffix={
                      <Tooltip
                        title={
                          <div style={{ maxWidth: "none", maxHeight: "none" }}>
                            <img
                              src="/do_dont_onboarding.svg"
                              alt="Description"
                              style={{ width: "700px", height: "auto" }}
                            />
                          </div>
                        }
                        overlayStyle={{ maxWidth: "none", maxHeight: "none" }}
                        placement="bottom"
                      >
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      </Tooltip>
                    }
                  />
                </div>
                {/* <h5>{t("Details")}* :</h5>
                <textarea
                  placeholder={t("Description_keyword")}
                  className="textArea"
                  rows="3"
                  value={k.description}
                  onChange={(e) =>
                    handleDescriptionChange(index, e.target.value)
                  }
                /> */}
                <div>
                  <h5>{t("Keyword_intent")}* :</h5>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Tooltip
                      title={
                        <div style={{ maxWidth: "none", maxHeight: "none" }}>
                          <img
                            src="/do_dont_onboarding.svg"
                            alt="Description"
                            style={{ width: "700px", height: "auto" }}
                          />
                        </div>
                      }
                      overlayStyle={{ maxWidth: "none", maxHeight: "none" }}
                      placement="bottom"
                    >
                      <textarea
                        placeholder={t("placeholder_description_intent")}
                        className="textArea"
                        rows="3"
                        value={k.intent}
                        onChange={(e) =>
                          handleIntentChange(index, e.target.value)
                        }
                        style={{ width: "100%", paddingRight: "30px" }} // Ajustez padding pour l'espace de l'icône
                      />
                    </Tooltip>
                    <InfoCircleOutlined
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "rgba(0,0,0,.45)",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
                <h5>{t("Website_URL")} :</h5>
                <input
                  placeholder={t("URL_describe_keyword")}
                  className="textArea"
                  value={k.website}
                  onChange={(e) => handleURLChange(index, e.target.value)}
                />
                <button
                  className="deleteKeyword"
                  onClick={() => handleDeleteKeyword(index)}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </button>
              </div>
            ))}
            {keywords.length < 3 && (
              <button className="keyword-block-add" onClick={handleAddKeyword}>
                <span className="plusKeyword">+</span>
                <span className="textAddKeyword">{t("add_a_new_keyword")}</span>
              </button>
            )}
          </div>

          {/* <div>
          {keywords.map((k, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                paddingBottom: "20px",
              }}
            >
              <span className="keywordSelectOnboardingText">
                {k.keywordName}
              </span>
              <span className="keywordSelectOnboardingText">
                {k.description}
              </span>
              <span className="keywordSelectOnboardingText">{k.website}</span>
            </div>
          ))}
        </div> */}

          <div className="alertPurpleOnboarding">
            <span>{t("choosing_the_right_keyword_and_intent_is_crutial")}</span>
          </div>

          <div style={{ textAlign: "left" }}>
            <Button
              key="submit"
              className="CtaOnboardingv2"
              onClick={handleNextClick}
            >
              {t("Continue")} <i className="fa-solid fa-arrow-right"></i>
            </Button>
          </div>
          <div className="footerButtonSteps2"></div>
        </div>
      </Modal>
    </>
  );
};

export default Step1;
