import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import GoogleConnect from "../components/GoogleConnect.js";
import FacebookConnect from "../components/FacebookConnect.js";
import mixpanel from "mixpanel-browser";

import { AuthContext } from "../context/AuthContext.js";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Modal } from "antd";

import "./Login.css";
import "../i18n";

function LoginPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  const handleCloseModal = () => {
    setIsVisible(false);
  };

  const goToRegister = () => {
    navigate("/register");
  };

  const handleLogin = (e) => {
    e.preventDefault();

    // Convertir l'email en minuscules
    const lowercaseEmail = email.toLowerCase();

    fetch(process.env.REACT_APP_BASEURL + "/api/user/login", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: lowercaseEmail,
        password,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          localStorage.setItem("Token", res.token);
          context.setUserInfos({
            email: res.email,
          });
          context.setIsAuthenticate(true);
          context.setToken(res.token);
          context.setTeamId(res.teamId);
          // window.location.reload();
          mixpanel.identify(res.teamId);
          mixpanel.people.set({
            $email: res.email,
            $firstName: res.firstName,
            $lastName: res.surname,
            "Last Login": new Date(),
          });
          mixpanel.track("Login");
          navigate("/leads");
        } else {
          if (res.msg === "Account not confirmed, check your e-mail !") {
            setLoginError(t("account_not_confirmed"));
          } else {
            setLoginError(t("bad_credentials"));
          }
        }
      });
  };

  const handleForgetPassword = (e) => {
    e.preventDefault();
    const language = localStorage.getItem("i18nextLng");
    if (!localStorage.getItem("ForgetToken")) {
      fetch(process.env.REACT_APP_BASEURL + "/api/user/forget-password", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: email,
          language: language,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toast.info(res.msg);
          } else {
            toast.info(res.msg);
          }
          if (res.token) {
            localStorage.setItem("ForgetToken", res.token);
          }
        })
        .catch(console.error);
    } else {
      toast.success(t("Checked_your_mailbox"));
    }
  };

  const goToHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="fullContainer">
        <div className="card">
          <div className="loginContainer">
            <div className="rightSide">
              <main className="">
                <div className="loginWrap loginPage">
                  <img className="logoOverlay" src="Rating.svg" />

                  <a onClick={goToHome}>
                    <img className="logoLogin" src="/logo.svg" />
                  </a>
                  <p>{t("never_miss_any_mention_of_your_brand_again")}</p>
                  <GoogleConnect
                    className="connectGoogleButton"
                    buttonText={t("Continue_with_Google")}
                    label="Continue with Google"
                    methodeType="login"
                  />
                  <br />
                  <br />
                  <span className="lineLogin">or</span>
                  <br />
                  <br />
                  <form onSubmit={handleLogin}>
                    <div className={`form-group`}>
                      <input
                        placeholder="elon@spacex.com"
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className={`form-group`}>
                      <input
                        placeholder={t("password")}
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    {loginError && (
                      <div className="error-message">{loginError}</div>
                    )}

                    <button
                      type="submit"
                      className="ctaPrimaryBlack"
                      style={{ width: "100%", height: "40px" }}
                    >
                      {t("connexion")}
                    </button>
                    <br />
                    <br />
                    <a
                      href="#"
                      onClick={handleOpenModal}
                      className="register-link"
                    >
                      {t("Forgot_Password")}
                    </a>
                    <br />
                    <button
                      style={{ color: "#000", width: "100%" }}
                      className="ctaPrimaryHome"
                      onClick={goToRegister}
                    >
                      {t("you_dont_have_a_buska_account_yet")}{" "}
                      <span className="bold">{t("Register")}</span>
                    </button>
                    <Modal
                      title={t("Forgot_Password")}
                      open={isVisible}
                      onCancel={handleCloseModal}
                      footer={[
                        <button
                          key="back"
                          className="ctaThird"
                          onClick={handleCloseModal}
                        >
                          {t("Cancel")}
                        </button>,
                        <button
                          key="submit"
                          className="ctaPrimaryBlack"
                          onClick={handleForgetPassword}
                        >
                          {t("Send")}
                        </button>,
                      ]}
                    >
                      <input
                        type="email"
                        placeholder={t("Enter_your_email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Modal>
                  </form>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
