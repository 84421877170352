import React from "react";
import "./Loader.css";

import { useTranslation } from "react-i18next";

const Loader = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="loader-container">
      <div className="loader-content">
        <img
          src="/loadingMascot.svg"
          alt="Buska Image"
          className="loader-image"
        />
        <img src="/logo.svg" alt="Buska Image" className="logo-loader" />
        <p className="loader-text">
          <br />
          {t("I_am_scanning_the_entire_web_for_you_wait")}
        </p>
        <br />
        <div className="loader-bar"></div>
      </div>
    </div>
  );
};

export default Loader;
