import React from 'react';
import {useEffect, useState, useContext, Component} from 'react';
import {Link, useNavigate} from "react-router-dom";


import { useTranslation } from "react-i18next";

import {AuthContext} from "../context/AuthContext.js"

import {gapi} from 'gapi-script';
import {GoogleLogin} from 'react-google-login';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./FacebookConnect.css";

const lang = navigator.language || navigator.userLanguage;
const clientId = '741410305698-tufvav78a3llk11v3j2i4a45sdb83m2t.apps.googleusercontent.com';

let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
let passwordLength = 21;
let randomPass = "";
for (let i = 0; i <= passwordLength; i++) {
    let randomNumber = Math.floor(Math.random() * chars.length);
    randomPass += chars.substring(randomNumber, randomNumber +1);
}


const FacebookConnect = (props) => {
    const [error, setError] = useState('Error');
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const context = useContext(AuthContext);
    const { buttonText } = props;

    useEffect(() => {
      function start() {
        gapi.client.init({
            clientId: clientId,
            scope: 'email',
            plugin_name: "chat"
        });
      }
      
      gapi.load('client:auth2', start);
    }, []);


    const onSuccess = response => {

        const urlParams = new URLSearchParams(window.location.search);
        const referral = urlParams.get('ref');

        fetch(process.env.REACT_APP_BASEURL + '/api/user/registergoogle', {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            email: response.profileObj.email,
            nickname: response.profileObj.name,
            firstname: response.profileObj.givenName,
            surname: response.profileObj.familyName,
            password: randomPass,
            newsLetter: true,
            language: lang,
            selectedOption: "1",
            image: response.profileObj.imageUrl ,
            referral: referral
          })
        }).then(res => res.json()).then(res => {
          if (res.success) {
            const { companyName, billingAddress, vatNumber } = res.agency ? res.agency : { companyName: '', billingAddress: '', vatNumber: '' };
            context.setUserInfos({
              email: res.email,
              nickname: res.nickname,
              firstname: res.firstname,
              surname: res.surname,
              agency: {
                companyName,
                billingAddress,
                vatNumber,
              },
            });
            
            toast.success(t("Connexion_reussie"), {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000, // Durée d'affichage en ms
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            context.setToken(res.token);
            // if (res.isAdmin) {
            //   context.setIsAdmin(true);
            //     setTimeout(() => navigate('/admin'), 1000);
            // } else {
            //     // setTimeout(() => window.location.reload(), 100);
            //     // navigate('/dashboard');
            // }

          }
          else {
            setError({
              confirmPassword: res.msg
            });
          }
        });
    }

    const onFailure = response => {
        console.log('FAILED');
    };

    return(
        <div className="facebookConnectButton fbCta ctaGoogle">
          <GoogleLogin
            clientId={clientId}
            methodeType={props.label.methodeType}
            // buttonText={t("Connexion_avec_Google")}
            buttonText={buttonText}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
                    
        </div>
      
    )

}


export default FacebookConnect;