import { useState, useEffect } from "react";
import "./CookieModal.css";


// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

const CookieModal = () => {

  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const handleCookies = (e) => {
    e.preventDefault;
    localStorage.setItem("Cookies", "Cookies Accepted");
    setIsOpen(false);
  };

  useEffect(()=> {
    if(localStorage.getItem("Cookies")) {
        setIsOpen(false);
    }
  }, [isOpen]);

  return (
    isOpen &&
    <div className="modalCookie">
      <div className="cookieContent wrap tac">
        <p>{t("we_use_performance_cookies")}</p>
        <a onClick={handleCookies} className="smallCta white">{t("Ok")}</a>
      </div>
    </div>
  );
};

export default CookieModal;