import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import Footer from "../components/footer.js";

import "./BasicPage.css";
import "../i18n";

function Affiliate() {
  useEffect(() => {
    // start to scrolltop 0
    window.scrollTo(0, 0);

    // Load Tally script
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.onload = () => {
      if (window.Tally) window.Tally.loadEmbeds();
    };
    document.body.appendChild(script);
  }, []);

  const { t, i18n } = useTranslation();

  // Function to render the Tally iframe
  const renderTallyIframe = () => {
    return (
      <iframe
        data-tally-src="https://tally.so/embed/wg9rOl?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
        loading="lazy"
        width="100%"
        height="1"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="Become an affiliate"
      ></iframe>
    );
  };

  return (
    <>
      <div className="globalContainer">
        <main className="BasicMain">
          <div className="wrap smallWrap">
            <h1>{t("Become_an_affiliate")}</h1>
            <br />

            <div className="content" style={{ textAlign: "left" }}>
              <div className="textPart mb30">
                <h3 className="" style={{ textAlign: "left" }}>
                  {t("How_to_apply")}
                </h3>
                <br />
                <p>{t("welcome_to_partner_program_buska")}</p>
                <br />
                <p>{t("We_are_delighted_to_welcome_you")}</p>
                <br />
                <p>{t("few_point_to_understand")}:</p>
                <p>
                  - {t("payments_are_made_on_the_first_of_each_month")}
                </p>{" "}
                <p>- {t("a_minimum_treshold_of_50")}</p>{" "}
                <p>- {t("commissions_are_validated_30_days")}</p>{" "}
                <p>- {t("it_is_forbidden_to_promote_buska_with_ads")}</p>{" "}
                <p>
                  - {t("In_the_event_of_a_high_number_of_suspicious_sales")}
                </p>
                <br />
                <p>{t("If_you_think_you_re_the_ideal_person")}</p>
                <br />
                <p>{renderTallyIframe()}</p>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default Affiliate;
