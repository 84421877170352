import React, { useState, useEffect } from "react";

const TikTokEmbed = ({ link }) => {
  const [embedHtml, setEmbedHtml] = useState("");

  useEffect(() => {
    const fetchEmbedHtml = async () => {
      try {
        const response = await fetch(
          `https://www.tiktok.com/oembed?url=${link}`
        );
        const data = await response.json();
        if (data.html) {
          setEmbedHtml(
            data.html +
              `<script async src="https://www.tiktok.com/embed.js"></script>`
          );
        }
      } catch (error) {
        console.error("Failed to fetch TikTok oEmbed data:", error);
      }
    };

    fetchEmbedHtml();
  }, [link]);

  // Cette fonction tente de recharger les scripts TikTok après le rendu du HTML
  useEffect(() => {
    if (embedHtml) {
      const scriptTags = document
        .getElementsByClassName("tiktok-embed-container")[0]
        .getElementsByTagName("script");
      Array.from(scriptTags).forEach((oldScript) => {
        const newScript = document.createElement("script");
        newScript.src = oldScript.src;
        oldScript.parentNode.replaceChild(newScript, oldScript);
      });
    }
  }, [embedHtml]);

  return (
    <div
      className="tiktok-embed-container"
      dangerouslySetInnerHTML={{ __html: embedHtml }}
    />
  );
};

export default TikTokEmbed;
