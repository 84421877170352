import { Link } from "react-router-dom";

import "./NotFound.css";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

function NotFound() {
  const { t, i18n } = useTranslation();

  return (
    <main id="mainNotFound">
      <div className="wrap tac vac">
        <h1 className="NotFound">
          4<img style={{ marginRight: "-30px" }} src="/Confused.svg" />4
        </h1>
        <p>{t("Oops_not_found")}</p>
        <div>
          <Link className="ctaFullHome" to="/">
            {t("back_to_dashboard")}
          </Link>
        </div>
      </div>
    </main>
  );
}

export default NotFound;
