import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Loader from "../components/Loader.js";
import Footer from "../components/footer.js";

import { AuthContext } from "../context/AuthContext.js";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import "./ChangePassword.css";

function ChangePassword() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const search = useLocation().search;
  const token =
    new URLSearchParams(search).get("tokenForget") ||
    localStorage.getItem("Token");
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState();
  const data = {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    passwordError,
    setPasswordError,
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    if (password == confirmPassword) {
      fetch(process.env.REACT_APP_BASEURL + "/api/user/change-password", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          password,
          confirmPassword,
          token,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setPasswordError("");
          if (res.success) {
            localStorage.setItem("Token", res.token);
            context.setUserInfos({
              email: res.email,
            });
            context.setIsAuthenticate(true);
            context.setToken(res.token);
            context.setTeamId(res.teamId);
            navigate("/leads");
            window.location.reload();
          } else {
            setError({
              confirmPassword: res.msg,
            });
          }
        });
    } else {
      setPasswordError("errorInput");
      setError({
        confirmPassword: t("Password does not match"),
      });
    }
  };
  // if(isLoading){
  //     return(
  //         <Loader />
  //     )
  // }

  if (success) {
    return (
      <main className="ChangePasswordMain">
        <div className="wrap tac">
          <h3>{t("Your password has been changed")}</h3>
          <p>{t("You will be redirected")}</p>
        </div>
      </main>
    );
  }
  return (
    <>
      <main className="ChangePasswordMain">
        <div className="wrap tac">
          <h1>{t("Change your password")}</h1>
          <p className="mb30">
            ⚠️ {t("You can change your password only once a day")} ⚠️
          </p>
          <form onSubmit={handleChangePassword}>
            <div className="formWrap">
              <div>
                <input
                  className={passwordError}
                  id="password"
                  type="password"
                  placeholder={t("Password")}
                  required
                  value={data.password}
                  onChange={(e) => data.setPassword(e.target.value)}
                />
              </div>
              <div>
                <input
                  id="confirmPassword"
                  type="password"
                  placeholder={t("Confirm password")}
                  required
                  value={data.confirmPassword}
                  onChange={(e) => data.setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="tac mt30">
              <button type="submit" className="ctaPrimaryBlack">
                <span>{t("Change Password")}</span>
              </button>
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default ChangePassword;
