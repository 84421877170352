import { useState, useContext } from "react";
import NavBar from "./components/navBar.js";
import Menu from "./components/MenuFeed.js";
import NavDashboard from "./components/NavDashboard.js";
import HomePage from "./views/HomePage.js";
import RegisterPage from "./views/RegisterPage.js";
import Profile from "./views/Profile.js";
import Dashboard from "./views/Dashboard.js";
import Security from "./views/Security";
import NotFound from "./views/NotFound.js";
import PrivacyPage from "./views/PrivacyPage.js";
import LegalsPage from "./views/LegalsPage.js";
import Login from "./views/LoginPage.js";
import Subscription from "./views/Subscription.js";
import Rapport from "./views/Rapport.js";
import Feed from "./views/Feed.js";
import NewMention from "./views/newMention.js";
import Leads from "./views/Leads.js";
import Credits from "./views/Credits.js";
import Referal from "./views/Referal.js";
import About from "./views/About.js";
import Affiliate from "./views/Affiliate.js";
import AccountValidation from "./views/AccountValidation.js";
import Loader from "./components/Loader.js";
import ChangePassword from "./views/ChangePassword.js";
import JoinTeam from "./views/JoinTeam.js";
//import Cursor from "./components/Cursor.js"

import { AuthContext } from "./context/AuthContext.js";
import AuthProvider from "./context/AuthContext.js";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";

// FREE TOOLS
import InstagramCaptionTool from "./views/freetools/instagram-caption-generator.js";
import PressReleaseGenerator from "./views/freetools/press-release-generator.js";

// EXTENSION
import ExtensionLinkedIn from "./views/extension/extensionLinkedIn.js";

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";

import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import posthog from "posthog-js";

import "./App.css";
import "./i18n";

ReactGA.initialize("G-KNVY6HN96Z");
mixpanel.init("1731048e1a6132166db1c225085f4c4a");
// ReactGA.pageview(window.location.pathname + window.location.search);

posthog.init("phc_UHHJixIIsTBalYsRJreeSkLzURPnrEYuPofi7Yva5Qr", {
  api_host: "https://eu.i.posthog.com",
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

// PRIVATE USERS ROUTES
const PrivateRoute = ({ children, isAuthenticate }) => {
  const context = useContext(AuthContext);
  return context.isAuthenticate ? children : <Navigate to="/" />;
};

function AuthenticatedRoute({ element }) {
  const context = useContext(AuthContext);

  if (context.isLoading) {
    return <Loader />;
  }

  if (!context.isAuthenticate) {
    return <Navigate to="/login" />;
  }

  return element;
}

function AppRoutes() {
  const context = useContext(AuthContext);

  // Redirection conditionnelle pour les utilisateurs authentifiés
  // if (context.isAuthenticate) {
  //   return <Navigate to="/dashboard" />;
  // }

  return (
    <>
      <NavBar updateKeyword={context.setKeyword} />
      {/* <Menu /> */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/account-validation/:activationToken"
          element={<AccountValidation />}
        />
        <Route path="/join-team/:invitationToken" element={<JoinTeam />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route
          path="/rapport"
          element={<AuthenticatedRoute element={<Rapport />} />}
        />
        <Route
          path="/feed"
          element={<AuthenticatedRoute element={<Feed />} />}
        />
        <Route
          path="/new-mentions"
          element={<AuthenticatedRoute element={<NewMention />} />}
        />
        <Route
          path="/leads"
          element={<AuthenticatedRoute element={<Leads />} />}
        />
        <Route
          path="/credits"
          element={<AuthenticatedRoute element={<Credits />} />}
        />
        <Route
          path="/profile"
          element={<AuthenticatedRoute element={<Profile />} />}
        />
        <Route
          path="/dashboard"
          element={<AuthenticatedRoute element={<Dashboard />} />}
        />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/affiliate" element={<Affiliate />} />
        <Route path="/legals" element={<LegalsPage />} />
        <Route path="*" element={<NotFound />} />
        {/* Free Tools */}
        <Route
          path="/freetools/instagram-caption-generator"
          element={<InstagramCaptionTool />}
        />
        <Route
          path="/freetools/press-release-generator"
          element={<PressReleaseGenerator />}
        />
        {/* EXTENSION */}
        <Route path="/extension/linkedin" element={<ExtensionLinkedIn />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <NavBar />
        <ToastContainer />
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
} // END APP

export default App;
