import { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { AuthContext } from "../context/AuthContext.js";
import { useRef, useState, useEffect } from "react";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n";

import Loader from "./Loader.js";

// STYLES
import "./NavDashboard.css";

const NavDashboard = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const { teamDetails, setTeamId } = useContext(AuthContext);

  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(true);

  const disconnectUser = () => {
    context.disconnect();
  };

  function handleTeamClick() {
    setShowDropdown(!showDropdown);
  }

  const handleTeamChange = (teamId) => {
    const team = teams.find((t) => t._id === teamId);
    setSelectedTeam(team);
    setTeamDetails(team);
    setShowDropdown(false);
    localStorage.setItem("selectedTeamId", team._id);
    setTeamId(team._id);
  };

  // useEffect(() => {
  //   const storedTeamId = localStorage.getItem("selectedTeamId");
  //   if (storedTeamId && teams.length > 0) {
  //     const team = teams.find((t) => t._id === storedTeamId);
  //     setSelectedTeam(team);
  //   }
  // }, [teams]);

  // useEffect(() => {
  //   async function fetchTeams() {
  //     setLoading(true);
  //     const userId = context.userInfos.id;
  //     try {
  //       const response = await fetch(
  //         process.env.REACT_APP_BASEURL + `/api/teams/user/${userId}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           method: "GET",
  //         }
  //       );

  //       const data = await response.json();

  //       if (data.success) {
  //         setTeams(data.teams);

  //         const storedTeamId = localStorage.getItem("selectedTeamId");
  //         if (storedTeamId) {
  //           const team = data.teams.find((t) => t._id === storedTeamId);
  //           setSelectedTeam(team);
  //         }
  //       } else {
  //         console.error("Error fetching teams:", data.msg);
  //       }
  //     } catch (error) {
  //       console.error("Network error:", error);
  //     }
  //     setLoading(false);
  //     console.log("ma teama c'est s :", teams);
  //   }

  //   fetchTeams();
  // }, []);

  return (
    <>
      <nav id="userProfileNav">
        <Link to="/" className="logo">
          <img
            width="110"
            className="logoimg"
            src="/logo.svg"
            alt="Buska Logo"
          />
        </Link>

        <div className="teamData" onClick={handleTeamClick}>
          <div className="teamImage">
            <img src={teamDetails ? teamDetails.image : "/testpic.jpg"} />
          </div>
          <div className="teamInfo">
            <h4>{teamDetails ? teamDetails.name : "Select a team"}</h4>
            <p>
              {teamDetails ? `${teamDetails.members.length} member(s)` : ""}
            </p>
          </div>
          <div className="teamArrow">
            <span>
              <i className="fa-solid fa-caret-down"></i>
            </span>
          </div>
        </div>

        {showDropdown && (
          <div className="customDropdown">
            {teams.map((team) => (
              <div
                key={team._id}
                onClick={() => handleTeamChange(team._id)}
                className="dropdownItem"
              >
                {team.name}
              </div>
            ))}
          </div>
        )}

        <ul>
          <li
            className={location.pathname === "/dashboard" ? "active-link" : ""}
          >
            <Link to="/dashboard">
              <i className="fas fa-gauge"></i>
              <span>{t("Dashboard")}</span>
            </Link>
          </li>
          <li className={location.pathname === "/feed" ? "active-link" : ""}>
            <Link to="/feed">
              <i className="fas fa-bell"></i>
              <span>{"Feed"}</span>
            </Link>
          </li>
          {/* <li className={location.pathname === "/rapport" ? "active-link" : ""}>
            <Link to="/rapport">
              <i className="fas fa-file-export"></i>
              <span>{"Rapport"}</span>
            </Link>
          </li> */}
          {/* <li
            className={location.pathname === "/profile" ? "active-link" : ""}
            ref={ref2}
          >
            <Link to="/profile">
              <i className="fas fa-user"></i>
              <span>{t("Profile")}</span>
            </Link>
          </li> */}
          {/* <li
            className={
              location.pathname === "/subscription" ? "active-link" : ""
            }
          >
            <Link to="/subscription">
              <i className="fa-solid fa-credit-card"></i>
              <span>{t("Subscription")}</span>
              <span className="newPastille">{t("NEW")}</span>
            </Link>
          </li> */}
          <li>
            <a onClick={disconnectUser}>
              <i className="fas fa-power-off"></i> <span>Logout</span>
            </a>
          </li>
        </ul>
        <div className="footerLeft">
          <h4>Need help?</h4>
          <p>
            Send us an email anytime, with questions, comments, or feedback.
          </p>
          <p>
            <i className="fa-regular fa-envelope"></i>{" "}
            <a target="_blank" href="mailto:hello@buska.io">
              hello@buska.io
            </a>
          </p>
        </div>
        <div className="copywriteFooter">
          <p>v 1.0.0 - Buska.io</p>
        </div>
      </nav>
    </>
  );
};
export default NavDashboard;
