import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import LangModal from "../components/langModal.js";
import LanguageSelector from "./LanguageSelector.js";
import CookieModal from "./CookieModal.js";
import { hotjar } from "react-hotjar";

import { Crisp } from "crisp-sdk-web";

import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css";

import "./footer.js";

const FooterLogin = () => {
  useEffect(() => {
    Crisp.configure("3ca68a15-69f7-4415-a48b-f8cdf93a38ce");
  }, []);

  const { t, i18n } = useTranslation();
  const [isOpenLang, setIsOpenLang] = useState();

  return (
    <>
      <footer id="footerLogin">
        <div className="subFooterLogin tac">
          <div>
            <img style={{ margin: "50px" }} src="/logo-grey.svg" />
            {/* <img src="/icon_footer_masc.svg" /> */}
          </div>
        </div>
      </footer>
      <div className="feedbackLogin">
        {/* <Feedback
          projectId="6530e023da2e9400028e27d6"
          email={true}
          emailRequired={true}
        /> */}
      </div>
    </>
  );
};
export default FooterLogin;
