import { useState, useEffect, useContext } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { AuthContext } from "../context/AuthContext.js";

import disposableDomains from "disposable-email-domains";

import mixpanel from "mixpanel-browser";

import Loader from "../components/Loader.js";
import Checkbox from "../components/Checkbox.js";
import Footer from "../components/footer.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GoogleConnect from "../components/GoogleConnect.js";

import "./Login.css";
import "../i18n";

function RegisterPage() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const context = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  const { t, i18n } = useTranslation();

  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [referralError, setReferralError] = useState();
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedOption, setSelectedOption] = useState("1");
  const [referral, setReferral] = useState(
    new URLSearchParams(search).get("referral") || ""
  );

  const invitationToken = new URLSearchParams(search).get("invitationToken");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utmSource = searchParams.get("utm_source") || "";
  const utmMedium = searchParams.get("utm_medium") || "";
  const utmCampaign = searchParams.get("utm_campaign") || "";
  const utmTerm = searchParams.get("utm_term") || "";
  const utmContent = searchParams.get("utm_content") || "";

  const [error, setError] = useState({});

  //const [users, setUsers] = useState();

  const data = {
    email,
    setEmail,
    password,
    setPassword,
    referral,
    setReferral,
    error,
  };

  const isEmailTemporary = (email) => {
    const emailDomain = email.split("@")[1];
    return disposableDomains.includes(emailDomain);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Convertir l'email en minuscules
    const lowercaseEmail = email.toLowerCase();

    if (isEmailTemporary(lowercaseEmail)) {
      setEmailError("errorInput");
      setError({
        confirmPassword: t("Temporary_emails_are_not_allowed"),
      });
      toast.error(t("Temporary_emails_are_not_allowed"));
      return;
    }

    const language = navigator.language || navigator.userLanguage;

    fetch(process.env.REACT_APP_BASEURL + "/api/user/register", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: lowercaseEmail,
        password,
        referral,
        language,
        invitationToken,
      }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        setPasswordError("");
        setEmailError("");
        setReferralError("");
        if (res.success) {
          if (res.isConfirmed) {
            if (window.Reflio) {
              await window.Reflio.signup(lowercaseEmail);
            }
            // console.log("res", res);
            // Log the user in immediately if they are confirmed.
            localStorage.setItem("Token", res.token);
            // Vérifiez si invitationToken existe avant de le stocker
            if (invitationToken) {
              localStorage.setItem("InvitationToken", invitationToken);
            }
            context.setUserInfos({
              email: res.email,
            });
            context.setIsAuthenticate(true);
            context.setToken(res.token);
            context.setTeamId(res.teamId);
            navigate("/leads");

            // Envoyer les paramètres UTM à Mixpanel
            mixpanel.track("Register", {
              user: lowercaseEmail,
              utm_source: utmSource,
              utm_medium: utmMedium,
              utm_campaign: utmCampaign,
              utm_term: utmTerm,
              utm_content: utmContent,
            });
          } else {
            setSuccess(true);
          }
        } else {
          if (res.msg == "Referral Code incorrect") {
            setReferralError("errorInput");
            setError({
              confirmPassword: t("Referral_Code_incorrect"),
            });
          } else {
            setEmailError("errorInput");
            setError({
              confirmPassword: res.msg,
            });
          }
        }
      });
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const goToHome = () => {
    navigate("/");
  };

  if (success) {
    toast.success(t("Click_on_the_link_we_sent"));
    navigate("/");
  }

  // if(isLoading) {
  //   return (
  //     <Loader />
  //   )
  // }

  if (context.isAuthenticate) {
    navigate("/");
    return false;
  }

  function TallyRegister() {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://tally.so/widgets/embed.js";
      script.onload = () => {
        if (typeof Tally !== "undefined") {
          Tally.loadEmbeds();
        } else {
          document
            .querySelectorAll("iframe[data-tally-src]:not([src])")
            .forEach((e) => {
              e.src = e.dataset.tallySrc;
            });
        }
      };

      script.onerror = script.onload;
      document.body.appendChild(script);

      return () => {
        // Cleanup script on unmount
        document.body.removeChild(script);
      };
    }, []);

    return (
      <>
        <iframe
          data-tally-src="https://tally.so/embed/mZEJEo?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
          loading="lazy"
          width="100%"
          height="1"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Get early access (Social)"
        ></iframe>
        <div id="overlay"></div>
      </>
    );
  }

  // return (
  //   <>
  //     <div className="loginContainer">
  //       <div className="leftSide">
  //         <img className="avisLogin" src="/avis1.png" />
  //       </div>
  //       <div className="rightSide">
  //         <main className="BasicMain">
  //           <div className="wrap smallWrap">
  //             <h2>{t("register_now")}</h2>
  //             {TallyRegister()}
  //             <br />
  //           </div>
  //         </main>
  //       </div>
  //     </div>
  //   </>
  // );

  // Pour la waitlist
  // return (
  //   <>
  //     <div className="fullContainer">
  //       <div className="card">
  //         <div className="loginContainer">
  //           <div className="rightSide">
  //             <main className="">
  //               <div className="loginWrap loginPage">
  //                 <img className="logoOverlay" src="Rating.svg" />
  //                 <img className="logoLogin" src="/logo.svg" />
  //                 <p>{t("never_miss_any_mention_of_your_brand_again")}</p>
  //                 {TallyRegister()}
  //               </div>
  //             </main>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  // Lors de la mise en prod
  return (
    <>
      <div className="fullContainer">
        <div className="card">
          <div className="loginContainer">
            <div className="rightSide">
              <main className="">
                <div className="loginWrap loginPage">
                  <img className="logoOverlay" src="Rating.svg" />
                  <a onClick={goToHome}>
                    <img className="logoLogin" src="/logo.svg" />
                  </a>
                  <p>{t("never_miss_any_mention_of_your_brand_again")}</p>
                  <GoogleConnect
                    className="connectGoogleButton"
                    buttonText={t("Continue_with_Google")}
                    label="Continue with Google"
                    methodeType="login"
                  />
                  <br />
                  <br />
                  <span className="lineLogin">or</span>
                  <br />
                  <br />
                  <form onSubmit={handleRegister}>
                    <div className={`form-group`}>
                      <input
                        className={emailError}
                        id="email"
                        type="email"
                        placeholder={t("Enter_your_email")}
                        required
                        value={data.email}
                        onChange={(e) => data.setEmail(e.target.value)}
                      />
                    </div>
                    <div className={`form-group`}>
                      <input
                        placeholder={t("Enter_your_password")}
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className={`form-group`}>
                      <input
                        className={referralError}
                        id="referral"
                        type="text"
                        placeholder={t("Enter_your_referral_code")}
                        value={data.referral}
                        onChange={(e) => data.setReferral(e.target.value)}
                      />
                    </div>
                    <p className="errorMsg">
                      {data.error && data.error.confirmPassword}
                    </p>
                    {/* {loginError && <div className="error-message">{loginError}</div>} */}
                    <br />
                    <button
                      type="submit"
                      className="ctaPrimaryBlack"
                      style={{ width: "100%", height: "40px" }}
                    >
                      {t("register_now")}
                    </button>

                    <br />
                    <button
                      style={{ color: "#000", width: "100%", height: "40px" }}
                      className="ctaPrimaryHome"
                      onClick={goToLogin}
                    >
                      {t("Already_got_an_account")}
                    </button>
                    <br />
                    <br />
                  </form>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // return (
  //   <>
  //     <div className="loginContainer">
  //       <div className="leftSide">
  //         <img className="avisLogin" src="/avis1.png" />
  //       </div>
  //       <div className="rightSide">
  //         <main className="BasicMain">
  //           <div className="wrap smallWrap">
  //             <h2>{t("register_now")}</h2>
  //             <form onSubmit={handleRegister}>
  //               <div className={`form-group`}>
  //                 <input
  //                   className={emailError}
  //                   id="email"
  //                   type="email"
  //                   placeholder={t("Enter_your_email")}
  //                   required
  //                   value={data.email}
  //                   onChange={(e) => data.setEmail(e.target.value)}
  //                 />
  //               </div>
  //               <div className={`form-group`}>
  //                 <input
  //                   placeholder={t("Enter_your_password")}
  //                   type="password"
  //                   id="password"
  //                   name="password"
  //                   value={password}
  //                   onChange={(e) => setPassword(e.target.value)}
  //                 />
  //               </div>
  //               <div className={`form-group`}>
  //                 <input
  //                   className={referralError}
  //                   id="referral"
  //                   type="text"
  //                   placeholder={t("Enter_your_referral_code")}
  //                   value={data.referral}
  //                   onChange={(e) => data.setReferral(e.target.value)}
  //                 />
  //               </div>
  //               <p className="errorMsg">
  //                 {data.error && data.error.confirmPassword}
  //               </p>
  //               {/* {loginError && <div className="error-message">{loginError}</div>} */}
  //               <a href="/login" className="register-link">
  //                 {t("Already_got_an_account")}
  //               </a>
  //               <br />
  //               <button type="submit" className="smallCta">
  //                 {t("register_now")}
  //               </button>
  //               <br />
  //               <br />
  //             </form>
  //             <br />
  //             <span className="lineLogin">OR</span>
  //             <br />
  //             <br />
  //             <br />
  //             <GoogleConnect
  //               className="smallCta"
  //               buttonText={t("Continue_with_Google")}
  //               label={t("Continue_with_Google")}
  //               methodeType="login"
  //             />
  //           </div>
  //         </main>
  //       </div>
  //     </div>
  //   </>
  // );
}

export default RegisterPage;
