import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../i18n";

import Loader from "../components/Loader.js";

import "./JoinTeam.css";

function JoinTeam() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { invitationToken } = useParams();

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASEURL + `/api/teams/join-team/${invitationToken}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // Si le token est valide, redirigez l'utilisateur vers la page d'inscription avec le token d'invitation comme paramètre
          navigate(`/register?invitationToken=${invitationToken}`);
        } else {
          alert(t("error_invitation_link"));
        }
      });
  }, [navigate, invitationToken, t]);

  return <Loader />;
}

export default JoinTeam;
