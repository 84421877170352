import React, { useState, useContext } from "react"; // Assurez-vous d'importer tous les hooks et composants nécessaires

import { AuthContext } from "../../context/AuthContext.js";

import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from "antd";

import mixpanel from "mixpanel-browser";

import "react-toastify/dist/ReactToastify.css";
import "./steps.css";

import { useTranslation } from "react-i18next";

const Step0 = ({ isVisible, onClose, onNext }) => {
  const { t, i18n } = useTranslation();

  const [keywords, setKeywords] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [languagekeyword, setLanguagekeyword] = useState("");
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [error, setError] = useState({});

  const context = useContext(AuthContext);

  const handleNextClick = async () => {
    mixpanel.track("Onboarding", {
      Step: "0",
    });

    onNext();
  };

  return (
    <Modal
      className="customModal"
      open={isVisible}
      maskStyle={{
        background:
          "linear-gradient(130deg, #FFD788 28.79%, #FFBFBF 64.77%, #DDA5FF 100%)",
      }}
      footer={false}
      maskClosable={false}
      closable={false}
    >
      <div className="leftPart">
        <h2>{t("Welcome_to_buska")}</h2>
        <p>
          {t("did_you_know_that_96_of_online_conversations")}
          <br />
          <br />
          {t("you_might_be_missing_hundreds_of_business")}
          <br />
          {t("buska_finds_every_conversation_online_that_is_relevant")}
          <br />
          <br />
          {t("and_gives_you_the_opportunity_to_engage_with_people")}
          <br />
          <br />
          <br />
        </p>

        <div style={{ textAlign: "left", paddingBottom: "60px" }}>
          <Button
            key="submit"
            className="CtaOnboardingv2"
            onClick={handleNextClick}
          >
            {t("Continue")} <i className="fa-solid fa-arrow-right"></i>
          </Button>
        </div>
        <div className="footerButtonSteps"></div>
      </div>
      <div className="rightPart2">
        {/* <p>
          {t("Nearly")}{" "}
          <span className="highlightedOnboard"> {t("90_of_consumers")}</span>{" "}
          {t("read_online_reviews_before_visite")}
        </p> */}
        <br />
        <p>
          <strong>1. {t("Choose_a_keyword")}</strong>
          <br />
          <span>{t("your_brand_your_service_your_competition")}</span>
        </p>
        <br />
        <p>
          <strong>2. {t("Anytime_someone_talks_online")}</strong>{" "}
          {t("about_your_keyword_we_will_find_them_for_you")}
          <br />
        </p>
        <br />
        <p>
          <strong>2. {t("Engage_with_them")}</strong>
          <br />
          <span>{t("and_turn_mentions_into_happy_customers")}</span>
        </p>
        <br />
        <img src="/buskhino.svg" />
        {/* <h3>{t("use_buska_to_detect_reviews")}</h3> */}
      </div>
    </Modal>
  );
};

export default Step0;
