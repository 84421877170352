import React from "react";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { AuthContext } from "../context/AuthContext.js";

import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import mixpanel from "mixpanel-browser";

import "./GoogleConnect.css";

const lang = navigator.language || navigator.userLanguage;
const clientId =
  "561857664117-0aicckdd2mdtklchfj33bj6gtnumdro4.apps.googleusercontent.com";

let chars =
  "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
let passwordLength = 21;
let randomPass = "";
for (let i = 0; i <= passwordLength; i++) {
  let randomNumber = Math.floor(Math.random() * chars.length);
  randomPass += chars.substring(randomNumber, randomNumber + 1);
}

const GoogleConnect = (props) => {
  const [error, setError] = useState("Error");
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const { buttonText, isExtension, onSuccess } = props;

  console.log("isExtension", isExtension);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "email",
        plugin_name: "chat",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const handleSuccess = async (response) => {
    const urlParams = new URLSearchParams(window.location.search);
    const referral = urlParams.get("ref");

    fetch(process.env.REACT_APP_BASEURL + "/api/user/registergoogle", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: response.profileObj.email,
        firstname: response.profileObj.givenName,
        surname: response.profileObj.familyName,
        password: randomPass,
        language: lang,
        image: response.profileObj.imageUrl,
        referral: referral,
      }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.success) {
          context.setUserInfos({
            email: res.email,
            userId: res.userId,
            teamId: res.teamId,
          });
          context.setIsAuthenticate(true);
          context.setToken(res.token);
          context.setTeamId(res.teamId);
          mixpanel.track("Login");

          // console.log("res.userId", res.userId)

          if (window.Reflio) {
            await window.Reflio.signup(response.profileObj.email);
          }

          toast.success(t("Welcome_to_buska"), {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000, // Durée d'affichage en ms
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if (isExtension) {
            // Logique spécifique pour l'extension
            window.parent.postMessage(
              {
                action: "getLinkedInCookies",
                token: res.token,
                userId: res.userId,
              },
              "*"
            );

            // Envoyer un message au popup de l'extension Chrome avec le token
            window.parent.postMessage(
              {
                action: "saveToken",
                token: res.token,
                userId: res.userId,
              },
              "*"
            );

            if (onSuccess) {
              onSuccess(res);
            }
          } else {
            navigate("/leads");
            setTimeout(() => {
              window.location.reload();
            }, 300);
          }
        } else {
          setError({
            confirmPassword: res.msg,
          });
        }
      });
  };

  const onFailure = (response) => {
    console.log("FAILED");
  };

  return (
    <div className="googleConnectButton connectGoogleButton ctaGoogle">
      <GoogleLogin
        clientId={clientId}
        methodeType={props.label.methodeType}
        // buttonText={t("Connexion_avec_Google")}
        buttonText={buttonText}
        onSuccess={handleSuccess}
        onFailure={onFailure}
      />
    </div>
  );
};

export default GoogleConnect;
