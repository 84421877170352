import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import Footer from "../components/footer.js";

import "./BasicPage.css";
import "../i18n";

function LegalsPage() {
  useEffect(() => {
    //start to scrolltop 0
    window.scrollTo(0, 0);
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="globalContainer">
        <main className="BasicMain">
          <div className="wrap smallWrap">
            <h1>{t("Legals")}</h1>

            <div className="content" style={{ textAlign: "left" }}>
              <div className="textPart mb30">
                <p>
                  All the information on this website -{" "}
                  <a href="https://www.buska.io">www.buska.io</a> - is published
                  in good faith and for general information purpose only.
                  www.buska.io does not make any warranties about the
                  completeness, reliability and accuracy of this information.
                  Any action you take upon the information you find on this
                  website (www.buska.io), is strictly at your own risk.
                  www.buska.io will not be liable for any losses and/or damages
                  in connection with the use of our website.
                </p>
              </div>

              <div className="textPart mb30">
                <h4>The company</h4>
                <p>
                  The company Fibroweb is a simplified joint stock company with
                  a capital of 1 euro, is in the process of being registered at
                  the R.C.S. of Grasse, the e-mail address is hello(at)buska.io.
                </p>
                <h4 className="mt20">Terms of use</h4>
                <p>
                  From our website, you can visit other websites by following
                  hyperlinks to such external sites. While we strive to provide
                  only quality links to useful and ethical websites, we have no
                  control over the content and nature of these sites. These
                  links to other websites do not imply a recommendation for all
                  the content found on these sites. Site owners and content may
                  change without notice and may occur before we have the
                  opportunity to remove a link which may have gone 'bad'. Please
                  be also aware that when you leave our website, other sites may
                  have different privacy policies and terms which are beyond our
                  control. Please be sure to check the Privacy Policies of these
                  sites as well as their "Terms of Service" before engaging in
                  any business or uploading any information.
                </p>
                <h4 className="mt20">Hosting company</h4>
                <ul>
                  <li>SAS with a capital of 10 174 560 €.</li>
                  <li>RCS Lille Métropole 424 761 419 00045</li>
                  <li>Code APE 2620Z</li>
                  <li>VAT NUMBER : FR 22 424 761 419</li>
                  <li>
                    Head office: 2 rue Kellermann - 59100 Roubaix - France
                  </li>
                  <li>Chairman: Michel Paulin</li>
                  <li>
                    OVH SAS is a subsidiary of OVH Groupe SA, a company
                    registered in the Lille Trade and Companies Register under
                    number 537 407 926 and located at 2, rue Kellermann, 59100
                    Roubaix.
                  </li>
                </ul>
                <h4 className="mt20">Consent</h4>
                <p>
                  By using our website, you hereby consent to our disclaimer and
                  agree to its terms.
                </p>
                <h4 className="mt20">Update</h4>
                <p>
                  Should we update, amend or make any changes to this document,
                  those changes will be prominently posted here.
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default LegalsPage;
