import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import Footer from "../components/footer.js";

import "./BasicPage.css";
import "../i18n";

function About() {
  useEffect(() => {
    //start to scrolltop 0
    window.scrollTo(0, 0);
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="globalContainer">
        <main className="BasicMain">
          <div className="wrap smallWrap">
            <h1>{t("About_us")}</h1>

            <div className="content" style={{ textAlign: "left" }}>
              <div className="textPart mb30">
                <h3 className="" style={{ textAlign: "left" }}>
                  {t("Founders_Story")}
                </h3>
                <p>{t("we_used_to_work_together")}</p>
                <br />
                <p>{t("working_remotly_we_got_the_chance")}</p>
                <br />
                <p>{t("we_almost_instantly_clicked")}</p>
                <br />
                <p>{t("so_by_the_pool_we_decided_to_brainstorm")}</p>
                <br />
                <p>{t("when_threads_was_announced_we_decided_to_build")}</p>
                <br />
                <p>{t("Unfortunately_meta_didnt_open_the_new_app_europe")}</p>
                <br />
                <p>{t("it_was_a_failure_but_allowed_us_to_ensure_fit")}</p>
                <br />
                <p>{t("the_next_venture_project_came_from_frustrations")}</p>
                <br />
                <p>{t("when_Tristan_launched_his_previous_app")}</p>
                <br />
                <p>{t("and_as_a_product_lead_in_a_bank")}</p>
                <br />
                <p>{t("thats_how_we_decided_to_build_buska")}</p>
                <br />
                <p>{t("but_as_a_side_project_it_was_difficult")} </p>
                <br />
                <p>{t("Luckily_it_was_in_the_summer_hollidays")}</p>
                <br />
                <p>{t("tristan_had_week_in_canada")} </p>
                <br />
                <p>{t("he_took_that_chance_to_coding_a_working_prototype")}</p>
                <br />
                <p>{t("Toni_used_his_vacation_to_do_market")}</p>
                <br />
                <p>{t("we_decided_to_launch_on_ph_asap")} </p>
                <br />
                <p>{t("we_engaged_intensely_with_makers")} </p>
                <br />
                <p>{t("we_launched_on_october")}</p>
                <br />
                <p>{t("it_really_helped_us_focus_and_improve_buska")} </p>
              </div>
              <div className="textPart">
                <h3 className="" style={{ textAlign: "left" }}>
                  {t("The_name")}
                </h3>
                <p>{t("we_brainstormed_many_ideas")}</p>
                <br />
                <p>
                  - {t("short")}
                  <br />- {t("means_something")}
                  <br />- {t("not_a_mere_description")}
                  <br />- {t("unique")}
                  <br />- {t("accessible_in_many_languages")}
                </p>
                <br />
                <p>{t("i_am_from_portugal_and_Tristan_lives")}</p>
                <br />
                <p>
                  {t("so_we_came_up_with_buska_name")}
                  <br />
                  {t("we_added_the_k_to_buska")}
                  <br />
                  {t("thats_how")}{" "}
                  <a className="bold" href="www.buska.io">
                    buska.io
                  </a>{" "}
                  {t("was_born")}
                </p>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default About;
