import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import LangModal from "../components/langModal.js";
import LanguageSelector from "./LanguageSelector.js";
import CookieModal from "./CookieModal.js";
import { hotjar } from "react-hotjar";

import "./footer.css";

const Footer = () => {
  hotjar.initialize(3577767, 6);

  const { t, i18n } = useTranslation();
  const [isOpenLang, setIsOpenLang] = useState();

  const toggleModalLang = () => {
    setIsOpenLang(false);
    localStorage.setItem("isDefinedLang", true);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("isDefinedLang", true);
  };

  useEffect(() => {
    // if token doesnt exist, open modal
    if (!localStorage.getItem("isDefinedLang")) {
      setIsOpenLang(true);
    }
    // if token exist, close modal
    else {
      setIsOpenLang(false);
    }
  }, []);

  return (
    <>
      {/* <LangModal isOpen={isOpenLang} hide={toggleModalLang}/> */}
      <CookieModal />
      <footer id="globalFooter">
        <div className="wrap tac">
          <div className="mb30">
            <a href="/">
              <img
                width="180"
                className="footerLogo"
                src="/buska_white.png"
                alt="Buska logo"
              />
            </a>
            <div className="alignHorizon">
              <h3 className="h3footer">{t("Monitor")}</h3>
              <h3 className="h3footer">{t("Understand")}</h3>
              <h3 className="h3footer">{t("Engage")}</h3>
            </div>
            {/* <span className="h3footer">Write. Analyze. Start again</span> */}
            <p className="prefooter">
              {t("we_help_small_businesses_and_artists_to_improve")}
              <br />
              {t("Dont_miss_any_mention_of_your_brand")}
            </p>
            <ul className="mb30">
              <li>
                {/* <a href="#"><i className="fa-brands fa-twitter fa-2x"></i></a>
                  <a href="#"><i className="fa-brands fa-linkedin ml10 fa-2x"></i></a> */}
                <a onClick={() => changeLanguage("en-EN")}>
                  <img
                    alt="flag image"
                    className="flagRadius"
                    src="/flags/United_states.svg"
                  />
                </a>
                <a onClick={() => changeLanguage("fr-FR")}>
                  <img
                    alt="flag image"
                    className="flagRadius"
                    src="/flags/icon_France_.svg"
                  />
                </a>
                <a onClick={() => changeLanguage("es-ES")}>
                  <img
                    alt="flag image"
                    className="flagRadius"
                    src="/flags/Spain.svg"
                  />
                </a>
                <a onClick={() => changeLanguage("pt-PT")}>
                  <img
                    alt="flag image"
                    className="flagRadius"
                    src="/flags/Portugal.svg"
                  />
                </a>
              </li>
            </ul>
          </div>
          <ul>
            {/* <li>
              <img width="110" src="/logo.svg" alt="Buska logo" />
            </li> */}
            <li>
              <Link className="ReallysmallText" to="/legals">
                {t("Legals")}
              </Link>
            </li>
            <li>
              <Link className="ReallysmallText" to="/privacy">
                {t("Privacy")}
              </Link>
            </li>
            <li>
              <a
                className="ReallysmallText"
                href="https://buska.substack.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Newsletter")}
              </a>
            </li>
            {/* <li>
              <a
                className="ReallysmallText"
                href="mailto:hello@buska.io"
                target="_blank"
              >
                {t("Contact_us")}
              </a>
            </li> */}
            <li>
              <a
                className="ReallysmallText"
                href="https://blog.buska.io"
                target="_blank"
              >
                {t("blog")}
              </a>
            </li>
            <li>
              <Link className="ReallysmallText" to="/about">
                {t("About_us")}
              </Link>
            </li>
            <li>
              <Link className="ReallysmallText" to="/affiliate">
                {t("Affiliate")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="footerPreImg">
          <img src="/Painting.svg" alt="Painting Buska logo" />
        </div>
        <div className="subFooter tac">
          <p>
            {t("Copyright")}{" "}
            <a target="_blank" href="https://www.fibroweb.fr">
              {t("Fibroweb")}
            </a>{" "}
            {t("Copyright_bis")}
            <br />
          </p>
        </div>
      </footer>
    </>
  );
};
export default Footer;
