import { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { AuthContext } from "../context/AuthContext.js";
import { TwitterTimelineEmbed, TwitterVideoEmbed } from "react-twitter-embed";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";
import "moment/locale/fr";

import html2canvas from "html2canvas";
import Plans from "../components/Plans.js";

import TikTokEmbed from "../components/TikTokEmbed.js";

import ExtensionLinkedIn from "./extension/extensionLinkedIn.js";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import {
  BarChart,
  LabelList,
  Bar,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Sector,
  Legend,
  AreaChart,
  Label,
  Area,
  ResponsiveContainer,
} from "recharts";
import { Select, Button, Drawer } from "antd";

import { Modal } from "antd";

import Step0 from "./onboarding/Step0.js";
import Step1 from "./onboarding/Step1.js";
import Step1choose from "./onboarding/Step1choose.js";
import Step2 from "./onboarding/Step2.js";
import Step3 from "./onboarding/Step3.js";

import AlertModal from "../components/AlertModal.js";

import mixpanel from "mixpanel-browser";

import Footer from "../components/footer.js";
import FooterLogin from "../components/footerLogin.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";
import MenuDashboard from "../components/MenuDashboard.js";

// STYLES
import "./Dashboard.css";

function Dashboard(props) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const location = useLocation();

  const { pathname } = location;
  const { isFreeTrialOver, isFreeTrial } = useContext(AuthContext);

  moment.locale("fr"); // Utiliser la locale française pour l'affichage des dates

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [surname, setSurname] = useState("");
  const [newsLetter, setNewsLetter] = useState(false);
  const [selectedOption, setSelectedOption] = useState("1");
  const [error, setError] = useState({});
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [DataLoading, setDataLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState("");
  const [languagekeyword, setLanguagekeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataFromSearch, setDataFromSearch] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [isModalAlertVisible, setModalAlertVisible] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);
  const [totalMentions, setTotalMentions] = useState(0);
  const [percentageGrowth, setPercentageGrowth] = useState(0);
  const [duration, setDuration] = useState(moment().subtract(2, "years"));
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedMention, setSelectedMention] = useState(null);
  const [bgColorClass, setBgColorClass] = useState("");
  const [nombreResults, setNombreResults] = useState(0);
  const [open, setOpen] = useState(false);
  const [dataToUse, setDataToUse] = useState([]);
  const [sentimentData, setSentimentData] = useState(null);
  const [isPremiumTeam, setIsPremiumTeam] = useState(false);
  const [premiumTeamCondition, setPremiumTeamCondition] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [mentionCountsNumber, setMentionCountsNumber] = useState({
    good: 0,
    neutrale: 0,
    bad: 0,
  });
  const [isTrialOverModalVisible, setIsTrialOverModalVisible] = useState(false);

  const dataMentionSentiment = [
    { name: "Bad", value: 300, fillColor: "#EED3FF" },
    { name: "Neutrale", value: 300, fillColor: "#C99FFF" },
    { name: "Good", value: 300, fillColor: "#9E00FF" },
  ];

  // const dataMentionSentiment = [
  //   { name: "Bad", value: mentionCountsNumber.bad, fillColor: "#EED3FF" },
  //   {
  //     name: "Neutrale",
  //     value: mentionCountsNumber.neutrale,
  //     fillColor: "#C99FFF",
  //   },
  //   { name: "Good", value: mentionCountsNumber.good, fillColor: "#9E00FF" },
  // ];

  const COLORS = [
    "#E57373",
    "#81C784",
    "#64B5F6",
    "#FFD54F",
    "#BA68C8",
    "#4DB6AC",
    "#FF8A65",
    "#A1887F",
    "#7986CB",
    "#F06292",
    "#9575CD",
    "#4FC3F7",
    "#AED581",
  ];

  const GRADIENT_COLORS = [
    "linear-gradient(45deg, #b649ff, #b649ff)",
    "linear-gradient(45deg, #64B5F6, #417ABD)",
    "linear-gradient(45deg, #FFD54F, #CBA92D)",
    "linear-gradient(45deg, #BA68C8, #8C4BA2)",
    "linear-gradient(45deg, #4DB6AC, #349683)",
    "linear-gradient(45deg, #FF8A65, #C96644)",
    "linear-gradient(45deg, #A1887F, #775A51)",
    "linear-gradient(45deg, #7986CB, #5560A2)",
    "linear-gradient(45deg, #F06292, #B74772)",
    "linear-gradient(45deg, #9575CD, #6D4BAB)",
    "linear-gradient(45deg, #4FC3F7, #2D91BF)",
    "linear-gradient(45deg, #AED581, #84A95C)",
  ];

  const handleNavigation = () => {
    navigate("/leads", { state: { keyword: context.keyword } });
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, index } = props;
    const source = pieData[index].name;
    const iconPath = `/social/${source}.png`;

    return (
      <foreignObject
        x={x + width / 2 - 7.5}
        y={y + height - 10} // Essayez de décaler de 10px vers le haut pour tester
        width={width}
        height={20}
      >
        <img
          src={iconPath}
          alt={source}
          style={{ width: "15px", textAlign: "center" }}
        />
      </foreignObject>
    );
  };

  const HiddenLegend = () => {
    return null;
  };

  const handleMentionClick = (mentionData) => {
    setSelectedMention(mentionData);
    setDrawerVisible(true);
    setOpen(true);
  };

  const onClose = () => {
    setSelectedMention(null);
    handleMentionClick(false);
    setOpen(false);
  };

  const showModal = (mentionData) => {
    setSelectedMention(mentionData);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (location.search === "?=success") {
      toast.success(t("thank_you_for_your_purchase"));
    }
  }, [location.search, context]);

  const exportModalAsImage = () => {
    const modalElement = document.querySelector(".ant-modal-content");
    const footerElement = modalElement.querySelector(".custom-footer");

    // Cachez temporairement le footer
    footerElement.style.display = "none";

    html2canvas(modalElement).then((canvas) => {
      // Réaffichez le footer
      footerElement.style.display = "flex";

      // Convertissez le canvas en image et téléchargez-le
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "exportBuska.png";
      link.click();
    });
  };

  function aggregateDataByDay(searches) {
    let mentionsByDate = {};

    if (!searches || !Array.isArray(searches)) {
      console.error("Invalid searches data", searches);
      return [];
    }

    searches.forEach((search) => {
      for (const source in search.results) {
        const posts = search.results[source].data?.posts;
        if (posts && Array.isArray(posts)) {
          posts.forEach((post) => {
            const date = moment(post.date).format("YYYY-MM-DD");
            mentionsByDate[date] = (mentionsByDate[date] || 0) + 1;
          });
        }
      }
    });

    const sortedData = Object.entries(mentionsByDate)
      .sort(([date1], [date2]) => moment(date1).diff(moment(date2)))
      .map(([date, count]) => ({ name: date, uv: count }));
    return sortedData;
  }

  useEffect(() => {
    // Si la modal est visible, trouvez l'élément .ant-modal-content et modifiez sa classe
    if (isModalVisible) {
      const modalContentElement = document.querySelector(".ant-modal-content");
      if (modalContentElement) {
        modalContentElement.className = `ant-modal-content ${bgColorClass}`;
      }
    }
  }, [bgColorClass, isModalVisible]);

  const keyword = context.keyword;

  const [modalState, setModalState] = useState({
    isVisible: false,
    step: 0,
  });

  const [affiliateCodeURL, setAffiliateCodeURL] = useState("");
  const [referral, setReferral] = useState([]);
  const data = {
    email,
    setEmail,
    name,
    setName,
    firstname,
    setFirstname,
    surname,
    setSurname,
    newsLetter,
    setNewsLetter,
    selectedOption,
    setSelectedOption,
    affiliateCode,
    setAffiliateCode,
    affiliateCodeURL,
    setAffiliateCodeURL,
    referral,
    setReferral,
    error,
  };
  const [isMobile, setIsMobile] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Fonction pour gérer le clic sur la div
  const handleDivClick = () => {
    setModalAlertVisible(true);
  };

  const handleCloseModal = () => {
    setModalAlertVisible(false);
  };

  const fakeData = [
    { name: "Data", uv: 10 },
    { name: "Data", uv: 20 },
    { name: "Data", uv: 15 },
    { name: "Data", uv: 25 },
    { name: "Data", uv: 30 },
    { name: "Data", uv: 35 },
  ];

  const fakeBarData = [
    { name: "linkedin", value: 25 },
    { name: "reddit", value: 25 },
    { name: "twitter", value: 25 },
    { name: "youtube", value: 25 },
    { name: "instagram", value: 25 },
    { name: "pinterest", value: 25 },
    { name: "medium", value: 25 },
    { name: "google", value: 25 },
    { name: "facebook", value: 25 },
    { name: "googleNews", value: 25 },
    { name: "tiktok", value: 25 },
    { name: "quora", value: 25 },
    { name: "indiehacker", value: 25 },
  ];

  useEffect(() => {
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkWindowSize);
    checkWindowSize();

    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  function highlightKeyword(content, keyword) {
    const regex = new RegExp(`(${keyword})`, "gi");
    return content.replace(regex, '<span class="highlightedKeyword">$1</span>');
  }

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true; // pour suivre si le composant est monté

    const fetchData = async () => {
      const headers = {
        "Content-Type": "application/json",
      };
      if (context.token) {
        headers.Authorization = `Bearer ${context.token}`;
      } else if (localStorage.getItem("Token")) {
        headers.Authorization = `Bearer ${localStorage.getItem("Token")}`;
      }

      const resProfile = await fetch(
        process.env.REACT_APP_BASEURL + "/api/user/profile",
        {
          headers: new Headers(headers),
        }
      );
      const jsonProfile = await resProfile.json();

      if (isMounted) {
        setUser(jsonProfile.user);
        setEmail(jsonProfile.user.email);

        // Vérifiez si l'utilisateur provient de l'extension
        if (context.isFromExtension) {
          setModalState({ isVisible: false, step: 0 });
        } else if (!jsonProfile.user.onboarding) {
          setModalState({ isVisible: false, step: 0 });
        } else if (jsonProfile.user.onboardingStep !== undefined) {
          setModalState({
            isVisible: true,
            step: jsonProfile.user.onboardingStep,
          });
        } else {
          setModalState({ isVisible: true, step: 0 });
        }
      }
    };

    fetchData();
    setIsLoading(false);

    return () => {
      isMounted = false; // indiquer que le composant a été démonté
    };
  }, [context]);

  const renderCustomLegend = ({ payload }) => {
    return (
      <div>
        {payload.map((entry, index) => {
          const percentage = entry.value.split(" ")[0]; // extrait le pourcentage
          const networkName = entry.value.split(" ")[1]; // sépare la chaîne par l'espace et prend le deuxième élément

          return (
            <div
              key={`item-${index}`}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              {/* Pourcentage */}
              <span style={{ marginRight: "8px" }}>{percentage}</span>

              {/* Cercle avec la couleur */}
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: entry.color,
                  marginRight: "8px",
                }}
              ></div>
              {/* Nom du réseau avec première lettre en majuscule */}
              <span>
                {networkName.charAt(0).toUpperCase() + networkName.slice(1)}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    fetchMentionsCount(keyword, context.teamId)
      .then((data) => {
        if (data && data.success) {
          setMentionCountsNumber({
            good: data.goodCount,
            neutrale: data.neutraleCount,
            bad: data.badCount,
          });
        }
      })
      .catch((error) => console.error("Error:", error));
    setIsLoading(false);

    mixpanel.track("Load_page", {
      user: email,
      page: "Dashboard",
    });
  }, [context.keyword]);

  function fetchMentionsCount(keyword, teamId) {
    setIsLoading(true);
    return fetch(
      `${
        process.env.REACT_APP_BASEURL
      }/api/search/mentions-count?keyword=${encodeURIComponent(
        keyword
      )}&teamId=${encodeURIComponent(teamId)}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // console.log("Good:", data.goodCount);
          // console.log("Neutrale:", data.neutraleCount);
          // console.log("Bad:", data.badCount);
          setIsLoading(false);
          return data;
        } else {
          setIsLoading(false);
          throw new Error(data.msg);
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  function calculateAverage(mentionCounts) {
    const totalMentions =
      mentionCounts.good + mentionCounts.neutrale + mentionCounts.bad;
    if (totalMentions === 0) return 0;
    const weightedSum = mentionCounts.good - mentionCounts.bad;
    return weightedSum / totalMentions; // Cela donnera un score entre -1 et 1
  }

  const averageScore = calculateAverage({
    good: mentionCountsNumber.good,
    neutrale: mentionCountsNumber.neutrale,
    bad: mentionCountsNumber.bad,
  });

  function needle(averageScore, iR, oR, color) {
    // Calculez l'angle basé sur le score moyen.
    const angle = 90 - averageScore * 90; // Maintenant, 'Good' est à droite et 'Bad' à gauche

    // Convertissez l'angle en radians pour le calcul de la position x et y
    const angleRadian = (angle * Math.PI) / 180;

    // Utilisez les dimensions du conteneur pour déterminer le centre du PieChart
    const center = { x: 135, y: 100 }; // Mettez à jour selon les valeurs réelles de votre PieChart

    // Calculez la position de l'aiguille
    const length = (iR + oR) / 2; // Prenez une moyenne des rayons pour la longueur
    const xEnd = center.x + length * Math.cos(angleRadian);
    const yEnd = center.y - length * Math.sin(angleRadian);

    return (
      <>
        <circle cx={center.x} cy={center.y} r={5} fill={color} stroke="none" />
        <path
          d={`M${center.x},${center.y} L${xEnd},${yEnd}`}
          stroke={color}
          strokeWidth={2}
        />
      </>
    );
  }

  const needleComponent = needle(averageScore, 60, 85, "black");

  const goToStep1 = () => {
    setModalState({
      isVisible: true,
      step: 1,
    });
  };

  const goToStep2 = () => {
    setModalState({
      isVisible: true,
      step: 2,
    });
  };

  const goToStep3 = () => {
    setModalState({
      isVisible: true,
      step: 3,
    });
  };

  let mentionCounts = {};
  let aggregatedData = [];

  if (
    dataFromSearch &&
    dataFromSearch.searches &&
    Array.isArray(dataFromSearch.searches)
  ) {
    aggregatedData = aggregateDataByDay(dataFromSearch.searches);
    const twoMonthsAgo = duration;
    aggregatedData = aggregatedData.filter((data) =>
      moment(data.name).isAfter(twoMonthsAgo)
    );
    dataFromSearch.searches.forEach((search) => {
      for (let source in search.results) {
        if (!mentionCounts[source]) {
          mentionCounts[source] = 0;
        }
        if (
          search.results[source].data?.posts &&
          Array.isArray(search.results[source].data.posts)
        ) {
          mentionCounts[source] += search.results[source].data.posts.length;
        }
      }
    });
  }

  const isMentionCountsEmpty =
    !mentionCounts || Object.keys(mentionCounts).length === 0;

  const pieData = isMentionCountsEmpty
    ? fakeBarData
    : Object.keys(mentionCounts).map((source) => ({
        name: source,
        value: mentionCounts[source],
      }));

  const hasData =
    !isMentionCountsEmpty &&
    Object.values(mentionCounts).some((value) => value !== 0);
  const dataToUseForBar = pieData;

  const renderIconForSource = (sourceKey, faviconUrl) => {
    if (sourceKey === "googleNews") {
      return (
        <img
          src="/background/newsGoogle.png"
          className="google"
          alt="Google News"
        />
      );
    } else if (sourceKey === "indiehacker") {
      return (
        <img
          src="/social/indiehacker.png"
          className="googleFeed"
          alt="Indie Hacker"
        />
      );
    } else if (sourceKey === "twitter") {
      return (
        <img
          src="/background/logo-x-twitter.svg"
          className="twitter"
          alt="Twitter"
        />
      );
    } else {
      return <i className={`${sourceKey} fa-brands fa-${sourceKey} fa-3x`}></i>;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!dataFromSearch) return;

    let allPosts = [];

    dataFromSearch.searches.forEach((search) => {
      if (Array.isArray(selectedSources)) {
        selectedSources.forEach((source) => {
          if (
            search.results[source]?.data &&
            Array.isArray(search.results[source].data.posts)
          ) {
            search.results[source].data.posts.forEach((post) => {
              allPosts.push({
                ...post,
                sourceKey: source,
                searchDate: search.createdAt,
              });
            });
          }
        });
      }
    });

    allPosts.sort((a, b) => {
      // Convertir les dates en objets Date, et gérer les dates non valides
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      // Vérifier si les dates sont valides
      const isValidDateA = dateA instanceof Date && !isNaN(dateA);
      const isValidDateB = dateB instanceof Date && !isNaN(dateB);

      // Si les deux dates sont valides, comparez-les normalement
      if (isValidDateA && isValidDateB) {
        return dateB - dateA;
      }

      // Si la date A est invalide, placez-la à la fin
      if (!isValidDateA) {
        return 1;
      }

      // Si la date B est invalide, placez-la à la fin
      if (!isValidDateB) {
        return -1;
      }
    });

    // 2. Calculer le pourcentage d'évolution
    const aggregatedDataTwoMonthsAgo = aggregateDataByDay(
      dataFromSearch.searches
    ).filter((data) =>
      moment(data.name).isBetween(
        moment().subtract(4, "months"),
        moment().subtract(2, "months")
      )
    );

    const mentionsTwoMonthsAgo = aggregatedDataTwoMonthsAgo.reduce(
      (acc, curr) => acc + curr.uv,
      0
    );

    let currentPercentageGrowth = 0;
    if (mentionsTwoMonthsAgo !== 0) {
      currentPercentageGrowth =
        ((allPosts.length - mentionsTwoMonthsAgo) / mentionsTwoMonthsAgo) * 100;
    }

    // const currentPercentageGrowth =
    //   ((allPosts.length - mentionsTwoMonthsAgo) / mentionsTwoMonthsAgo) * 100;
    setPercentageGrowth(currentPercentageGrowth);

    setTotalMentions(allPosts.length);

    // setDashboardData((prevData) => ({
    //   ...prevData,
    //   totalMentions: allPosts.length,
    // }));

    // Après avoir calculé newDataToUse
    const newDataToUse = allPosts.length !== 0 ? aggregatedData : fakeData;

    setDataToUse(newDataToUse);
    setIsLoading(false);
    // console.log("newDataToUse", newDataToUse);
  }, [dataFromSearch, selectedSources, context.keyword, duration]);

  const [dashboardData, setDashboardData] = useState({
    totalMentions: 0,
  });

  const captureGraphsSource = async () => {
    const graphElements = document.querySelectorAll(".piedata");
    const images = await Promise.all(
      Array.from(graphElements).map(async (element) => {
        const canvas = await html2canvas(element);
        return canvas.toDataURL("image/png");
      })
    );

    return images;
  };

  const captureMentionSource = async () => {
    const graphElements = document.querySelectorAll(".mentiondataPie");
    const imagesSource = await Promise.all(
      Array.from(graphElements).map(async (element) => {
        const canvas = await html2canvas(element);
        return canvas.toDataURL("image/png");
      })
    );

    return imagesSource;
  };

  const captureSentiment = async () => {
    const element = document.getElementById("sentiment");
    if (!element) {
      console.error("L'élément avec l'ID \"sentiment\" est introuvable.");
      return null;
    }
    const canvas = await html2canvas(element);
    return canvas.toDataURL("image/png");
  };

  const storeGraphImages = async () => {
    const images = await captureGraphsSource();
    const imagesSource = await captureMentionSource();
    const imagesSentiment = await captureSentiment();
    localStorage.setItem("captureGraphsSource", JSON.stringify(images));
    localStorage.setItem("captureMentionSource", JSON.stringify(imagesSource));
    localStorage.setItem(
      "captureSentimentSource",
      JSON.stringify(imagesSentiment)
    );
  };

  // useEffect(() => {
  //   storeGraphImages();
  // }, [dashboardData]);

  function TwitterEmbed({ embedCode }) {
    useEffect(() => {
      // Fonction pour charger le script de widgets Twitter
      const loadTwitterScript = () => {
        if (!window.twttr) {
          const script = document.createElement("script");
          script.src = "https://platform.twitter.com/widgets.js";
          script.async = true;
          script.charset = "utf-8";
          document.body.appendChild(script);
        } else {
          window.twttr.widgets.load();
        }
      };

      loadTwitterScript();
    }, [embedCode]); // Exécuté à chaque fois que embedCode change

    return (
      <div
        className="twitter-embed"
        dangerouslySetInnerHTML={{ __html: embedCode }}
      />
    );
  }

  const loadPinterestScript = () => {
    if (window.PinUtils) {
      window.PinUtils.build();
    } else if (!window.pintrk) {
      window.pintrk = true;
      const script = document.createElement("script");
      script.src = "https://assets.pinterest.com/js/pinit.js";
      script.setAttribute("async", "async");
      script.setAttribute("defer", "defer");
      script.onload = () => {
        if (window.PinUtils) {
          window.PinUtils.build();
        }
      };
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    // Appel de la fonction pour charger le script lors du premier montage du composant
    loadPinterestScript();
  }, []);

  useEffect(() => {
    // Appel de la fonction quand le post sélectionné change et qu'il est de type 'pinterest'
    if (selectedMention && selectedMention.sourceKey === "pinterest") {
      loadPinterestScript();
    }
  }, [selectedMention]);

  useEffect(() => {
    setIsLoading(true);
    // Vérifiez si la période d'essai est terminée et si l'utilisateur n'est pas premium
    if (isFreeTrial() && !isPremiumTeam) {
      setIsTrialOverModalVisible(true); // Affichez la modal si les conditions sont remplies
      setIsLoading(false);
    } else {
      setIsTrialOverModalVisible(false); // Sinon, assurez-vous que la modal est cachée
      setIsLoading(false);
    }
  }, [isFreeTrial, isPremiumTeam]);

  const loadInstagramScript = () => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    } else {
      const script = document.createElement("script");
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.instgrm.Embeds.process();
      };
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    // Appel de la fonction pour charger le script lors du premier montage du composant
    loadInstagramScript();
  }, []);

  const InstagramPost = ({ url }) => {
    useEffect(() => {
      const scriptId = "instagram-embed-script";

      // Vérifie si le script existe déjà
      let script = document.getElementById(scriptId);
      if (!script) {
        // Crée le script seulement s'il n'existe pas déjà
        script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://www.instagram.com/embed.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
          if (window.instgrm) {
            window.instgrm.Embeds.process();
          }
        };
      } else if (window.instgrm) {
        // Si le script existe déjà, exécutez simplement la fonction de traitement
        window.instgrm.Embeds.process();
      }

      // Fonction de nettoyage
      return () => {
        // Supprime le script par son ID s'il n'est plus nécessaire
        const existingScript = document.getElementById(scriptId);
        if (existingScript) {
          existingScript.remove();
        }
      };
    }, [url]); // Se relance lorsque l'URL change

    return (
      <blockquote
        className="instagram-media"
        data-instgrm-captioned
        data-instgrm-permalink={url}
        data-instgrm-version="14"
        style={{
          background: "#FFF",
          border: "0",
          borderRadius: "3px",
          boxShadow: "0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)",
          margin: "1px",
          maxWidth: "540px",
          minWidth: "326px",
          padding: "0",
          width: "99.375%",
          width: "-webkit-calc(100% - 2px)",
          width: "calc(100% - 2px)",
        }}
      ></blockquote>
    );
  };

  useEffect(() => {
    // Assurez-vous que le DOM a été mis à jour avec le nouveau contenu
    // avant d'appeler le processus Instagram
    const processInstagram = () => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
    };

    // Vous devez attendre deux cycles d'événements pour vous assurer que le DOM est mis à jour.
    setTimeout(processInstagram, 0);

    // Assurez-vous que le script est chargé.
    const scriptId = "instagram-embed-script";
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, [selectedMention]); // Dépendance au post pour que cela se réexécute si le post change

  function getInstagramEmbedHtml(url) {
    // La structure de base d'une intégration Instagram (à remplacer par la structure réelle nécessaire)
    return `<blockquote class="instagram-media" data-instgrm-permalink="${url}" data-instgrm-version="14"></blockquote>`;
  }

  useEffect(() => {
    // Assurez-vous que le script TikTok est chargé
    const tiktokScriptId = "tiktok-embed-script";
    let tiktokScript = document.getElementById(tiktokScriptId);
    if (!tiktokScript) {
      tiktokScript = document.createElement("script");
      tiktokScript.id = tiktokScriptId;
      tiktokScript.src = "https://www.tiktok.com/embed.js";
      document.body.appendChild(tiktokScript);
    }
  }, []);

  function getEmbeddedContent(post) {
    // Déterminez le contenu à intégrer en fonction de la source du post
    if (post) {
      switch (post.sourceKey) {
        case "linkedin":
          if (post.link) {
            const iframeSrc = post.link.replace(
              "feed/update",
              "embed/feed/update"
            );
            return (
              <div className="alignEmbed rounded">
                <iframe
                  src={iframeSrc}
                  height="833"
                  width="504"
                  frameBorder="0"
                  allowFullScreen=""
                ></iframe>
              </div>
            );
          }
          break;
        case "twitter":
          if (post.link) {
            const tweetIdMatch = post.link.match(/status\/(\d+)/);
            const tweetId = tweetIdMatch ? tweetIdMatch[1] : null;

            const twitterLink = post.link.replace("x.com", "twitter.com");

            if (tweetId) {
              const twitterEmbedCode = `<blockquote class="twitter-tweet">
              <p>${t("loading")}</p>
              <a href="${twitterLink}"></a>
            </blockquote>`;
              return <TwitterEmbed embedCode={twitterEmbedCode} />;
            }
          }
          break;
        case "youtube":
          if (post && post.sourceKey === "youtube" && post.link) {
            // Extrait l'ID de la vidéo YouTube à partir de l'URL
            const videoIdMatch = post.link.match(
              /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/))([\w-]{10,12})/
            );
            const videoId = videoIdMatch ? videoIdMatch[1] : null;

            if (videoId) {
              const youtubeEmbedUrl = `https://www.youtube.com/embed/${videoId}`;
              return (
                <div className="alignEmbed rounded">
                  <iframe
                    width="504"
                    height="283" // Hauteur standard pour une intégration 16:9 avec une largeur de 504px
                    src={youtubeEmbedUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="rounded"
                  ></iframe>
                </div>
              );
            }
          }
          break;
        case "pinterest":
          if (post.link) {
            const pinterestEmbedCode = `<a data-pin-do="embedPin" href="${post.link}"></a>`;
            return (
              <div
                className="alignEmbed rounded interest-embed"
                dangerouslySetInnerHTML={{ __html: pinterestEmbedCode }}
              />
            );
          }
          break;
        case "instagram":
          if (post.link) {
            return (
              <div
                className="rounded alignEmbed instagram-post"
                dangerouslySetInnerHTML={{
                  __html: getInstagramEmbedHtml(post.link),
                }}
              />
            );
          }
          break;
        case "tiktok":
          if (post.link) {
            return <TikTokEmbed link={post.link} />;
          }
          break;
        case "facebook":
          if (post.link) {
            // Supposons que post.link est l'URL directe de la vidéo Facebook que vous souhaitez intégrer.
            const encodedUrl = encodeURIComponent(post.link);
            const iframeSrc = `https://www.facebook.com/plugins/video.php?height=314&href=${encodedUrl}&show_text=true&width=560&t=0`;

            return (
              <div className="alignEmbed rounded">
                <iframe
                  src={iframeSrc}
                  width="560"
                  height="314"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen={true}
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
              </div>
            );
          }
          break;
        case "googleNews":
          if (post.link) {
            return (
              <div className="alignEmbed rounded">
                <p
                  className="preserve-newlines"
                  dangerouslySetInnerHTML={{
                    __html: highlightKeyword(
                      post ? post.name : t("No_post_selected"),
                      keyword
                    ),
                  }}
                ></p>
              </div>
            );
          }
          break;
      }
    }

    // S'il n'y a pas de contenu à intégrer ou si la source n'est pas gérée, renvoyez le contenu de repli
    return (
      <p
        className="preserve-newlines"
        dangerouslySetInnerHTML={{
          __html: highlightKeyword(
            post ? post.content : t("No_post_selected"),
            keyword
          ),
        }}
      ></p>
    );
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const teamId = context.teamId;
      const keyword = context.keyword;

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      };

      const url = `${
        process.env.REACT_APP_BASEURL
      }/api/search/getSearchesByTeamId?teamId=${teamId}&keyword=${encodeURIComponent(
        keyword
      )}`;
      const response = await fetch(url, { headers });
      const result = await response.json();

      // Filtrer les recherches pour ne garder que celles qui correspondent au mot-clé sélectionné
      result.searches = result.searches.filter(
        (search) => search.keyword === context.keyword
      );

      result.searches.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setDataFromSearch(result);
      setSelectedSources(Object.keys(result?.searches?.[0]?.results || {}));

      let totalPosts = 0;

      if (result.searches) {
        const filteredSearches = result.searches.filter(
          (search) => search.keyword === context.keyword
        );

        totalPosts = filteredSearches.reduce((acc, search) => {
          // Pour chaque 'search', obtenir le total de posts de toutes ses sources
          const searchTotalPosts = Object.values(search.results || {}).reduce(
            (innerAcc, source) => {
              return (
                innerAcc +
                (Array.isArray(source.data?.posts)
                  ? source.data.posts.length
                  : 0)
              );
            },
            0
          );
          return acc + searchTotalPosts;
        }, 0);
      }

      setNombreResults(totalPosts);

      // Obtenir des informations sur l'équipe
      const teamUrl = `${process.env.REACT_APP_BASEURL}/api/teams/team-info/${context.teamId}`;
      const teamResponse = await fetch(teamUrl);
      const teamData = await teamResponse.json();

      const trialOver = context.isFreeTrialOver;

      // Vérifiez si l'équipe est premium
      if (teamData.premium || !trialOver) {
        setIsPremiumTeam(true);
      } else {
        setIsPremiumTeam(false);
      }

      if (teamData.premium) {
        setPremiumTeamCondition(true);
      }

      if (result.searches.length == 0) {
      } else {
        setLastUpdate(result.searches[0].createdAt);
      }
    };
    fetchData();

    setIsLoading(false);
  }, [context.teamId, context.keyword, context.isFreeTrialOver]);

  function getIconClass(sourceKey) {
    if (sourceKey === "googleNews") {
      return "fa-brands fa-google";
    } else {
      return `${sourceKey} fa-brands fa-${sourceKey}`;
    }
  }

  async function getSentimentAnalysis(post) {
    setIsLoading(true);
    // Vérifier si l'équipe est premium avant de faire l'appel API
    if (!isPremiumTeam) {
      // Gérer le cas où l'équipe n'est pas premium
      // Vous pouvez retourner un objet ou lever une exception selon votre gestion d'erreur
      return {
        error: "Cette fonctionnalité est réservée aux équipes premium.",
        isPremiumFeature: false,
      };
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/sentiment/analyse-sentiment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
          body: JSON.stringify({
            content: post.content,
          }),
        }
      );

      if (!response.ok) {
        // Gérer les réponses d'erreur de l'API
        throw new Error("Problème lors de la récupération des données");
      }

      const data = await response.json();
      // Retournez la réponse de l'API si l'équipe est premium
      setIsLoading(false);
      return { ...data, isPremiumFeature: true };
    } catch (error) {
      console.error("Error when calling API :", error);
      setIsLoading(false);
      return {
        error: error.message,
        isPremiumFeature: true,
      };
    }
  }

  function renderSentiment(score) {
    setIsLoading(true);
    const sentimentContainerClass =
      score > 0 ? "goodNote" : score < 0 ? "badNote" : "neutNote";
    const sentimentIcon =
      score > 0
        ? "good fa-face-smile"
        : score < 0
        ? "bad fa-face-sad-tear"
        : "neutrale fa-face-meh";
    const sentimentTextClass =
      score > 0 ? "positiveNote" : score < 0 ? "negativeNote" : "neutraleNote";
    const sentimentText =
      score > 0 ? t("good") : score < 0 ? t("bad") : t("neutrale");

    setIsLoading(false);
    return {
      sentimentContainerClass,
      sentimentIcon,
      sentimentTextClass,
      sentimentText,
    };
  }

  // useEffect(() => {
  //   if (selectedMention) {
  //     getSentimentAnalysis(selectedMention).then((data) => {
  //       setSentimentData(data);
  //     });
  //   }
  // }, [selectedMention]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="intro">{`Mentions on ${label} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!context || !context.isAuthenticate) {
    return <Loader />;
  }

  if (context.isFromExtension) {
    return <ExtensionLinkedIn />;
  }

  if (context.isAuthenticate) {
    return (
      <>
        <div className="userDashboard">
          <div className="">
            {/* {isMobile ? (
              <div className="tac mt30">
                <i className="fa-6x fa-solid fa-laptop"></i>
                <br />
                <br />
                <span style={{ fontSize: "22px" }}>⚠️</span> <br />
                {t("use_computer")}
                <br />
                <br />
                <a className="cta mt30" onClick={disconnectUser}>
                  <i className="fas fa-power-off"></i>{" "}
                  <span>{t("Se_deconnecter")}</span>
                </a>
              </div>
            ) : ( */}
            <>
              <Step0
                isVisible={modalState.isVisible && modalState.step == 0}
                onClose={() =>
                  setModalState((prevState) => ({
                    ...prevState,
                    isVisible: false,
                  }))
                }
                onNext={() => {
                  setModalState({
                    isVisible: true, // Assurez-vous que la modal reste visible pour l'étape suivante
                    step: 1, // Passez à l'étape suivante
                  });
                }}
              />

              <Step1
                isVisible={modalState.isVisible && modalState.step == 1}
                onClose={() =>
                  setModalState((prevState) => ({
                    ...prevState,
                    isVisible: false,
                  }))
                }
                onNext={() => {
                  setModalState({
                    isVisible: true, // Assurez-vous que la modal reste visible pour l'étape suivante
                    step: 2, // Passez à l'étape suivante
                  });
                }}
              />

              <Step1choose
                isVisible={modalState.isVisible && modalState.step == 2}
                onClose={() =>
                  setModalState((prevState) => ({
                    ...prevState,
                    isVisible: false,
                  }))
                }
                onNext={() => {
                  setModalState({
                    isVisible: true, // Assurez-vous que la modal reste visible pour l'étape suivante
                    step: 3, // Passez à l'étape suivante
                  });
                }}
                onBack={goToStep1}
              />

              <Step2
                isVisible={modalState.isVisible && modalState.step == 3}
                onClose={() =>
                  setModalState((prevState) => ({
                    ...prevState,
                    isVisible: false,
                  }))
                }
                onNext={() => {
                  setModalState({
                    isVisible: true, // Assurez-vous que la modal reste visible pour l'étape suivante
                    step: 4, // Passez à l'étape suivante
                  });
                }}
                onBack={goToStep2}
              />

              <Step3
                isVisible={modalState.isVisible && modalState.step == 4}
                onClose={() =>
                  setModalState((prevState) => ({
                    ...prevState,
                    isVisible: false,
                  }))
                }
                onNext={() => {
                  setModalState({
                    isVisible: true, // Assurez-vous que la modal reste visible pour l'étape suivante
                    step: 5, // Passez à l'étape suivante
                  });
                }}
                onBack={goToStep3}
                fromInvitation={!!localStorage.getItem("InvitationToken")} // Utilisez la double négation pour convertir en booléen
              />

              {/* <NavDashboard /> */}
              <main id="UserDashboard">
                <div className="">
                  {/* <h1>Dashboard</h1> */}
                  <MenuDashboard
                    duration={duration}
                    setDuration={setDuration}
                  />
                  <br />
                </div>

                <div className="mentionsContainer">
                  <div className="alignChart">
                    <div className="piedata">
                      <h5>{t("Source_of_mentions")}</h5>
                      <hr></hr>
                      <ResponsiveContainer width="100%" height={380}>
                        <BarChart
                          data={pieData}
                          margin={{
                            top: 0,
                            right: 30,
                            left: 10,
                            bottom: 20,
                          }}
                        >
                          <defs>
                            {!hasData ? (
                              <linearGradient
                                id="grayGradient"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#F8F8F8"
                                  stopOpacity={0.4}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#d0d0d0"
                                  stopOpacity={0.12}
                                />
                              </linearGradient>
                            ) : (
                              GRADIENT_COLORS.map((gradient, index) => (
                                <linearGradient
                                  key={`gradient-${index}`} // Ajout d'une prop `key` ici
                                  id={`gradient${index}`}
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="5%"
                                    stopColor="#9E00FFC2"
                                    stopOpacity={0.9}
                                  />
                                  <stop
                                    offset="95%"
                                    stopColor="#F8F8F8"
                                    stopOpacity={0.3}
                                  />
                                </linearGradient>
                              ))
                            )}
                          </defs>

                          <CartesianGrid strokeDasharray="0.3 3" />
                          <XAxis dataKey="name" hide={true} />
                          <YAxis />
                          {/* <Tooltip /> */}
                          <Tooltip content={<CustomTooltip />} />

                          <Legend content={<HiddenLegend />} />
                          <Bar dataKey="value">
                            {dataToUseForBar.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  !hasData
                                    ? `url(#grayGradient)`
                                    : `url(#gradient${index})`
                                }
                              />
                            ))}
                            <LabelList
                              dataKey="value"
                              content={renderCustomizedLabel}
                            />
                          </Bar>

                          {!hasData && (
                            <>
                              <rect
                                x="37.5%" // À ajuster en fonction de la largeur du texte
                                y="44%" // À ajuster en fonction de la hauteur du texte
                                width="25%" // Largeur du rectangle
                                height="40px" // Hauteur du rectangle
                                fill="#F2F2F2"
                                rx="10px"
                              />
                              <text
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  fontFamily: "Inter",
                                  fill: "#666", // Changez la couleur du texte en blanc
                                }}
                              >
                                {t("No_data_yet")}
                              </text>
                            </>
                          )}
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <br />
                  </div>
                  <div className="alignChart">
                    <div className="mentiondataPie">
                      <h5>{t("Mentions_Evolution")}</h5>
                      <div className="dataWrite">
                        <h6>{totalMentions}</h6>
                        {totalMentions !== 0 && !isFinite(percentageGrowth) ? (
                          <h3
                            className={`pourcentGrowth ${
                              percentageGrowth >= 0 ? "positive" : "negative"
                            }`}
                          >
                            + {percentageGrowth.toFixed(2)}%
                          </h3>
                        ) : null}
                      </div>

                      <ResponsiveContainer>
                        <AreaChart
                          key={totalMentions}
                          data={dataToUse}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <defs>
                            <linearGradient
                              id="colorUv"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="0%"
                                stopColor={
                                  totalMentions !== 0 ? "#9E00FF" : "#d0d0d0"
                                }
                                stopOpacity={1}
                              />
                              <stop
                                offset="100%"
                                stopColor={
                                  totalMentions !== 0 ? "#9E00FF" : "#d0d0d0"
                                }
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" hide={true} />
                          <YAxis hide={true} />
                          <Tooltip
                            formatter={(value, name) => {
                              if (name === "uv") return [value, "Mentions"];
                              return [value, name];
                            }}
                          />
                          {!hasData && (
                            <>
                              <rect
                                x="37.5%" // À ajuster en fonction de la largeur du texte
                                y="13.5%" // À ajuster en fonction de la hauteur du texte
                                width="25%" // Largeur du rectangle
                                height="40px" // Hauteur du rectangle
                                fill="#F2F2F2"
                                rx="10px"
                              />
                              <text
                                x="50%"
                                y="20%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  fontFamily: "Inter",
                                  fill: "#666", // Changez la couleur du texte en blanc
                                }}
                              >
                                {t("No_data_yet")}
                              </text>
                            </>
                          )}

                          <Area
                            type="monotone"
                            dataKey="uv"
                            stroke={totalMentions !== 0 ? "#9E00FF" : "#d0d0d0"}
                            fill="url(#colorUv)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
                {/* TODO: Le limiter a uniquement au team premium  */}
                {isPremiumTeam ? (
                  <div className="mentionsContainer" id="sentiment">
                    <div className="alignVign">
                      <div className="mentiondataPieChart">
                        <h5>
                          <i className="fa-regular fa-face-meh-blank fa-2x"></i>{" "}
                          <span className="textSentiment">
                            {t("Global_score")}
                          </span>
                        </h5>
                        <div className="pieChartContainer">
                          <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                              <Pie
                                dataKey="value"
                                startAngle={180}
                                endAngle={0}
                                data={dataMentionSentiment}
                                cx={135} // la moitié de max-width
                                cy={100} // ajustez pour réduire l'espace sous le Pie
                                innerRadius={60} // valeur en pixel ou en pourcentage à tester
                                outerRadius={85} // valeur en pixel ou en pourcentage à tester
                                fill="#8884d8"
                                stroke="none"
                              >
                                {dataMentionSentiment.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={entry.fillColor}
                                  />
                                ))}
                              </Pie>
                              {needleComponent}
                            </PieChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                    <div className="alignVign">
                      <div className="mentiondataNegative">
                        <h5>
                          <i className="fa-regular fa-face-frown fa-2x"></i>{" "}
                          <span className="textSentiment">
                            {t("Negative_sentiment")}
                          </span>
                          <br />
                          <br />
                          <span className="numberSentimentBad">
                            {mentionCountsNumber.bad}
                          </span>
                          <br />
                          <br />
                          {/* <span
                            className="showNegativeMentions"
                            onClick={handleNavigation}
                          >
                            {t("show_negative_mentions")}{" "}
                            <i className="fa-solid fa-chevron-right"></i>
                          </span> */}
                        </h5>
                      </div>
                    </div>
                    <div className="alignVign">
                      <div className="mentiondataNeutral">
                        <h5>
                          <i className="fa-regular fa-face-meh fa-2x"></i>{" "}
                          <span className="textSentiment">
                            {t("Neutral_sentiment")}
                          </span>
                          <br />
                          <br />
                          <span className="numberSentimentNeutrale">
                            {mentionCountsNumber.neutrale}
                          </span>
                          <br />
                          <br />
                          {/* <span
                            className="showNeutralMentions"
                            onClick={handleNavigation}
                          >
                            {t("show_neutral_mentions")}{" "}
                            <i className="fa-solid fa-chevron-right"></i>
                          </span> */}
                        </h5>
                      </div>
                    </div>
                    <div className="alignVign">
                      <div className="mentiondataPositive">
                        <h5>
                          <i className="fa-regular fa-face-smile fa-2x"></i>{" "}
                          <span className="textSentiment">
                            {t("Positive_sentiment")}
                          </span>
                          <br />
                          <br />
                          <span className="numberSentimentGood">
                            {mentionCountsNumber.good}
                          </span>
                          <br />
                          <br />
                          {/* <span
                            className="showGoodMentions"
                            onClick={handleNavigation}
                          >
                            {t("show_good_mentions")}{" "}
                            <i className="fa-solid fa-chevron-right"></i>
                          </span> */}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mentionsContainer">
                    <div
                      className="alignVign premiumNeed"
                      onClick={() => setShowPlansModal(true)}
                    >
                      <div className="mentiondataPieChart">
                        <h5>
                          <i className="fa-regular fa-face-meh-blank fa-2x"></i>{" "}
                          <span className="textSentiment">
                            {t("Global_score")}
                          </span>
                        </h5>
                        <div className="pieChartContainer"></div>
                        <div className="overlay-message">
                          <i className="fa-solid fa-lock"></i>{" "}
                          {t("You_should_be_premium")}
                        </div>
                      </div>
                    </div>
                    <div
                      className="alignVign premiumNeed"
                      onClick={() => setShowPlansModal(true)}
                    >
                      <div className="mentiondataNegative">
                        <h5>
                          <i className="fa-regular fa-face-frown fa-2x"></i>{" "}
                          <span className="textSentiment">
                            {t("Negative_sentiment")}
                          </span>
                        </h5>
                        <div className="overlay-message">
                          <i className="fa-solid fa-lock"></i>{" "}
                          {t("You_should_be_premium")}
                        </div>
                      </div>
                    </div>
                    <div
                      className="alignVign premiumNeed"
                      onClick={() => setShowPlansModal(true)}
                    >
                      <div className="mentiondataNeutral">
                        <h5>
                          <i className="fa-regular fa-face-meh fa-2x"></i>{" "}
                          <span className="textSentiment">
                            {t("Neutral_sentiment")}
                          </span>
                        </h5>
                        <div className="overlay-message">
                          <i className="fa-solid fa-lock"></i>{" "}
                          {t("You_should_be_premium")}
                        </div>
                      </div>
                    </div>
                    <div
                      className="alignVign premiumNeed"
                      onClick={() => setShowPlansModal(true)}
                    >
                      <div className="mentiondataPositive">
                        <h5>
                          <i className="fa-regular fa-face-smile fa-2x"></i>{" "}
                          <span className="textSentiment">
                            {t("Positive_sentiment")}
                          </span>
                        </h5>
                        <div className="overlay-message">
                          <i className="fa-solid fa-lock"></i>{" "}
                          {t("You_should_be_premium")}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  id="boutonDashboardMention"
                  className="alignAll alignMid"
                  onClick={handleNavigation}
                >
                  <h4> {t("Latest_Mentions")} </h4>
                  <span className="ctaPrimaryBlack">
                    <i className="fa-solid fa-eye"></i> {t("See_all_mentions")}
                  </span>
                </div>

                <div className="mentionsContainer">
                  {dataFromSearch &&
                    Array.isArray(dataFromSearch.searches) &&
                    (() => {
                      let allPosts = [];

                      // setNombreResults(allPosts.length);
                      dataFromSearch.searches.forEach((search) => {
                        if (Array.isArray(selectedSources)) {
                          selectedSources.forEach((source) => {
                            if (
                              search.results[source] &&
                              Array.isArray(search.results[source].data?.posts)
                            ) {
                              search.results[source].data.posts.forEach(
                                (post) => {
                                  allPosts.push({
                                    ...post,
                                    sourceKey: source,
                                    searchDate: search.createdAt,
                                  });
                                }
                              );
                            }
                          });
                        }
                      });

                      allPosts.sort((a, b) => {
                        // Convertir les dates en objets Date, et gérer les dates non valides
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);

                        // Vérifier si les dates sont valides
                        const isValidDateA =
                          dateA instanceof Date && !isNaN(dateA);
                        const isValidDateB =
                          dateB instanceof Date && !isNaN(dateB);

                        // Si les deux dates sont valides, comparez-les normalement
                        if (isValidDateA && isValidDateB) {
                          return dateB - dateA;
                        }

                        // Si la date A est invalide, placez-la à la fin
                        if (!isValidDateA) {
                          return 1;
                        }

                        // Si la date B est invalide, placez-la à la fin
                        if (!isValidDateB) {
                          return -1;
                        }
                      });

                      // console.log("allPosts", allPosts);
                      // console.log("nombre de post réel", allPosts.length);

                      // Ajustez le nombre de résultats à afficher en fonction de isPremiumTeam
                      const postsToDisplay = allPosts.slice(0, 8);

                      return postsToDisplay.map((post, index) => {
                        // N'appliquez pas la classe blurredFeed si isPremiumTeam est à true

                        return (
                          <div
                            key={post.id || index}
                            className={`alignVign ${index === post ? "" : ""} ${
                              !post.read && index !== post ? "triangle" : ""
                            }`}
                          >
                            {isLoading ? (
                              <Loader />
                            ) : (
                              <div
                                className={`mentiondata ${
                                  index === post ? "highlighted" : ""
                                } ${!post.read ? "nonread" : ""}`}
                              >
                                <div className="mentionContent">
                                  <a
                                    onClick={() => {
                                      handleMentionClick(post);
                                    }}
                                  >
                                    <div className="webkitBox">
                                      {post.favicon ? (
                                        <img
                                          className="favicon"
                                          src={post.favicon}
                                          alt={post.sourceKey}
                                        />
                                      ) : post.sourceKey === "googleNews" ? (
                                        <img
                                          src="/background/newsGoogle.png"
                                          className="google"
                                          alt="Google News"
                                        />
                                      ) : post.sourceKey === "indiehacker" ? (
                                        <img
                                          src="/social/indiehacker.png"
                                          className="google"
                                          alt="Indie Hacker"
                                        />
                                      ) : post.sourceKey === "twitter" ? (
                                        <img
                                          src="/background/logo-x-twitter.svg"
                                          className="twitter"
                                          alt="Google News"
                                        />
                                      ) : (
                                        <i
                                          className={
                                            getIconClass(post.sourceKey) +
                                            " fa-3x"
                                          }
                                        ></i>
                                      )}
                                      <div className="ml10 lineHeight">
                                        <span className="NameSocial">
                                          {post.name
                                            ? post.name
                                            : post.sourceKey
                                                .charAt(0)
                                                .toUpperCase() +
                                              post.sourceKey.slice(1)}
                                        </span>
                                        <span className="dateAgo">
                                          {moment(post.date).isValid()
                                            ? moment(post.date)
                                                .locale("fr")
                                                .format("LL")
                                            : post.date}
                                        </span>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        color: "black",
                                        marginTop: "10px",
                                      }}
                                      className="text-truncateFeed"
                                    >
                                      {post.language && (
                                        <img
                                          src={`https://flagcdn.com/16x12/${
                                            post.language === "en"
                                              ? "us"
                                              : post.language
                                          }.png`}
                                          onError={(e) =>
                                            (e.target.style.display = "none")
                                          } // Masque l'image en cas d'erreur de chargement
                                          alt={`flag ${
                                            post.language === "en"
                                              ? "us"
                                              : post.language
                                          }`}
                                        />
                                      )}{" "}
                                      {post.name ? post.name + " - " : ""}{" "}
                                      {post.content}
                                    </p>
                                  </a>

                                  {post.sentiment && (
                                    <div
                                      className={`analysisSentimentVignette ${
                                        isPremiumTeam
                                          ? post.sentiment
                                            ? post.sentiment === "good"
                                              ? "goodNote"
                                              : post.sentiment === "bad"
                                              ? "badNote"
                                              : "neutNote"
                                            : ""
                                          : "lockNote"
                                      }`}
                                    >
                                      <h4>{t("Sentiment")}</h4>
                                      {isPremiumTeam ? (
                                        post.sentiment ? (
                                          <div>
                                            <i
                                              className={`fa-solid ${
                                                post.sentiment === "good"
                                                  ? "good fa-face-smile"
                                                  : post.sentiment === "bad"
                                                  ? "bad fa-face-sad-tear"
                                                  : "neutrale fa-face-meh"
                                              }`}
                                            ></i>
                                            <span
                                              className={
                                                post.sentiment === "good"
                                                  ? "positiveNote"
                                                  : post.sentiment === "bad"
                                                  ? "negativeNote"
                                                  : "neutraleNote"
                                              }
                                            >
                                              {t(post.sentiment)}
                                            </span>
                                          </div>
                                        ) : null
                                      ) : (
                                        <div className="">
                                          <i className="lock fa-solid fa-lock"></i>
                                          <span
                                            className="lockedNote"
                                            onClick={() =>
                                              setShowPlansModal(true)
                                            }
                                          >
                                            {t("Unlock_feature")}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  <div className="bottomMention linkHeight">
                                    <span
                                      style={{ textAlign: "center" }}
                                      onClick={() => {
                                        handleMentionClick(post);
                                      }}
                                    >
                                      {t("See_more")}
                                    </span>
                                    {/* <a target="_blank" href={post.link}>
                                        <i className="fa-solid fa-up-right-from-square"></i>{" "}
                                        Source
                                      </a>
                                      <span onClick={() => showModal(post)}>
                                        <i className="fa-solid fa-share-nodes"></i>
                                      </span> */}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      });
                    })()}
                  <Modal
                    className={`exportModal feedModal ${bgColorClass}`} // Ici, nous utilisons le state bgColorClass pour définir la classe de la modal
                    open={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    centered
                    width="35%"
                    closable={false}
                  >
                    <div className={`modal-contentWrapper`}>
                      {" "}
                      {/* Ajouté pour le positionnement flex */}
                      <div className="post-contentModal">
                        <div className="post-header">
                          {/* Icône à gauche */}
                          <div className="icon-container">
                            {selectedMention &&
                              renderIconForSource(
                                selectedMention.sourceKey,
                                selectedMention.favicon
                              )}
                          </div>

                          {/* Nom du réseau et date à droite */}
                          <div className="details-container">
                            <span className="post-name">
                              {selectedMention
                                ? selectedMention.name
                                : t("No_post_selected")}
                            </span>
                            <span className="dateAgo">
                              {selectedMention &&
                              selectedMention.date &&
                              moment(selectedMention.date).isValid()
                                ? moment(selectedMention.date)
                                    .locale("fr")
                                    .format("LL")
                                : selectedMention
                                ? selectedMention.date
                                : t("No_date_available")}
                            </span>
                          </div>
                        </div>

                        {/* Contenu du post en dessous */}
                        <div className="post-body">
                          {selectedMention
                            ? selectedMention.content
                              ? selectedMention.content
                              : selectedMention.name
                            : t("No_content_available")}
                        </div>
                      </div>
                      <div className="poweredShare">
                        <img
                          style={{ display: "block", margin: "20px auto" }}
                          src="/logo.svg"
                        />
                        <p>{t("Your_brand_watchtower")}</p>
                      </div>
                    </div>
                    <div className="custom-footer">
                      <div className="color-selector">
                        <div
                          className="color-option exportGradiant"
                          onClick={() => setBgColorClass("exportGradiant")}
                        ></div>
                        <div
                          className="color-option exportGrey"
                          onClick={() => setBgColorClass("exportGrey")}
                        ></div>
                        <div
                          className="color-option exportPurple"
                          onClick={() => setBgColorClass("exportPurple")}
                        ></div>
                      </div>
                      <div className="action-buttons">
                        <button
                          className="export-button"
                          onClick={exportModalAsImage}
                        >
                          <i className="fa-solid fa-download"></i> {t("Export")}
                        </button>
                      </div>
                    </div>
                  </Modal>
                  {!isPremiumTeam && (
                    <Modal
                      title={
                        <span className="modalTitleFreeTrialOver">
                          {t("Your_free_trial_is_finished")}
                        </span>
                      }
                      centered
                      open={isTrialOverModalVisible}
                      onCancel={() => setIsTrialOverModalVisible(false)}
                      footer={null}
                      className="custom-modal-free-trial-over"
                    >
                      <div className="modalCenterFreeTrial">
                        <p className="modalContentFreeTrialOver">
                          {t(
                            "you_can_still_continue_to_use_buska_and_track_your_mentions"
                          )}
                        </p>
                        <button
                          className="ctaPrimaryBlack"
                          onClick={() => setShowPlansModal(true)}
                        >
                          {t("choose_a_plan")}{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                        <img
                          className="imageFreeTrial"
                          src="/freeTrial_image.png"
                        />
                      </div>
                    </Modal>
                  )}

                  <Drawer
                    width={550}
                    className="feedDrawer"
                    title={
                      <>
                        <div className="webkitBoxFeed">
                          {selectedMention &&
                            renderIconForSource(
                              selectedMention.sourceKey,
                              selectedMention.favicon
                            )}
                          {selectedMention ? (
                            <>
                              <div className="ml10 lineHeight">
                                <span className="NameSocial">
                                  {selectedMention.name
                                    ? selectedMention.name
                                    : selectedMention.sourceKey}
                                </span>
                                {/* <span className="dateAgo">
                                    {selectedMention.date}
                                  </span> */}
                                <span className="dateAgo">
                                  {moment(selectedMention.date).isValid()
                                    ? moment(selectedMention.date)
                                        .locale("fr")
                                        .format("LL")
                                    : selectedMention.date}
                                </span>
                              </div>
                            </>
                          ) : (
                            t("Post_Details")
                          )}

                          <div className="topRightDrawer">
                            <div className="imgRank">
                              <i
                                className={`petitRondDashboard fa-regular fa-thumbs-up ${
                                  selectedMention &&
                                  selectedMention.evaluate === "good"
                                    ? "evaluate"
                                    : ""
                                }`}
                              ></i>
                              <i
                                className={`petitRondDashboard fa-regular fa-thumbs-down ${
                                  selectedMention &&
                                  selectedMention.evaluate === "bad"
                                    ? "evaluate"
                                    : ""
                                }`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    placement="right"
                    onClose={onClose}
                    open={open}
                    centered
                    bodyStyle={{ backgroundColor: "white" }}
                  >
                    <div className="contentWrapper">
                      {selectedMention
                        ? getEmbeddedContent(selectedMention)
                        : t("No_post_selected")}
                      <br />
                      {selectedMention && selectedMention.link ? (
                        <a
                          href={selectedMention.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        ></a>
                      ) : null}

                      {/* <p
                        className="preserve-newlines"
                        dangerouslySetInnerHTML={{
                          __html: highlightKeyword(
                            selectedMention
                              ? selectedMention.content
                              : t("No_post_selected"),
                            context.keyword
                          ),
                        }}
                      ></p> */}

                      <br />
                      {/* <div
                        className={`analysisSentiment ${
                          sentimentData
                            ? isPremiumTeam
                              ? renderSentiment(sentimentData.score)
                                  .sentimentContainerClass
                              : "lockNote"
                            : ""
                        }`}
                      >
                        <h4>{t("Sentiment")}</h4>
                        {sentimentData ? (
                          isPremiumTeam ? (
                            <div>
                              <i
                                className={`fa-solid ${
                                  renderSentiment(sentimentData.score)
                                    .sentimentIcon
                                }`}
                              ></i>
                              <span
                                className={
                                  renderSentiment(sentimentData.score)
                                    .sentimentTextClass
                                }
                              >
                                {
                                  renderSentiment(sentimentData.score)
                                    .sentimentText
                                }
                              </span>
                            </div>
                          ) : (
                            <div>
                              <i className="lock fa-solid fa-lock"></i>
                              <span
                                className="lockedNote"
                                onClick={() => setShowPlansModal(true)}
                              >
                                {t("Unlock_feature")}
                              </span>
                            </div>
                          )
                        ) : null}
                      </div> */}
                    </div>
                    <br />
                    {selectedMention && selectedMention.link ? (
                      <a
                        href={selectedMention.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          mixpanel.track("Check Source", {
                            "Click on Source from": "Drawer Dashboard",
                          });
                        }}
                      >
                        <button className="ctaResumeMention">
                          <i className="fa-solid fa-up-right-from-square"></i>{" "}
                          {t("Source")}
                        </button>
                      </a>
                    ) : null}

                    {/* <button className="ctaResumeMention">
                        <i className="fa-solid fa-wand-magic-sparkles"></i>{" "}
                        Resume this mention
                      </button> */}

                    <button
                      className="ctaResumeMention"
                      onClick={() => {
                        showModal(selectedMention);
                        mixpanel.track("Share", {
                          "Click on Share from": "Drawer Dashboard",
                        });
                      }}
                    >
                      <i className="fa-solid fa-share-nodes"></i>{" "}
                      {t("Share_this_mention")}
                    </button>
                    <div className="bottomDrawer">
                      <img src="/delivery.svg" />
                    </div>
                  </Drawer>
                  {totalMentions <= 4 && (
                    <>
                      <div className="mentions-container">
                        {Array(Math.max(0, 4 - nombreResults))
                          .fill()
                          .map((_, idx) => (
                            <div key={idx} className="noMentions">
                              <div className="empty">
                                <div className="avatarAndTraits">
                                  <img
                                    className="avatar"
                                    src="/empty/Avatar.svg"
                                  />
                                  <div className="traitLong"></div>
                                </div>
                                <div className="traitShort"></div>
                                <div className="traitMoyen"></div>
                                <div className="traitMoyen"></div>
                                <div className="traitMoyenLong"></div>
                                <div className="traitShort"></div>
                                <div className="traitLong"></div>
                                <div className="traitShort"></div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                  <Plans
                    isVisible={showPlansModal}
                    onClose={() => setShowPlansModal(false)}
                  />

                  <div
                    onClick={handleNavigation}
                    className="ctaSecondary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                      fontWeight: "bold",
                      width: "97%",
                      maxWidth: "1900px",
                      padding: "10px 20px",
                    }}
                    href="/feed"
                  >
                    {t("See_all_mentions")}{" "}
                    <i className="ml10 fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </main>
            </>
            {/* )} */}
          </div>
        </div>
        <FooterLogin />
      </>
    );
  }
}

export default Dashboard;
