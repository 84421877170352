import React, { useState } from "react";
import "./Basic.css";
import "../../i18n";

function PressReleaseGenerator() {
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("English");
  const [caption, setCaption] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleLanguageSelect = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("Submitting:", { description, language });
    try {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + `/api/freetools/generate-press-release`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description,
            language,
            email,
            companyName,
            websiteUrl,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCaption(data.caption);
      } else {
        alert("Erreur lors de la génération de la légende.");
      }
    } catch (error) {
      console.error("Failed to generate caption:", error);
      alert("Erreur lors de la génération de la légende.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="globalContainer">
        <main className="BasicMain">
          <div className="wrap smallWrap">
            <form
              onSubmit={handleSubmit}
              className="content"
              style={{ textAlign: "left" }}
            >
              <div className="textPart mb30">
                <h1>
                  <label htmlFor="description">Press release generator</label>
                  <p>
                    Please describe your activity and your announcement in order
                    to generate your press release.
                  </p>
                </h1>

                <textarea
                  id="description"
                  type="text"
                  className="textarea-description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Describe your announcement to generate a press release."
                  style={{ width: "100%", marginBottom: "10px" }}
                />

                <br />
                <br />
                <h2>To help journalists get in touch with you:</h2>

                <label>Enter your email</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="form-field"
                />
                <br />

                <label>Enter your company name</label>
                <input
                  id="companyName"
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter your company name"
                  className="form-field"
                />
                <br />

                <label>Enter your website URL</label>
                <input
                  id="websiteUrl"
                  type="url"
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                  placeholder="Enter your website URL"
                  className="form-field"
                />

                <div className="languageSelection">
                  <h2>
                    <legend>Select the language:</legend>
                  </h2>
                  <div>
                    {["English", "French", "Spanish"].map((lang) => (
                      <button
                        key={lang}
                        type="button"
                        className={`buttonOption ${
                          language === lang ? "selected" : ""
                        }`}
                        onClick={() => handleLanguageSelect(lang)}
                      >
                        {lang}
                      </button>
                    ))}
                  </div>
                </div>
                <br />
                <button
                  className="buttonLanding"
                  type="submit"
                  style={{ marginTop: "20px" }}
                  disabled={isLoading}
                >
                  {isLoading
                    ? "Loading..."
                    : "Generate the perfect press release in few seconds"}
                </button>
                {caption && (
                  <div
                    className="captionResult"
                    style={{ marginTop: "20px" }}
                    dangerouslySetInnerHTML={{ __html: caption }}
                  ></div>
                )}
              </div>
            </form>
          </div>
        </main>
      </div>
    </>
  );
}

export default PressReleaseGenerator;
