import React, { useState, useContext } from "react"; // Assurez-vous d'importer tous les hooks et composants nécessaires
import { Modal, Button } from "antd"; // Ou d'où que vous importez vos composants UI

import { AuthContext } from "../../context/AuthContext.js";
import Select from "react-select";

import mixpanel from "mixpanel-browser";

import Loader from "../../components/Loader.js";

import countries from "../data/countries.json";

import { useTranslation } from "react-i18next";

import "./steps.css";

const fromInvitation = localStorage.getItem("InvitationToken");

const CountrySelect = ({ country, setCountry }) => {
  const { t, i18n } = useTranslation();
  const customStyles = {
    control: (base) => ({
      ...base,
      height: "56px",
      minHeight: "56px",
      background: "#F2F2F2",
      borderRadius: "10px",
      boxShadow: "none",
      border: "none",
      display: "flex",
      width: "98%",
      alignItems: "center",
      justifyContent: "space-between", // Assure que les éléments restent espacés
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 8px", // Ajuster la padding si nécessaire
      height: "56px",
      alignItems: "center",
      justifyContent: "left",
    }),
    input: (base) => ({
      ...base,
      margin: "0", // Réduire la marge si nécessaire
      padding: "0",
      height: "56px",
      position: "relative",
      top: "-2px", // Ajuster la position si nécessaire
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "56px",
      alignItems: "center",
    }),
  };

  const countryOptions = countries.map((country) => ({
    value: country.abbreviation,
    label: country.country,
  }));

  return (
    <div className="half">
      <label className="labelFormOnboarding" htmlFor="country">
        {t("Country")}
      </label>
      <div className="form-grouphalf">
        <Select
          id="country"
          name="country"
          placeholder="Choose a country"
          styles={customStyles}
          value={countryOptions.find((option) => option.value === country)}
          onChange={(option) => setCountry(option.value)}
          options={countryOptions}
        />
      </div>
    </div>
  );
};

const Step3 = ({ isVisible, onClose, onNext, onBack, fromInvitation }) => {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [featureInterested, setFeatureInterested] = useState("");
  const [whyhere, setWhyhere] = useState("");
  const [wherehere, setWherehere] = useState("");
  const [country, setCountry] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [languagekeyword, setLanguagekeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState({
    name: "",
    surname: "",
    featureInterested: "",
    country: "",
    whyhere: "",
    wherehere: "",
  });

  const context = useContext(AuthContext);

  // const handleSearch = async () => {
  //   const storedKeywords = JSON.parse(localStorage.getItem("keywords"));
  //   console.log("storedKeywords", storedKeywords);

  //   if (Array.isArray(storedKeywords) && storedKeywords.length) {
  //     try {
  //       setLoader(true);
  //       setLoading(true);

  //       for (const { keywordName } of storedKeywords) {
  //         const searchRequestBody = {
  //           keyword: keywordName,
  //           teamId: localStorage.getItem("teamid"),
  //         };

  //         const searchResponse = await fetch(
  //           `${process.env.REACT_APP_BASEURL}/api/search/proplan`,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${
  //                 context.token || localStorage.getItem("Token")
  //               }`,
  //             },
  //             body: JSON.stringify(searchRequestBody),
  //           }
  //         );

  //         const searchResponseData = await searchResponse.json();

  //         const saveResponse = await fetch(
  //           `${process.env.REACT_APP_BASEURL}/api/search/search/save`,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${
  //                 context.token || localStorage.getItem("Token")
  //               }`,
  //             },
  //             body: JSON.stringify({
  //               keyword: keywordName,
  //               teamId: searchRequestBody.teamId,
  //               responseData: searchResponseData,
  //             }),
  //           }
  //         );

  //         if (saveResponse.ok) {
  //           console.log(
  //             "Search data saved successfully for keyword:",
  //             keywordName
  //           );
  //         } else {
  //           console.error("Error saving search data for keyword:", keywordName);
  //         }
  //       }

  //       setLoading(false);
  //       setLoader(false);
  //     } catch (error) {
  //       console.error("There was an error sending the search request:", error);
  //       setLoading(false);
  //       setLoader(false);
  //     }
  //   } else {
  //     console.error("No keywords found or invalid format in localStorage.");
  //   }
  // };

  const handleSearch = async () => {
    const storedKeywords = JSON.parse(localStorage.getItem("keywords"));
    console.log("storedKeywords", storedKeywords);

    if (Array.isArray(storedKeywords) && storedKeywords.length) {
      try {
        setLoader(true);
        setLoading(true);

        for (const { keywordName } of storedKeywords) {
          const searchRequestBody = {
            keyword: keywordName,
            teamId: localStorage.getItem("teamid"),
          };

          console.log("Sending search request body:", searchRequestBody);

          const searchResponse = await fetch(
            `${process.env.REACT_APP_BASEURL}/api/search/customPlan`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${
                  context.token || localStorage.getItem("Token")
                }`,
              },
              body: JSON.stringify(searchRequestBody),
            }
          );

          const searchResponseData = await searchResponse.json();
          console.log("Received search response data:", searchResponseData);

          const saveResponse = await fetch(
            `${process.env.REACT_APP_BASEURL}/api/search/search/save`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${
                  context.token || localStorage.getItem("Token")
                }`,
              },
              body: JSON.stringify({
                keyword: keywordName,
                teamId: searchRequestBody.teamId,
                responseData: searchResponseData,
              }),
            }
          );

          if (saveResponse.ok) {
            console.log(
              "Search data saved successfully for keyword:",
              keywordName
            );
          } else {
            console.error("Error saving search data for keyword:", keywordName);
          }
        }

        setLoading(false);
        setLoader(false);
      } catch (error) {
        console.error("There was an error sending the search request:", error);
        setLoading(false);
        setLoader(false);
      }
    } else {
      console.error("No keywords found or invalid format in localStorage.");
    }
  };

  const updateTeamSocialInbox = async (teamId) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);

    try {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + "/api/teams/update-social-inbox",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              context.token || localStorage.getItem("Token")
            }`,
          },
          body: JSON.stringify({
            teamId,
            socialInbox: {
              activate: true,
              expirationDate,
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update team social inbox");
      }

      const responseData = await response.json();
      console.log("Team social inbox updated successfully!", responseData);
    } catch (error) {
      console.error(
        "There was an error updating the team social inbox:",
        error
      );
    }
  };

  const handleNextClick = async () => {
    // 1. Validation
    if (!name || !surname) {
      setError({
        ...error,
        name: !name ? "Name is required" : "",
        surname: !surname ? "Surname is required" : "",
      });
      return;
    }

    // 2. Envoi des données
    const headers = {
      "Content-Type": "application/json",
    };

    if (context.token) {
      headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem("Token")) {
      headers.Authorization = `Bearer ${localStorage.getItem("Token")}`;
    }

    const requestBody = {
      firstname: name,
      surname: surname,
      featureInterested: featureInterested,
      country: country,
      whyhere: whyhere,
      wherehere: wherehere,
      onboarding: false,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + "/api/user/update-profile",
        {
          method: "POST",
          headers: new Headers(headers),
          body: JSON.stringify(requestBody),
        }
      );

      const responseData = await response.json();
      // console.log("responseData", responseData);

      // 3. Gérer la réponse
      if (response.ok) {
        mixpanel.track("Onboarding", {
          Step: "3",
          Onboarding: "Done",
          featureInterested: featureInterested,
          country: country,
          whyhere: whyhere,
          wherehere: wherehere,
        });
        // console.log("Profile updated successfully!", responseData);
        setResponse("ok");

        const teamId = localStorage.getItem("teamid");
        await updateTeamSocialInbox(teamId);

        await handleSearch();

        setLoader(false);
        onNext(); // Appellez la fonction callback
        window.location.reload();
      } else {
        // Votre code pour gérer une erreur, comme afficher un message d'erreur
        console.error("Failed to update profile:", responseData);
      }
    } catch (error) {
      console.error("There was an error updating the profile:", error);
    }
  };

  if (loader) {
    return <Loader />;
  }

  return (
    <Modal
      className="customModal" // Ajoutez cette classe pour cibler ce modal spécifiquement dans votre CSS
      open={isVisible}
      footer={false}
      maskClosable={false}
      closable={false}
      maskStyle={{
        background:
          "linear-gradient(130deg, #FFD788 28.79%, #FFBFBF 64.77%, #DDA5FF 100%)",
      }}
    >
      <div className="leftPart">
        <h2>{t("About_you")}</h2>
        <p>{t("We_want_know_more_about_you")}</p>
        <br />
        <div className="containerInput">
          <div className="half">
            <label className="labelFormOnboarding" htmlFor="name">
              {t("First_Name")}*
            </label>
            <div className="form-grouphalf">
              <input
                placeholder="Ex: John"
                type="text"
                id="name"
                name="name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {error.name && <p className="error">{error.name}</p>}
          </div>

          <div className="half">
            <label className="labelFormOnboarding" htmlFor="surname">
              {t("Last_Name")}*
            </label>
            <div className="form-grouphalf">
              <input
                placeholder="Ex: Do"
                type="text"
                id="surname"
                name="surname"
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
            {error.surname && <p className="error">{error.surname}</p>}
          </div>
        </div>

        <CountrySelect country={country} setCountry={setCountry} />

        <label className="labelFormOnboarding" htmlFor="whyhere">
          {t("What_type_of_company_would_you_like")}
        </label>
        <div className="form-group">
          <select
            id="whyhere"
            name="whyhere"
            className="selectOnboarding"
            value={whyhere}
            onChange={(e) => setWhyhere(e.target.value)}
          >
            <option value="commercial">{t("commercial")}</option>
            <option value="service">{t("service")}</option>
            <option value="financial">{t("financial")}</option>
            <option value="health">{t("health")}</option>
            <option value="government">{t("government")}</option>
            <option value="other">{t("Other")}</option>
          </select>
        </div>

        <label className="labelFormOnboarding" htmlFor="wherelistenbuska">
          {t("how_did_you_hear_about_buska")}
        </label>
        <div className="form-group">
          <select
            id="wherelistenbuska"
            name="wherelistenbuska"
            className="selectOnboarding"
            value={wherehere}
            onChange={(e) => setWherehere(e.target.value)}
          >
            <option value="social_media">{t("Social_Media")}</option>
            <option value="online_ads">{t("Online_Ads")}</option>
            <option value="friend_recommendation">
              {t("Friend_Recommendation")}
            </option>
            <option value="google_search">{t("Google_Search")}</option>
            <option value="event_conference">{t("Event_or_Conference")}</option>
            <option value="press_magazine">{t("Press_or_Magazine")}</option>
            <option value="other">{t("Other")}</option>
          </select>
        </div>

        <div className="alignVert">
          {!fromInvitation && (
            <Button
              key="submit"
              className="CtaOnboardingv2Back"
              onClick={onBack}
            >
              <i className="fa-solid fa-angle-left"></i>
            </Button>
          )}

          <div style={{ textAlign: "right" }}>
            <Button
              key="submit"
              className={`CtaOnboardingv2 ${response ? "resultsLoaded" : ""}`}
              onClick={response ? null : handleNextClick}
            >
              {loading ? (
                <span className="spannerLoader"></span>
              ) : response ? (
                <i className="fa-solid fa-circle-check"></i>
              ) : (
                t("Finish_now")
              )}
            </Button>
          </div>
        </div>
        <div className="footerButtonSteps4"></div>
      </div>
      <div className="rightPart special">
        <p>{t("79_of_consumers_except_brands_to_respond_within_a_day")}</p>
        <p>
          {t("customers_engaged_by_companies_on_social_media_will_spend_more")}
        </p>
        <img style={{ width: "40%" }} src="/Al_on_rocket.svg" />
      </div>
    </Modal>
  );
};

export default Step3;
