import { useEffect, useState, useContext } from "react";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n";

import { Modal, Switch } from "antd";
import mixpanel from "mixpanel-browser";

import { AuthContext } from "../context/AuthContext.js";

// STYLES
import "./Plans.css";

function Plans({ isVisible, onClose }) {
  const context = useContext(AuthContext);

  const { t, i18n } = useTranslation();
  const [monthlyPricing, setMonthlyPricing] = useState(true);

  const handleToggle = () => {
    setMonthlyPricing(!monthlyPricing);
  };

  function ManageSubscription() {
    window.open(
      "https://billing.stripe.com/p/login/fZebLhb2o30W6K45kk",
      "_blank"
    );
  }

  const productName = context.teamDetails?.plan?.[0]?.productName;
  const currency = context.teamDetails?.currency;

  async function handlePurchaseClick(plan, price) {
    // Vérifiez si l'utilisateur a un stripeCustomerId

    console.log("plan", plan);
    if (!context.userInfos.stripeCustomerId) {
      // Si l'utilisateur n'a pas de stripeCustomerId, créez un client Stripe pour lui
      const response = await fetch(
        process.env.REACT_APP_BASEURL + `/api/user/createStripeCustomer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              context.token || localStorage.getItem("Token")
            }`,
          },
          body: JSON.stringify({ email: context.userInfos.email }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      mixpanel.track("Payment_Plans", {
        Price: price,
        Plan: plan,
      });

      const data = await response.json();
      const stripeCustomerId = data.stripeCustomerId;
      context.userInfos.stripeCustomerId = stripeCustomerId;
    }

    let paymentURL;
    if (currency === "EUR") {
      console.log("plan", plan);
      switch (plan) {
        case "Pro":
          paymentURL =
            price === 39
              ? `https://buy.stripe.com/8wM7uE9FvbHH8gM7sL`
              : `https://buy.stripe.com/9AQ7uEbND6nn40wdRa`;
          break;
        case "Ultimate":
          paymentURL =
            price === 69
              ? `https://buy.stripe.com/3cs02c2d39zzgNi9AV`
              : `https://buy.stripe.com/fZe2akg3T3bbfJeaF0`;
          break;
      }
    } else if (currency === "USD") {
      switch (plan) {
        case "Pro":
          paymentURL =
            price === 39
              ? `https://buy.stripe.com/8wM7uE9FvbHH8gM7sL`
              : `https://buy.stripe.com/9AQ7uEbND6nn40wdRa`;
          break;
        case "Ultimate":
          paymentURL =
            price === 69
              ? `https://buy.stripe.com/3cs02c2d39zzgNi9AV`
              : `https://buy.stripe.com/fZe2akg3T3bbfJeaF0`;
          break;
      }
    }

    // switch (plan) {
    //   case "Pro":
    //     if (price === 9) {
    //       // Local - test
    //       // paymentURL = `https://buy.stripe.com/test_bIY7w135I70h68U007`;
    //       // Prod
    //       paymentURL = `https://buy.stripe.com/9AQ3eo7xn9zz1SobIQ`;
    //     } else if (price === 15) {
    //       // Local - test
    //       // paymentURL = `https://buy.stripe.com/test_4gw9E921E1FXeFq5kq`;
    //       // Prod
    //       paymentURL = `https://buy.stripe.com/fZe8yI18ZeTTdB6dR0`;
    //     }
    //     break;
    //   case "Ultimate":
    //     if (price === 69) {
    //       // Local - test
    //       // paymentURL = `https://buy.stripe.com/test_5kA9E921EactdBm3cm`;
    //       // Prod
    //       paymentURL = `https://buy.stripe.com/cN2eX64lb9zz54A5kp`;
    //     } else if (price === 115) {
    //       // Local - test
    //       // paymentURL = `https://buy.stripe.com/test_6oEdUp5dQ70h68UbIT`;
    //       // Prod
    //       paymentURL = `https://buy.stripe.com/14kcOYdVL277fJe3cl`;
    //     }
    //     break;
    //   default:
    //     return;
    // }

    window.open(
      `${paymentURL}?prefilled_email=${context.userInfos.email}&client_reference_id=${context.teamId}`
    );
  }

  const renderPlanButton = (planName, price) => {
    const currentPlan = context.teamDetails?.plan?.[0]?.productName;

    if (currentPlan === "Pro" && planName === "Ultimate") {
      return (
        <button
          className="buyButton"
          onClick={() => handlePurchaseClick(planName, price)}
        >
          <span>{t("Upgrade_to_ultimate")}</span>
        </button>
      );
    } else if (currentPlan === planName) {
      return (
        <button className="buyButton" onClick={() => ManageSubscription()}>
          <span>{t("Manage_my_Subscription")}</span>
        </button>
      );
    } else {
      return (
        <button
          className="buyButton"
          onClick={() => handlePurchaseClick(planName, price)}
        >
          <span>
            {planName === "Pro" ? t("Level_Up_with_Pro!") : t("Reach_the_Peak")}
          </span>
        </button>
      );
    }
  };

  return (
    <>
      <Modal
        open={isVisible}
        onCancel={onClose}
        footer={null}
        centered
        width={"100%"}
        style={{ borderRadius: "10px", maxWidth: "100%" }}
        className="plans-modal"
      >
        <div className="modalPlanList">
          <div className="plans-header">
            <div className="wrap">
              <div
                className="tac mb30"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img className="logoPricing" src="/logo.svg" alt="Buska Logo" />
                <h6>{t("never_miss_any_mention_of_your_brand_again")}</h6>
                <br />
                <div className="pricingContainer">
                  <div className="monthlyorYearly">
                    <span
                      className={`monthlyPrice ${
                        monthlyPricing ? "" : "activehere"
                      }`}
                      onClick={handleToggle}
                    >
                      {t("Monthly")}
                    </span>
                    <span
                      className={`yearlyPrice ${
                        monthlyPricing ? "activehere" : ""
                      }`}
                      onClick={handleToggle}
                    >
                      {t("Yearly")}
                    </span>
                  </div>
                  <img
                    src="Arrow-left.png"
                    alt="Arrow"
                    className="arrowImage"
                  />
                  <span className="switchText" style={{ marginBottom: "35px" }}>
                    {t("save_pourcentage")}
                  </span>
                </div>
              </div>
            </div>
            <div className="plans-content">
              <div className="containerPricingPlans">
                <h4>{t("Pro")} </h4>
                <p className="descriptifPriceTitle">
                  {t("Perfect_plan_for_all_Professionals")}
                </p>
                <hr className="ligne" />
                <div className="mb10 mt10">
                  <span className="price">
                    {currency === "EUR" ? t("euro") : t("dollar")}
                    {monthlyPricing ? "32.5" : "39"}{" "}
                  </span>
                  <span className="monthsPrices">/ {t("month")}</span>
                </div>
                <p>
                  {t("Optimize_your_online_presence_tracking_with_pro_plan")}
                </p>
                <ul>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> 3 {t("keywords")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span> 3 {t("users")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("daily_updates")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("email_notification")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("browser_notification")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("Sentiment_Analysis")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    <span>{t("IA_Features")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    <span>{t("facebook_tiktok_mentions")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    <span>{t("discord_slack_notifs")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    <span>{t("Software_Integrations")}</span>
                  </li>
                  {/* <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    <span>{t("Export_data")}</span>
                  </li> */}
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>{" "}
                    <span>{t("Google_Search_Mentions")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-xmark"></i>
                    <span> {t("api")}</span>
                  </li>
                </ul>
                {/* <div className="relaxImageWrapper">
                  <img src="/Relax.svg" className="relaxImage" />
                </div> */}

                {monthlyPricing
                  ? renderPlanButton("Pro", 32.5)
                  : renderPlanButton("Pro", 39)}
              </div>
              <div className="containerPricingPlansUltimate">
                <h4>{t("Ultimate")}</h4>
                <p className="descriptifPriceTitle">
                  {t("Get_the_ultimate_experience")}
                </p>
                <hr className="ligne" />
                <div className="mb10 mt10">
                  <span className="price">
                    {currency === "EUR" ? t("euro") : t("dollar")}
                    {monthlyPricing ? "57.5" : "69"}{" "}
                  </span>
                  <span className="monthsPrices">/ {t("month")}</span>
                </div>
                <p>{t("if_you_a_finance_manager_at_big_company")}</p>
                <ul>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>
                    <span>10 {t("keywords")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("Up_to_5_members_per_team")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("real_time_updates")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("email_notification")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("browser_notification")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("Sentiment_Analysis")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("IA_Features")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("facebook_tiktok_mentions")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("discord_slack_notifs")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("Software_Integrations")}</span>
                  </li>
                  {/* <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("Export_data")}</span>
                  </li> */}
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("Google_Search_Mentions")}</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-circle-check greenPlan"></i>{" "}
                    <span>{t("api")}</span>
                  </li>
                </ul>
                {/* <div className="relaxImageWrapper">
                  <img src="/Workout.svg" className="relaxImage" />
                </div> */}

                {monthlyPricing
                  ? renderPlanButton("Ultimate", 57.5)
                  : renderPlanButton("Ultimate", 69)}
              </div>
            </div>
            {productName ? (
              <div className="tac">
                <span
                  className="activeLink"
                  onClick={() => ManageSubscription()}
                >
                  {t("Manage_my_Subscription")}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Plans;
