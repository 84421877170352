import { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { AuthContext } from "../context/AuthContext.js";
import { useRef, useState, useEffect } from "react";

import Plans from "../components/Plans.js";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n.js";

import moment from "moment-timezone";
import "moment/locale/fr";
import "moment/locale/en-gb"; // Importez la locale anglaise si nécessaire

import mixpanel from "mixpanel-browser";

import Loader from "./Loader.js";

import { Select } from "antd";
const { Option } = Select;

// STYLES
import "./MenuFeed.css";

const MenuDashboard = (props) => {
  const { duration, setDuration } = props;

  const context = useContext(AuthContext);
  const { isFreeTrialOver } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [nombreResults, setNombreResults] = useState(0);
  const [selectedSources, setSelectedSources] = useState([]);
  const [dataFromSearch, setDataFromSearch] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [keywordSetupDate, setKeywordSetupDate] = useState("");
  const [timeRemaining, setTimeRemaining] = useState("");
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [unreadMentionsCount, setUnreadMentionsCount] = useState(0);

  useEffect(() => {
    // Fonction pour mettre à jour la locale de moment
    const updateMomentLocale = () => {
      let currentLang = localStorage.getItem("i18nextLng") || "en";
      if (currentLang === "en-EN") {
        currentLang = "en"; // Convert 'en-EN' to 'en'
      }
      moment.locale(currentLang);
    };

    // Mettre à jour la locale lors du premier chargement du composant
    updateMomentLocale();

    // Mettre à jour la locale chaque fois que la langue change
    i18n.on("languageChanged", updateMomentLocale);

    // Nettoyer en supprimant le gestionnaire d'événements lorsque le composant est démonté
    return () => {
      i18n.off("languageChanged", updateMomentLocale);
    };
  }, [i18n]);

  const calculateTimeRemaining = (plan, isFreeTrialOver) => {
    let currentLang = localStorage.getItem("i18nextLng") || "en";
    if (currentLang === "en-EN") {
      currentLang = "en"; // Convert 'en-EN' to 'en'
    }
    moment.locale(currentLang);

    const now = moment();
    let nextUpdate;

    // Si l'utilisateur est en période d'essai, traitez comme "Pro"
    if (!isFreeTrialOver && plan === "Free") {
      plan = "Pro";
    }

    switch (plan) {
      case "Pro":
        // Plan Pro s'exécute une fois par jour à 15h30
        nextUpdate = now.clone().hour(15).minute(30).second(0);
        if (now.isAfter(nextUpdate)) {
          nextUpdate.add(1, "day"); // Ajoutez 1 jour si c'est déjà passé
        }
        break;

      case "Ultimate":
        // Plan Ultimate s'exécute toutes les 3 heures entre 6h et 20h
        nextUpdate = now.clone().minute(0).second(0);

        if (now.hour() >= 6 && now.hour() <= 20) {
          // Trouver la prochaine heure d'actualisation basée sur les intervalles de 3h
          const nextInterval = Math.ceil(now.hour() / 3) * 3; // Ex: 6, 9, 12, 15, 18
          nextUpdate.hour(nextInterval);
          if (now.hour() === nextInterval && now.minute() >= 0) {
            nextUpdate.add(3, "hours"); // Si déjà atteint, passe au suivant
          }
        } else if (now.hour() < 6) {
          nextUpdate.hour(6); // Si avant 6h, l'actualisation est à 6h
        } else {
          nextUpdate.add(1, "day").hour(6); // Si après 20h, prochaine à 6h le lendemain
        }
        break;

      case "Free":
        // Plan Free, s'exécute une fois par semaine
        nextUpdate = now.clone().startOf("isoWeek").add(1, "week");
        if (
          now.isoWeekday() === 1 &&
          now.isSame(now.clone().startOf("day"), "day")
        ) {
          nextUpdate = nextUpdate.add(0, "week");
        }
        nextUpdate.startOf("day");
        break;

      default:
        nextUpdate = now; // Ou valeur par défaut
    }

    const duration = moment.duration(nextUpdate.diff(now));
    let timeString = "";

    // Vérifier si le temps total restant est inférieur à une heure
    if (duration.asHours() < 1) {
      // Afficher uniquement les minutes pour les temps inférieurs à une heure
      timeString = `${duration.minutes()} ${t("min")}`;
    } else {
      // Affichage standard pour les durées de plus d'une heure
      if (duration.days() > 0) {
        timeString += `${duration.days()} ${t("jour")} `;
      }
      if (duration.hours() > 0 || timeString.length > 0) {
        timeString += `${duration.hours()} ${t("hour")} `;
      }
      if (plan === "Ultimate") {
        timeString += `${duration.minutes()} ${t("min")} `;
      }
    }

    return timeString.trim(); // Retirer les espaces excédentaires
  };

  const userPlan = context.teamDetails?.plan?.[0]?.productName ?? "Free";

  useEffect(() => {
    // Supposons que `userPlan` et `isFreeTrialOver` soient fournis par votre contexte ou état
    const timeRemaining = calculateTimeRemaining(userPlan, isFreeTrialOver);
    setTimeRemaining(timeRemaining);
  }, [userPlan, isFreeTrialOver]);

  const downloadReport = async () => {
    const storedData = JSON.parse(localStorage.getItem("dashboardData"));
    const storedGraphImages = JSON.parse(
      localStorage.getItem("captureGraphsSource")
    );
    const storedMentionImages = JSON.parse(
      localStorage.getItem("captureMentionSource")
    );
    const captureSentimentSource = JSON.parse(
      localStorage.getItem("captureSentimentSource")
    );

    const reportData = {
      titre: "Powered by",
      content:
        "Report for the keyword " +
        context.keyword +
        " for the team " +
        context.teamDetails.name +
        ".",
      keyword: context.keyword,
      graphImages: storedGraphImages,
      graphMentions: storedMentionImages,
      graphSentiment: captureSentimentSource,
    };

    const response = await fetch(
      `${process.env.REACT_APP_BASEURL}/api/user/download-report`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reportData),
      }
    );

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "report_buska.pdf";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const teamId = context.teamId;
      const keyword = context.keyword;

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      };

      const url = `${
        process.env.REACT_APP_BASEURL
      }/api/search/getSearchesByTeamId?teamId=${teamId}&keyword=${encodeURIComponent(
        keyword
      )}`;
      const response = await fetch(url, { headers });
      const result = await response.json();

      const filteredSearches = result.searches
        .filter((search) => search.keyword === keyword)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      setDataFromSearch({ searches: filteredSearches });

      if (result.searches && result.searches.length > 0) {
        // Filtrer les recherches par le mot-clé
        const keywordSearches = result.searches.filter(
          (search) => search.keyword === context.keyword
        );

        // Trier les recherches filtrées par date de manière croissante pour trouver la première recherche
        const sortedKeywordSearches = keywordSearches.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );

        // Vérifier s'il y a des recherches après le filtrage et le tri
        if (sortedKeywordSearches.length > 0) {
          const firstSearchDate = new Date(sortedKeywordSearches[0].createdAt);
          const formattedFirstSearchDate = firstSearchDate
            .toISOString()
            .split("T")[0];

          setKeywordSetupDate(formattedFirstSearchDate);
        }

        // Continuer avec le traitement des sources et du nombre total de posts comme avant
        setKeyword(result.searches[0].keyword);
        setSelectedSources(Object.keys(result?.searches?.[0]?.results || {}));

        let totalPosts = 0;

        if (keywordSearches.length > 0) {
          totalPosts = keywordSearches.reduce((acc, search) => {
            const searchTotalPosts = Object.values(search.results || {}).reduce(
              (innerAcc, source) =>
                innerAcc +
                (Array.isArray(source.data?.posts)
                  ? source.data.posts.length
                  : 0),
              0
            );
            return acc + searchTotalPosts;
          }, 0);
        }

        setNombreResults(totalPosts);
      }
    };
    fetchData();
    setLoading(false);
  }, [context.teamId, context.keyword]);

  // useEffect(() => {
  //   if (!dataFromSearch) return;

  //   let tempAllPosts = [];

  //   // Assurez-vous que la recherche contient le bon mot-clé
  //   const currentSearch = dataFromSearch.searches.find(
  //     (search) => search.keyword === context.keyword
  //   );

  //   if (currentSearch) {
  //     if (Array.isArray(selectedSources)) {
  //       selectedSources.forEach((source) => {
  //         if (
  //           currentSearch.results[source] &&
  //           Array.isArray(currentSearch.results[source].posts)
  //         ) {
  //           currentSearch.results[source].posts.forEach((post) => {
  //             tempAllPosts.push({
  //               ...post,
  //               sourceKey: source,
  //               searchDate: currentSearch.createdAt,
  //             });
  //           });
  //         }
  //       });
  //     }
  //   }

  //   setAllPosts(tempAllPosts);
  //   setNombreResults(tempAllPosts.length);
  // }, [dataFromSearch, selectedSources, keyword]);

  useEffect(() => {
    if (!dataFromSearch) return;

    let allPosts = [];

    // Itérer sur toutes les recherches
    dataFromSearch.searches.forEach((search) => {
      if (Array.isArray(selectedSources)) {
        selectedSources.forEach((source) => {
          // Vérifiez si les résultats pour la source existent et ont des posts
          if (
            search.results[source] &&
            Array.isArray(search.results[source].data?.posts)
          ) {
            // Ajouter chaque post à la liste allPosts
            search.results[source].data.posts.forEach((post) => {
              allPosts.push({
                ...post,
                sourceKey: source,
                searchDate: search.createdAt,
              });
            });
          }
        });
      }
    });

    allPosts.sort((a, b) => {
      // Convertir les dates en objets Date, et gérer les dates non valides
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      // Vérifier si les dates sont valides
      const isValidDateA = dateA instanceof Date && !isNaN(dateA);
      const isValidDateB = dateB instanceof Date && !isNaN(dateB);

      // Si les deux dates sont valides, comparez-les normalement
      if (isValidDateA && isValidDateB) {
        return dateB - dateA;
      }

      // Si la date A est invalide, placez-la à la fin
      if (!isValidDateA) {
        return 1;
      }

      // Si la date B est invalide, placez-la à la fin
      if (!isValidDateB) {
        return -1;
      }
    });
    // setNombreResults(allPosts.length);
  }, [dataFromSearch, selectedSources, keyword]);

  useEffect(() => {
    if (!dataFromSearch) return;

    let nonReadCount = 0;

    dataFromSearch.searches.forEach((search) => {
      Object.values(search.results || {}).forEach((source) => {
        nonReadCount += (source.data?.posts || []).filter(
          (post) => !post.read
        ).length;
      });
    });

    setUnreadMentionsCount(nonReadCount);
  }, [dataFromSearch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="alignMid">
        <div className="menu-container">
          <div className="filterDuration linkMenu">
            {t("Keyword_Added")}: {keywordSetupDate}
            {/* <Select
              defaultValue={"Last 2 years"}
              style={{ width: 120 }}
              onChange={(value) => {
                let newDuration;

                mixpanel.track("Period", { Period: value });

                switch (value) {
                  case "Last 2 years":
                    newDuration = moment().subtract(2, "years");
                    break;
                  case "Last month":
                    newDuration = moment().subtract(1, "months");
                    break;
                  default:
                    newDuration = moment().subtract(2, "years");
                }
                setDuration(newDuration);
              }}
            >
              <Option value="Last 2 years">{t("Last_2_years")}</Option>
              <Option value="Last month">{t("Last_month")}</Option>
            </Select> */}
          </div>
          <div className="middle-elements linkMenu">
            {/* <Link
              to="/new-mentions"
              style={{ display: "flex" }}
              className={
                location.pathname === "/new-mentions"
                  ? "buttonActiveMenu"
                  : "buttonNoActiveMenu"
              }
            >
              <i className="mr10 fa-solid fa-wand-sparkles"></i>{" "}
              <span className="menu-text">{t("new_mentions")}</span>{" "}
              <span className="nbrResults">
                {unreadMentionsCount > 99 ? "+99" : unreadMentionsCount}
              </span>
            </Link> */}

            <Link
              to="/leads"
              style={{ display: "flex" }}
              className={
                location.pathname === "/leads"
                  ? "buttonActiveMenu"
                  : "buttonNoActiveMenu"
              }
            >
              <i className="mr10 fa-solid fa-users"></i>
              <span className="menu-text">{t("Social_inbox")}</span>
            </Link>
            <Link
              to="/feed"
              style={{ display: "flex" }}
              className={
                location.pathname === "/feed"
                  ? "buttonActiveMenu"
                  : "buttonNoActiveMenu"
              }
            >
              <i className="mr10 fa-solid fa-table-cells"></i>{" "}
              <span className="menu-text">{t("All_Mentions")}</span>{" "}
              {nombreResults && nombreResults > 0 ? (
                <span className="ml10">
                  {" "}
                  {nombreResults > 99 ? "+99" : nombreResults}{" "}
                </span>
              ) : (
                <span className="ml10">0</span>
              )}
            </Link>

            {/* {context.teamDetails && context.teamDetails.beta && ( */}
            {/* <div className="NewTag">
              <div className="arrow-container">
                <img src="/Arrow-left-pink.png" alt="Arrow" />
                <span className="arrow-text">{t("new")}</span>
              </div>
            </div> */}
            {/* )} */}
            {/* <Link
            to="/analytics"
            className={
              location.pathname === "/analytics" ? "active-linkMenu" : ""
            }
          >
            <span className="menu-text">Analytics</span>{" "}
          </Link> */}
          </div>
          <div className="middle-elements linkMenu">
            {/* <button
              className="ctaPrimaryBlackMenu disabled"
              onClick={downloadReport}
              disabled
            >
              <i className="fa-solid fa-download"></i> {t("Download_report")}{" "}
              {t("Ultimate_plan")}
            </button> */}
            <Link
              to="/dashboard"
              className={
                location.pathname === "/dashboard" ? "buttonActiveMenu" : ""
              }
            >
              <i className="mr10 fa fa-chart-line"></i>{" "}
              <span className="menu-text">{t("Dashboard")}</span>{" "}
            </Link>
          </div>
        </div>
        <br />
        <div className="menuUpdateMentions">
          <div className="updateMentionDashboard">
            <span className="updateMentions">
              {isFreeTrialOver ? (
                <span
                  className="sousligne"
                  onClick={() => setShowPlansModal(true)}
                >
                  {t("your_free_trial_is_over_click_here_to_update")}
                </span>
              ) : (
                <>
                  {t("we_will_update_your_new_mentions_in")} {timeRemaining}.{" "}
                  {userPlan !== "Ultimate" && (
                    <span
                      className="sousligne"
                      onClick={() => setShowPlansModal(true)}
                    >
                      {t("click_here_to_update_sooner")}
                    </span>
                  )}
                </>
              )}
            </span>
          </div>
        </div>
      </div>

      <Plans
        isVisible={showPlansModal}
        onClose={() => setShowPlansModal(false)}
      />
    </>
  );
};
export default MenuDashboard;
